import { NumberLike } from 'types/misc'
/* eslint-disable jsdoc/require-param */
import { useMutation } from '@tanstack/react-query'
import { fetchClient } from 'helpers/transport'
import _ from 'lodash'

import { xmlToJson } from 'helpers'

interface NewAttachmentProps {
  meta: {
    presign: {
      url: string
      fields: Record<string, string>
    }
  }
}

interface CreateAttachmentProps {
  orgId: NumberLike
  file: File
}

/**
 * Upload attachment.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function createAttachment({ file, orgId }: CreateAttachmentProps) {
  const newAttachResponse = await fetchClient<NewAttachmentProps>(
    `/attachments/new?content_type=${file.type}`,
  )

  const awsUrl = newAttachResponse.parsedBody.meta.presign.url
  const { fields } = newAttachResponse.parsedBody.meta.presign

  const formData = new FormData()
  _.forOwn(fields, (value, key) => formData.append(key, value))
  formData.append('file', file)

  const fileUploadResponse = await fetch(awsUrl, {
    body: formData,
    method: 'POST',
  })

  const text = await fileUploadResponse.text()

  const parser = new DOMParser()
  const xml = parser.parseFromString(text, 'application/xml')
  const xmlData: any = xmlToJson(xml)
  const sourceUrl = xmlData.PostResponse.Location['#text']

  const saveToAttachmentResponse = await fetchClient<{
    attachment: Attachment
  }>('/attachments', {
    method: 'POST',
    body: JSON.stringify({
      attachment: {
        source_url: sourceUrl,
        organization_id: orgId,
      },
    }),
  })

  return saveToAttachmentResponse.parsedBody.attachment
}

export const useCreateAttachment = (orgId: NumberLike) =>
  useMutation((file: File) =>
    createAttachment({
      orgId,
      file,
    }),
  )
