import { NumberLike } from 'types/misc'
import { API_ADDRESS, getToken } from 'helpers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchClient } from 'helpers/transport'
import { ENVIRONMENT, QUERY_KEYS } from '../../helpers/constants'

export interface RefactoredLanguages {
  [id: number]: Language
}

export const objectifyLanguage = (languages: Array<Language>) => {
  const pair: RefactoredLanguages = {}
  for (let i = 0; i < languages.length; i += 1) {
    const language = languages[i]
    pair[language.id] = language
  }
  return pair
}

/*
 * API call to get all languages
 */
export const getLanguages = async (): Promise<Array<Language> | undefined> => {
  const authToken = await getToken()
  try {
    const response = await fetch(`${API_ADDRESS}/languages`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'GET',
    })
    const jsonResponse: { languages: Array<Language> } = await response.json()
    return jsonResponse.languages
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    }
  }
}

export const useGetLanguages = () =>
  useQuery([QUERY_KEYS.ALL_LANGUAGES], async () => {
    const languages = await getLanguages()
    if (languages) {
      return objectifyLanguage(languages)
    }
    return {}
  })

interface CreateLanguageProps {
  name: string
  code: string
}

/*
 * API call to create a languages
 */
export const createLanguage = async (
  props: CreateLanguageProps,
): Promise<Language> => {
  const response = await fetchClient<{ language: Language }>('/languages', {
    method: 'POST',
    body: JSON.stringify({ language: props }),
  })
  return response.parsedBody.language
}

export const useCreateLanguage = () => useMutation(createLanguage)

interface UpdateLanguageProps {
  name?: string
  code?: string
  id: NumberLike
}

/*
 * API call to update a language
 */
export const updateLanguage = async (
  props: UpdateLanguageProps,
): Promise<Language> => {
  const response = await fetchClient<{ language: Language }>(
    `/languages/${props.id}`,
    {
      method: 'PATCH',
      body: JSON.stringify({ language: props }),
    },
  )
  return response.parsedBody.language
}

export const useUpdateLanguage = () => useMutation(updateLanguage)

/*
 * API call to get a single language
 */
export const getLanguage = async (
  languageId: NumberLike | undefined,
): Promise<Language> => {
  const response = await fetchClient<{ language: Language }>(
    `/languages/${languageId}`,
  )
  return response.parsedBody.language
}

export const useGetLanguage = (languageId: NumberLike | undefined) =>
  useQuery([QUERY_KEYS.ALL_LANGUAGES, languageId], () =>
    getLanguage(languageId),
  )

export interface TranslatableLanguage {
  apiLanguageTag: string
  bibleLanguageTag: string
  localName: string
  locale: string
  name: string
  textDirection: 'ltr' | 'rtl'
}

/*
 * API call to get a single language
 */
export const getTranslatableLanguages = async (): Promise<
  Array<TranslatableLanguage>
> => {
  const baseUrl =
    ENVIRONMENT === 'production'
      ? 'https://presentation.youversionapi.com'
      : 'https://presentation.youversionapistaging.com'

  const response = await fetch(`${baseUrl}/graphql`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      query:
        '\n    query GetLocaleHeaders {\n  getLocaleHeaders(platform: WEB) {\n    locales {\n      apiLanguageTag\n      bibleLanguageTag\n      localName\n      locale\n      name\n      textDirection\n    }\n  }\n}\n    ',
      variables: {},
    }),
  })

  const res = await response.json()
  return res.data.getLocaleHeaders.locales
}

export const useGetTranslatableLanguages = () =>
  useQuery([QUERY_KEYS.TRANSLATABLE_LANGUAGES], getTranslatableLanguages)
