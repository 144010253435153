import { Box, Typography } from '@mui/material'
import { gray } from '@youversion/react/styles/colors-v3'
import { CheckBox } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { AcknowledgeButton } from '../../AcknowledgeButton'

interface ActivityItemProps {
  item: ActivityItem
}

export const PlanSubmissionItem = ({ item }: ActivityItemProps) => {
  return (
    <Box border={1} borderColor={gray[10]} borderRadius="10px" mb={2} p={2}>
      <Box alignItems="flex-start" display="flex" flexDirection="row">
        <Box mr={2}>
          <CheckBox style={{ color: gray[20], fontSize: 20 }} />
        </Box>
        <Box>
          <Typography variant="body1">
            <Link to={`/organizations/${item.subject.id}`}>
              {item.subject.name}
            </Link>{' '}
            submitted{' '}
            <Link to={`/plans/${item.object.id}`}>{item.object.name}</Link> for
            review (ID: {item.object.id})
          </Typography>
          <Typography variant="caption">
            {moment(item.created_at).format('LLL')}
          </Typography>
        </Box>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <AcknowledgeButton item={item} />
      </Box>
    </Box>
  )
}
