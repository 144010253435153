/* eslint-disable jsdoc/require-param */
/**
 * @module CreateComment
 */
import { useState } from 'react'
import { useCreateComment } from 'api/plans'
import { Box, Button, TextareaAutosize } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useAlert } from '@youversion/react'
import { grey } from '@mui/material/colors'
import { NumberLike } from 'types/misc'
import { API_STATUS } from 'helpers'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  addCommentContainer: {
    backgroundColor: grey['200'],
    borderRadius: theme.spacing(1),
  },
  commentTextarea: {
    backgroundColor: grey['200'],
    border: 'none',
    borderRadius: theme.spacing(1),
    margin: 0,
    outline: 'none',
    resize: 'none',
    width: '100%',
  },
  loadingBtn: {
    '&:hover': {
      backgroundColor: grey['800'],
    },
    backgroundColor: grey['900'],
    color: grey['50'],
  },
}))

interface CreateCommentProps {
  /** The day id. */
  dayId?: NumberLike
  /** The plan id. */
  planId: NumberLike
  /** Handler function to update the local comments after update button is clicked.  */
  refetch: VoidFunction
}

/**
 * Component to create a comment.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function CreateComment({ refetch, dayId, planId }: CreateCommentProps) {
  const classes = useStyles()
  const { t } = useTranslation(['plans'])
  const { throwAlert } = useAlert()
  const { mutateAsync, status: loadingStatus } = useCreateComment({
    planId,
    dayId,
  })

  const [newComment, setNewComment] = useState('')

  const isNewCommentEntered = Boolean(newComment.trim().length > 0)
  const submitting = loadingStatus === API_STATUS.LOADING

  async function handleSubmit() {
    try {
      const response = await mutateAsync(newComment)
      if (response) {
        refetch()
        setNewComment('')
        throwAlert({
          id: 'add_comment_success',
          key: 'add_comment_success',
          message: t('plans:comment.comment_added_successfully'),
          timeout: 3000,
          type: 'success',
        })
      }
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'add_comment_error',
          key: 'add_comment_error',
          message: t('plans:comment.unable_to_save_comment', {
            message: error.message,
          }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  return (
    <Box
      alignItems="flex-end"
      className={classes.addCommentContainer}
      display="flex"
      mb={2}
    >
      <TextareaAutosize
        autoComplete="off"
        className={classes.commentTextarea}
        maxRows={6}
        onChange={(event) => {
          setNewComment(event.target.value)
        }}
        placeholder={t('plans:comment.write_a_new_comment')}
        value={newComment}
      />
      {isNewCommentEntered ? (
        <Box mb={1} mr={1}>
          <Button
            className={classes.loadingBtn}
            disabled={submitting}
            onClick={() => handleSubmit()}
            size="small"
            variant="contained"
          >
            {t('plans:comment.post')}
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}
