/* eslint-disable jsdoc/require-param */
import { useState } from 'react'
import Select from 'react-select'
import { useCreateGroupMemberships } from 'api/group_memberships'
import { API_STATUS } from 'helpers'
import { useAlert } from '@youversion/react'
import { useTranslation } from 'react-i18next'
import styles from '../styles.module.scss'

interface GroupsSelectProps {
  /** Available groups. */
  groups: Group[]
  /** Groups user belongs to. */
  userGroups: Array<GroupMembership>
  /** Function to refetch group membership data.  */
  refetch: () => void
  /** User data. */
  user: User
}

/**
 * Add a group to user.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function GroupsSelect({
  groups,
  userGroups,
  refetch,
  user,
}: GroupsSelectProps) {
  const { throwAlert } = useAlert()
  const { mutateAsync, status } = useCreateGroupMemberships()
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null)
  const { t } = useTranslation('users')

  const isLoading = status === API_STATUS.LOADING

  const onAdd = async () => {
    if (!selectedGroupId) {
      return
    }
    try {
      await mutateAsync({
        userId: user.id,
        groupId: selectedGroupId,
      })
      refetch()
      setSelectedGroupId(null)
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'create_membership_error',
          key: 'create_membership_error',
          message: t('groups.create_membership_error', {
            error: error.message,
          }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  let options = groups.map((group) => {
    return { label: group.name, value: group.id }
  })
  const currentGroups = userGroups.map((group) => group.id)
  options = options.filter((opt) => !currentGroups.includes(opt.value))

  if (options.length === 0) return null

  return (
    <div>
      <span>{t('groups.add_to_group')}</span>
      <br />
      <div className={styles.addGroupSection}>
        <div className={styles.select}>
          <Select
            name="form-field-name"
            onChange={(data: { value: number }) =>
              setSelectedGroupId(data.value)
            }
            options={options}
            placeholder={t('groups.select_a_group')}
            value={
              selectedGroupId
                ? options.find((item) => item.value === selectedGroupId)
                : t('groups.select_a_group')
            }
          />
        </div>
        <button
          className={styles.button}
          disabled={isLoading}
          onClick={onAdd}
          type="button"
        >
          {t('groups.add')}
        </button>
      </div>
    </div>
  )
}
