/* eslint-disable jsdoc/require-param */
/**
 * @module hasValidPermission
 */
import { StringList } from 'types/misc'

/**
 * Checks if a permission or set of permissions is in a user's list of permissions.
 *
 * @alias module:hasValidPermission
 *
 * @returns {boolean} - Whether or not the user has the specified permission(s).
 */
export function hasValidPermission(
  /** The permission(s) that the user should have. */
  permissionToCheck: string | StringList,
  /** The user's permissions list. */
  userPermissions: StringList,
) {
  // Compare an array of permissions.
  if (Array.isArray(permissionToCheck)) {
    const allowedPermissions = permissionToCheck.filter((permission) =>
      userPermissions.includes(permission),
    )

    if (allowedPermissions.length === permissionToCheck.length) {
      return true
    }

    return false
  }

  // Compare a single permission.
  if (
    typeof permissionToCheck === 'string' &&
    userPermissions.includes(permissionToCheck)
  ) {
    return true
  }

  return false
}
