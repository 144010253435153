/**
 * @module User
 */
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Can, useAuth } from 'auth'
import { Box } from '@mui/material'
import { useAlert } from '@youversion/react'
import { statusTypes } from '@youversion/utils'
import { getAllPermissions, useGetUserPermissions } from 'api/permissions'
import { useGetUser } from 'api/users'
import { PaneContent } from 'components/misc'
import LoaderOverlay from 'components/LoaderOverlay'
import styles from 'components/MyAccount/MyAccount.module.scss'
import TransferList from 'components/TransferList'
import { UserGroups } from 'components/users/user-groups'
import { API_STATUS, REALMS } from 'helpers/constants'
import _ from 'lodash'
import { Page404 } from 'components/404Page'
import { OrganizationMemberships } from 'components/users/OrganizationMemberships'

/**
 * The User component.
 *
 * @alias module:User
 *
 * @returns {ReactElement} - The User component.
 */
export function User() {
  const { throwAlert } = useAlert()
  const { id: userId } = useParams()
  const { user: loggedInUser } = useAuth()
  const { status: userPermissionsLoadingStatus, data: userPermissions } =
    useGetUserPermissions(userId)
  const {
    data: user,
    status: loadingStatus,
    error: serverError,
  } = useGetUser(userId)

  const isAdmin = loggedInUser?.role === 'admin'
  const canUserViewPermissions = loggedInUser?.can(
    'administrate:all_permissions',
  )
  const canUserViewGroupMembership = loggedInUser?.can(
    'read_all:group_membership',
  )

  // Data loading statuses
  const [allPermissions, setAllPermissions] = useState<Array<PermissionItem>>(
    [],
  )
  const [userPermissionsIds, setUserPermissionsIds] = useState<
    Record<string, number>
  >({})

  const [loadingPermissionStatus, setLoadingPermissionsStatus] = useState(
    statusTypes.IDLE,
  )

  const activePermissions = useMemo(
    () =>
      allPermissions.filter((permission) => userPermissionsIds[permission.id]),
    [allPermissions, userPermissionsIds],
  )

  const inactivePermissions = useMemo(
    () =>
      allPermissions.filter((permission) => !userPermissionsIds[permission.id]),
    [allPermissions, userPermissionsIds],
  )

  useEffect(() => {
    async function fetchUserPermissions() {
      setLoadingPermissionsStatus(statusTypes.PENDING)
      try {
        const permissionsResponse = await getAllPermissions([REALMS.USER])

        setAllPermissions(permissionsResponse)

        setLoadingPermissionsStatus(statusTypes.RESOLVED)
      } catch (error) {
        setLoadingPermissionsStatus(statusTypes.REJECTED)
        if (error instanceof Error) {
          throwAlert({
            id: 'fetch_user_permissions_error',
            key: 'fetch_user_permissions_error',
            message: `Failed to fetch user permissions: ${error.message}`,
            type: 'error',
            timeout: 3000,
          })
        }
      }
    }
    if (canUserViewPermissions) {
      fetchUserPermissions()
    }
  }, [canUserViewPermissions, throwAlert, userId, userPermissions])

  useEffect(() => {
    if (userPermissions && userPermissions.length) {
      const permissionIds: Record<string, number> = {}
      userPermissions.forEach((userPermission) => {
        permissionIds[userPermission.permission.id] = userPermission.id
      })
      setUserPermissionsIds(permissionIds)
    }
  }, [userPermissions])

  const userError = useMemo(() => {
    if (serverError && serverError instanceof Error) {
      return serverError
    }
    return null
  }, [serverError])

  if (userError?.message === 'UserNotFound') {
    return <Page404 />
  }

  if (
    !userId ||
    !user ||
    loadingStatus !== API_STATUS.SUCCESS ||
    userPermissionsLoadingStatus === API_STATUS.LOADING
  ) {
    return <LoaderOverlay />
  }

  return (
    <div style={{ width: '100%' }}>
      <PaneContent className={styles.orgWrapper}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <h1>
            {user.first_name} {user.last_name}
          </h1>
          <div>
            <h4>First Name</h4>
            <div>{user.first_name}</div>
          </div>

          <div>
            <h4>Last Name</h4>
            <div>{user.last_name}</div>
          </div>

          <div>
            <h4>Email Address</h4>
            <div>{user.email}</div>
          </div>

          {isAdmin ? (
            <>
              <div>
                <h4>Account Status</h4>
                {_.capitalize(user.status)}
              </div>
              <div>
                <h4>YouVersion User Id</h4>
                <div>{user.yv_user_id ?? 'N/A'}</div>
              </div>
            </>
          ) : null}
        </Box>

        {canUserViewGroupMembership ? (
          <Box>
            <UserGroups user={user} />
          </Box>
        ) : null}

        {loadingPermissionStatus === statusTypes.RESOLVED ? (
          <Can user="administrate:all_permissions">
            <TransferList
              activePermissions={activePermissions}
              inactivePermissions={inactivePermissions}
              onPermissionActivate={(newId: number, permissionId: string) => {
                setUserPermissionsIds((prevIds) => ({
                  ...prevIds,
                  [permissionId]: newId,
                }))
              }}
              relationIds={userPermissionsIds}
              userId={parseInt(userId, 10)}
            />
          </Can>
        ) : null}

        <Can user="read_all:organization">
          <Box mb={2} mt={2}>
            <OrganizationMemberships userId={user.id} />
          </Box>
        </Can>
      </PaneContent>
    </div>
  )
}
