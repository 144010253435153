import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './Misc.module.scss'

export default function LoadingButton({
  children,
  className,
  isLoading,
  ...rest
}) {
  let loader = null
  if (isLoading) {
    loader = <i className="fa fa-spinner fa-spin" />
  }

  return (
    // eslint-disable-next-line react/button-has-type, react/jsx-props-no-spreading
    <button {...rest} className={c(className, styles.loadingButton)}>
      {children} {loader}
    </button>
  )
}

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

LoadingButton.defaultProps = {
  className: undefined,
  isLoading: false,
}
