/**
 * @module GlobalNotification
 */

import { handleActions } from 'redux-actions'
import _ from 'lodash'
import { TYPE_SUCCESS } from './constants'

const initialState = {
  notifications: [],
}

const ADD_NOTIFICATION = 'globalNotifications/ADD_NOTIFICATION'
const REMOVE_NOTIFICATION = 'globalNotifications/REMOVE_NOTIFICATION'

const reducer = handleActions(
  {
    [ADD_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      notifications: [...state.notifications, payload],
    }),

    [REMOVE_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      notifications: _.filter(state.notifications, (n) => n.uid !== payload),
    }),
  },
  initialState,
)

export function addNotification(message, type = TYPE_SUCCESS, options = {}) {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      uid: new Date().toISOString(),
      type,
      ...options,
      messageData: {
        message,
        ...options.messageData,
      },
    },
  }
}

export function removeNotification(uid) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: uid,
  }
}
export default reducer
