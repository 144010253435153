/**
 * @module NotificationsDisplay
 */
import React from 'react'
import NotificationSystem from 'react-notification-system'

export interface Notification {
  messageData: {
    message: NotificationSystem.Notification['message']
    action: NotificationSystem.Notification['action']
    dismissible: NotificationSystem.Notification['dismissible']
    autoDismiss: NotificationSystem.Notification['autoDismiss']
  }
  uid: NotificationSystem.Notification['uid']
  type: NotificationSystem.Notification['level']
}

interface Props {
  notifications: Array<Notification>
  onRemoveNotification: (uid: NotificationSystem.Notification['uid']) => void
}

export default function NotificationsDisplay({
  notifications,
  onRemoveNotification,
}: Props) {
  const notificationSystemRef = React.useRef<NotificationSystem | null>(null)

  React.useEffect(() => {
    if (notifications.length) {
      notifications.forEach((notification) => {
        const { message } = notification.messageData

        if (!message || !notification.uid) {
          return
        }
        const reactNotification: NotificationSystem.Notification = {
          uid: notification.uid,
          message,
          level: notification.type,
          position: 'tc',
          onRemove: (thisNotification) =>
            onRemoveNotification(thisNotification.uid),
          action: notification.messageData.action,
          dismissible: Object.prototype.hasOwnProperty.call(
            notification.messageData,
            'dismissible',
          )
            ? notification.messageData.dismissible
            : true,
        }
        if (
          Object.prototype.hasOwnProperty.call(
            notification.messageData,
            'autoDismiss',
          )
        ) {
          reactNotification.autoDismiss = notification.messageData.autoDismiss
        }
        if (notificationSystemRef && notificationSystemRef.current) {
          notificationSystemRef.current.addNotification(reactNotification)
        }
      })
    }
  }, [notifications, onRemoveNotification])

  return <NotificationSystem ref={notificationSystemRef} />
}
