/**
 * @module UsersReducer
 */

import update from 'immutability-helper'
import { LOAD_STAFF_REPRESENTATIVES } from 'state/actions/app/constants'
import { handleActions } from 'redux-actions'
import { handleApiAction as handleApi } from 'helpers'

const initialState = {
  staff_representatives: [],
}

export default handleActions(
  {
    [LOAD_STAFF_REPRESENTATIVES]: handleApi((state, { payload }) => {
      return {
        success: (state) => {
          return update(state, {
            staff_representatives: { $set: payload.representatives },
          })
        },
      }
    }),
  },
  initialState,
)
