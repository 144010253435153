/**
 * @module PlanForm
 */
import * as React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  useDisableNarratedAudio,
  useEnableNarratedAudio,
} from 'api/features/narrated-audio'
import { Breadcrumbs } from 'components/Navigation/breadcrumbs'
import { UpdatePlanParams, useUpdatePlan } from 'api/plans'
import { statusTypes } from '@youversion/utils'
import { useAlert } from '@youversion/react'
import { useNavigate } from 'react-router-dom'
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio'
import LoaderOverlay from 'components/LoaderOverlay'
import {
  API_STATUS,
  PLAN_DESCRIPTION_WORDS_LIMIT,
  submissionStatuses,
} from 'helpers'
import { isValidUrl } from 'helpers/url-normalizer'
import { NullableString } from 'types/misc'
import _ from 'lodash'
import countWords from 'utils/validate-word-count'
import { useTranslation } from 'react-i18next'
import { Form } from './Form'
import CompletionChecklist from '../CompletionChecklist'
import { Plan } from '../types'

type UpdatePlanServerErrors = Record<string, string[]>

const useStyles = makeStyles((theme) => ({
  formGridStyle: {
    order: 1,

    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  stepGridStyle: {
    order: 2,

    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
  stickyMobileHeader: {
    backgroundColor: theme.palette.common.white,
    paddingBlockEnd: theme.spacing(2),
    paddingBlockStart: theme.spacing(1),
    position: 'sticky',
    insetBlockStart: theme.spacing(0),
    zIndex: 2,

    [theme.breakpoints.down('md')]: {
      borderBlockEnd: `1px solid ${theme.palette.grey[300]}`,
      paddingBlockStart: theme.spacing(2),
      marginInlineStart: '-16px',
      marginInlineEnd: '-16px',
      paddingInlineStart: '16px',
      paddingInlineEnd: '16px',
      insetBlockStart: theme.spacing(6),
    },
  },
}))

export function validateUrl(url: NullableString) {
  if (!url) {
    return false
  }

  return isValidUrl(url)
}

interface EditPlanFormProps {
  /** The array of active categories. */
  activeCategories: Array<Plan.Category>
  /** Function to update the active categories. */
  setActiveCategories: React.Dispatch<
    React.SetStateAction<Array<Plan.Category>>
  >
  /** An array of categories. */
  categories: Array<Plan.Category>
  /** An array of categoriesSections. */
  categorySections: Array<string>
  /** Plan Object. */
  plan: Plan.Plan
  planDays: Plan.Day[]
  planLanguage: Language
  hasPlanBeenPublished: boolean
}

export function PlanForm({
  activeCategories,
  setActiveCategories,
  categories,
  categorySections,
  plan,
  planDays,
  planLanguage,
  hasPlanBeenPublished,
}: EditPlanFormProps) {
  const navigate = useNavigate()
  const classes = useStyles()
  const { throwAlert } = useAlert()
  const [loadingAttachmentStack, setLoadingAttachmentStack] = React.useState<
    Array<Date>
  >([])
  const [wantsNarratedAudio, setWantsNarratedAudio] = React.useState(() =>
    planHasNarratedAudio(plan),
  )
  const [showNarratedAudioDialog, setShowNarratedAudioDialog] =
    React.useState(false)
  const { mutateAsync, status: loadingStatus } = useUpdatePlan()
  const { t } = useTranslation(['common', 'plans'])

  const [formErrors, setFormErrors] = React.useState<Record<string, string>>({})

  const hasNarratedAudio = planHasNarratedAudio(plan)

  const {
    mutate: enableNarratedAudio,
    status: loadingStatusForEnablingNarratedAudio,
  } = useEnableNarratedAudio()
  const {
    mutate: disableNarratedAudio,
    status: loadingStatusForDisablingNarratedAudio,
  } = useDisableNarratedAudio()

  const submitting = loadingStatus === API_STATUS.LOADING
  const isEnablingNarratedAudio =
    loadingStatusForEnablingNarratedAudio === API_STATUS.LOADING
  const isDisablingNarratedAudio =
    loadingStatusForDisablingNarratedAudio === API_STATUS.LOADING
  const isLoading =
    submitting || isDisablingNarratedAudio || isEnablingNarratedAudio
  const isUploadProcessing = loadingAttachmentStack.length > 0

  async function handleSubmit() {
    setFormErrors({})

    if (plan.partner_url && !validateUrl(plan.partner_url)) {
      const partnerUrlErrorMessage = t('plans:edit_plan_info.invalid_url')
      setFormErrors({ partner_url: partnerUrlErrorMessage })
      setShowNarratedAudioDialog(false)
      throwAlert({
        id: 'update_plan_error',
        key: 'update_pan_error',
        message: t('plans:edit_plan_info.update_error', {
          error: partnerUrlErrorMessage,
        }),
        timeout: 3000,
        type: statusTypes.ERROR,
      })
      return navigate(`/plans/${plan.id}/edit#add-publisher-website`)
    }

    if (countWords(plan.description, PLAN_DESCRIPTION_WORDS_LIMIT)) {
      const errorMessage = t('plans:plan_description_validation', {
        limit: PLAN_DESCRIPTION_WORDS_LIMIT,
      })
      setFormErrors({ description: errorMessage })
      setShowNarratedAudioDialog(false)
      throwAlert({
        id: 'plan-description-error',
        key: 'plan-description-error',
        message: errorMessage,
        timeout: 5000,
        type: statusTypes.ERROR,
      })
      return navigate(`/plans/${plan.id}/edit#add-a-description`)
    }

    const submitValues: UpdatePlanParams = {
      attribution_text: plan.attribution_text,
      language_id: plan.language_id,
      categories: plan.categories || [],
      polly_voice_id: plan.polly_voice_id || null,
      description: plan.description,
      keywords: plan.keywords || [],
      large_image_id: plan.large_image ? plan.large_image.id : null,
      name: plan.name,
      partner_url: plan.partner_url,
      small_image_id: plan.small_image ? plan.small_image.id : null,
      system_status: plan?.system_status,
      title_slug: plan.title_slug,
    }

    submitValues.alternate_organization_ids = plan.alternate_organizations
      ? plan.alternate_organizations.map((org) => org.id)
      : []

    // Enable the narrated audio feature if it doesn't exist on the plan or if it's status is disabled.
    if (wantsNarratedAudio && !planHasNarratedAudio(plan)) {
      enableNarratedAudio(plan.id, {
        onError: () => {
          return throwAlert({
            id: 'enable_narrated_audio_error',
            key: 'enable_narrated_audio_error',
            message: t('plans:edit_plan_info.audio_enabling_error'),
            timeout: 3000,
            type: statusTypes.ERROR,
          })
        },
      })
      // Disable narrated audio if it exists and has never been submitted.
    } else if (
      !wantsNarratedAudio &&
      planHasNarratedAudio(plan) &&
      plan.narrated_audio_feature?.submission_status !==
        submissionStatuses.SUBMITTED
    ) {
      disableNarratedAudio(plan.id, {
        onError: () => {
          return throwAlert({
            id: 'disabled_narrated_audio_error',
            key: 'disabled_narrated_audio_error',
            message: t('plans:edit_plan_info.audio_disabling_error'),
            timeout: 3000,
            type: statusTypes.ERROR,
          })
        },
      })
    }

    try {
      await mutateAsync({
        planId: plan.id,
        data: submitValues,
      })

      // Navigates to the plan page and triggers a refresh.
      window.location.assign(`${window.location.origin}/plans/${plan.id}`)
    } catch (error) {
      let errorMessage = ''
      if (error instanceof Error) {
        errorMessage = error.message
      }

      if (error instanceof Response) {
        const serverErrors: UpdatePlanServerErrors = await error.json()
        if (Object.entries(serverErrors).length) {
          // Always pick the first error.
          const errObject = Object.entries(serverErrors)[0]
          errorMessage = `${_.startCase(_.camelCase(errObject[0]))} ${
            errObject[1][0]
          }`
        }
      }

      return throwAlert({
        id: 'update_plan_error',
        key: 'update_pan_error',
        message: t('plans:edit_plan_info.update_error', {
          error: errorMessage,
        }),
        timeout: 3000,
        type: statusTypes.ERROR,
      })
    }
  }

  async function handleSavePlan() {
    // Show dialog for the user to confirm they want Narrated Audio and agree that they cannot revert.
    if (wantsNarratedAudio && !hasNarratedAudio) {
      setShowNarratedAudioDialog(true)
      return
    }

    await handleSubmit()
  }

  // TODO - Determine if it's necessary to throw this warning when editing a plan, especially as an admin.
  // This error pops up on the Edit Plan page, which means if I'm viewing All Orgs, this error will inhibit
  // me from updating Plan Information.
  // if (isNew && !organizationName) {
  //   return (
  //     <Alert severity="warning">
  //       You must select an organization to create a new plan.
  //     </Alert>
  //   )
  // }

  if (!plan) {
    return <LoaderOverlay />
  }

  return (
    <Box mt={3}>
      <Box className={classes.stickyMobileHeader}>
        <Breadcrumbs
          pathProps={{
            [plan.id]: { disabled: true },
            edit: { title: t('common:edit') },
            plans: { title: t('plans:plans') },
          }}
        />
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h1">
              {t('plans:edit_plan_info.plan_info')}
            </Typography>
          </Box>
          <Box display="flex">
            <Box>
              <Button
                color="secondary"
                disabled={isLoading || isUploadProcessing}
                onClick={() => {
                  navigate(-1)
                }}
                variant="contained"
              >
                {t('common:cancel')}
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                color="primary"
                disabled={isLoading || isUploadProcessing}
                endIcon={
                  isLoading ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : undefined
                }
                onClick={async (event) => {
                  event.preventDefault()
                  await handleSavePlan()
                }}
                variant="contained"
              >
                {t('common:save')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid container={true} spacing={4}>
        <Grid
          className={classes.formGridStyle}
          item={true}
          lg={8}
          md={12}
          sm={12}
          xl={8}
          xs={12}
        >
          <Form
            activeCategories={activeCategories}
            categories={categories}
            categorySections={categorySections}
            formErrors={formErrors}
            hasPlanBeenPublished={hasPlanBeenPublished}
            onSubmit={handleSubmit}
            plan={plan}
            planLanguage={planLanguage}
            setActiveCategories={setActiveCategories}
            setFormErrors={setFormErrors}
            setLoadingAttachmentStack={setLoadingAttachmentStack}
            setShowNarratedAudioDialog={setShowNarratedAudioDialog}
            setWantsNarratedAudio={setWantsNarratedAudio}
            showNarratedAudioDialog={showNarratedAudioDialog}
            wantsNarratedAudio={wantsNarratedAudio}
          />
        </Grid>
        <Grid
          className={classes.stepGridStyle}
          item={true}
          lg={4}
          md={12}
          sm={12}
          xl={4}
          xs={12}
        >
          <CompletionChecklist plan={plan} planDays={planDays} />
        </Grid>
      </Grid>
    </Box>
  )
}
