import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useAlert } from '@youversion/react'
import { statusTypes } from '@youversion/utils'
import { Can, useAuth } from 'auth'
import { useDestroyGroup, useGetGroup } from 'api/groups'
import { getAllPermissions, useGetGroupPermissions } from 'api/permissions'
import { PaneContent } from 'components/misc'
import { Confirm as DeleteModal } from 'components/Modals'
import { Button, Toolbar } from 'components/Navigation/Toolbar'
import LoaderOverlay from 'components/LoaderOverlay'
import styles from 'components/Groups/Groups.module.scss'
import UserList from 'components/Groups/UserList'
import TransferList from 'components/TransferList'
import { Page404 } from 'components/404Page'
import { API_STATUS, REALMS } from 'helpers/constants'

export const Group = () => {
  const { id } = useParams()
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { throwAlert } = useAlert()
  const {
    status: groupLoadingStatus,
    data: group,
    error: serverError,
  } = useGetGroup(id)
  const { status: groupPermissionsLoadingStatus, data: groupPermissions } =
    useGetGroupPermissions(id)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  const [allPermissions, setAllPermissions] = React.useState<
    Array<PermissionItem>
  >([])
  const [groupPermissionsIds, setGroupPermissionsIds] = React.useState<
    Record<string, number>
  >({})
  const [loadingPermissionStatus, setLoadingPermissionStatus] = React.useState(
    statusTypes.IDLE,
  )
  const canUserViewPermissions = user?.can('administrate:all_permissions')

  // TODO for ELP - abstract all permissions into a custom hook
  // Permissions
  const activePermissions = React.useMemo(
    () =>
      allPermissions.filter((permission) => groupPermissionsIds[permission.id]),
    [allPermissions, groupPermissionsIds],
  )
  const inactivePermissions = React.useMemo(
    () =>
      allPermissions.filter(
        (permission) => !groupPermissionsIds[permission.id],
      ),
    [allPermissions, groupPermissionsIds],
  )

  React.useEffect(() => {
    if (groupPermissions) {
      setGroupPermissionsIds(
        groupPermissions.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.permission.id]: cur.id,
          }),
          {},
        ),
      )
    }

    async function fetchGroupPermissions() {
      setLoadingPermissionStatus(statusTypes.PENDING)
      try {
        getAllPermissions([REALMS.GROUP]).then((permissionsResponse) => {
          setAllPermissions(
            permissionsResponse.map((permission) => ({
              ...permission,
              id: permission.id,
            })),
          )
          setLoadingPermissionStatus(statusTypes.RESOLVED)
        })
      } catch (error) {
        setLoadingPermissionStatus(statusTypes.REJECTED)
        if (error instanceof Error) {
          throwAlert({
            id: 'fetch_permission_status_details_error',
            message: `Failed to fetch permission status details: ${error.message}`,
            type: 'error',
            key: 'error',
            timeout: 5000,
          })
        }
      }
    }
    if (canUserViewPermissions) {
      fetchGroupPermissions()
    }
  }, [canUserViewPermissions, groupPermissions, id, throwAlert])

  // Delete Group Function
  const { mutate: destroyGroup } = useDestroyGroup()
  const handleDestroyGroup = () => {
    if (id) {
      destroyGroup(id, {
        onError: () => {
          throwAlert({
            id: `group_${id}_delete_error`,
            key: `group_${id}_delete_error`,
            message: 'Failed to delete group',
            timeout: 3000,
            type: 'error',
          })
        },
        onSuccess: () => {
          queryClient.invalidateQueries(['groups'])
          throwAlert({
            id: `group_${id}_delete_success`,
            key: `group_${id}_delete_success`,
            message: 'Group has been deleted successfully',
            timeout: 3000,
            type: 'success',
          })
          navigate('/groups')
        },
      })
    }
  }

  const groupError = React.useMemo(() => {
    if (serverError && serverError instanceof Error) {
      return serverError
    }
    return null
  }, [serverError])

  if (groupError?.message === 'GroupNotFound') {
    return <Page404 />
  }

  if (
    !id ||
    !group ||
    groupLoadingStatus !== API_STATUS.SUCCESS ||
    groupPermissionsLoadingStatus === API_STATUS.LOADING
  ) {
    return <LoaderOverlay />
  }

  return (
    <div className={styles.contentContainer}>
      <PaneContent className={`${styles.showPage} ${styles.paneContent}`}>
        <Toolbar>
          <Can user="edit:group">
            <Link to="edit">Edit Group</Link>
          </Can>
          <Can user="delete:group">
            <Button onClick={() => setShowDeleteModal(true)}>
              Delete Group
            </Button>
          </Can>
        </Toolbar>

        <section className={styles.detailSection}>
          <h1>{group.name}</h1>
          <p>{group.description}</p>
        </section>

        <section className={styles.userSection}>
          <UserList users={group.users} />
        </section>

        {loadingPermissionStatus === statusTypes.RESOLVED ? (
          <Can user="administrate:all_permissions">
            <TransferList
              activePermissions={activePermissions}
              groupId={parseInt(id, 10)}
              inactivePermissions={inactivePermissions}
              onPermissionActivate={(newId, permissionId) => {
                setGroupPermissionsIds((prevIds) => ({
                  ...prevIds,
                  [permissionId]: newId,
                }))
              }}
              relationIds={groupPermissionsIds}
            />
          </Can>
        ) : null}
      </PaneContent>
      {showDeleteModal ? (
        <DeleteModal
          confirmBody="Are you sure you want to delete this group?"
          confirmText="Confirm Delete"
          isConfirmPending={false}
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDestroyGroup}
        />
      ) : null}
    </div>
  )
}
