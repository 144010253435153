/**
 * @module AuthContext
 */
import React from 'react'
import { State } from 'state/reducers'
import { connect } from 'react-redux'

import { authLoadingSelector, userSelector } from 'state/selectors'
import { hasValidPermission } from 'auth/utils'

/**
 * @typedef UserObject
 * @property {Function<boolean>} can - The function to check if a user has a permission.
 * @property {number} id - The user id.
 */
/**
 * @typedef {object} PartnerPortalAuthContext
 * @property {UserObject} user - The user data.
 */
export const AuthContext = React.createContext<{
  isLoading?: boolean
  user: LoggedInUser | null
}>({
  isLoading: false,
  user: null,
})

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (!('user' in context)) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

interface ProviderProps {
  children: React.ReactNode
  userData: LoggedInUser | null
  isLoadingAuth?: boolean
}

export const AuthContextProvider: React.FC<ProviderProps> = ({
  children,
  userData,
  isLoadingAuth,
}) => {
  const value = React.useMemo(() => {
    if (!userData) {
      return {
        user: null,
        isLoading: isLoadingAuth,
      }
    }

    const { permissions: userPermissions = [], ...userParams } = userData

    return {
      user: {
        ...userParams,
        can: (permission: any) => {
          return hasValidPermission(permission, userPermissions)
        },
        permissions: userPermissions,
      },
      isLoading: isLoadingAuth,
    }
  }, [userData, isLoadingAuth])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const stateToProps = (state: State) => {
  return {
    userData: userSelector(state),
    isLoadingAuth: authLoadingSelector(state),
  }
}

export const ConnectedAuthContextProvider =
  connect(stateToProps)(AuthContextProvider)
