/**
 * @module AuthReducer
 */

import update from 'immutability-helper'
import { handleActions } from 'redux-actions'

import { persistToken } from 'state/reducers/persistReducer'
import { LOAD_TOKEN } from 'state/reducers/constants'
import { API_ADDRESS, API_LOGOUT } from 'helpers/constants'

import { LOAD_ACCOUNT } from 'state/actions/auth/constants'

import { handleApiAction as handleApi } from 'helpers'
import { isLoggedInSelector } from 'state/selectors'
import { addNotification } from 'state/reducers/globalNotifications'

import { APP_ROUTE_CHANGED } from 'state/actions/app/constants'

const API_LOGIN = 'auth/LOGIN'
const API_SIGN_UP = 'auth/SIGN_UP'
const API_VALIDATE = 'auth/API_VALIDATE'
const API_CONFIRM = 'auth/API_CONFIRM'
const FORGOT_PASS = 'auth/FORGOT_PASS'
const RESET_PASS = 'auth/RESET_PASS'

const CANCEL_ACCOUNT = 'auth/CANCEL_ACCOUNT'
const UPDATE_ACCOUNT = 'auth/UPDATE_ACCOUNT'
const EDIT_ACCOUNT = 'auth/EDIT_ACCOUNT'
const EDIT_PASSWORD = 'auth/EDIT_PASSWORD'

const handleTokenResponse = (errorMessage) =>
  handleApi((state, { payload }) => ({
    start: (s) => ({
      ...s,
      loading: true,
    }),
    finish: (s) => ({
      ...s,
      loading: false,
    }),
    failure: (s) => ({
      ...s,
      token: null,
      error: payload.message || errorMessage,
    }),
    success: (s) => {
      const userData = payload.partnership
        ? payload.partnership.user
        : payload.user

      return {
        ...s,
        token: payload.meta.token,
        user: userData,
        error: null,
      }
    },
  }))

const initialState = {
  apiAddress: API_ADDRESS,
  token: null,
  error: null,
  test: null,
  user: null,
  loading: false, // this seems to be overloaded for multiple contexts (api signup, token responses)
  isEditingAccount: false,
  isPasswordEditingAccount: false,
  isSavingAccount: false,
}

export default handleActions(
  {
    [APP_ROUTE_CHANGED]: (state) => {
      return {
        ...state,
        isEditingAccount: false,
        isPasswordEditingAccount: false,
      }
    },
    [LOAD_TOKEN]: (state, { payload }) => ({ ...state, token: payload }),
    [EDIT_ACCOUNT]: (state) => ({ ...state, isEditingAccount: true }),
    [EDIT_PASSWORD]: (state) => ({ ...state, isPasswordEditingAccount: true }),
    [CANCEL_ACCOUNT]: (state) => ({
      ...state,
      isEditingAccount: false,
      isPasswordEditingAccount: false,
    }),
    [UPDATE_ACCOUNT]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        ...payload,
      },
    }),

    [API_LOGIN]: handleTokenResponse('Error logging in. Please try again.'),
    [RESET_PASS]: handleTokenResponse('Error logging in. Please try again.'),
    [API_CONFIRM]: handleTokenResponse(
      'Error confirming account. Please try again.',
    ),

    [LOAD_ACCOUNT]: handleApi((state, { payload }) => ({
      success: (s) => {
        return update(s, {
          user: { $set: payload.entities.users[payload.result.user] },
        })
      },
    })),

    [API_VALIDATE]: handleApi((state, { payload }) => ({
      failure: (s) => ({
        ...s,
        token: null,
        error:
          payload.message ||
          "Looks like you're not logged in. Please try again.",
      }),
    })),

    [API_SIGN_UP]: handleApi((state, { payload }) => ({
      start: (s) => ({
        ...s,
        loading: true,
      }),
      finish: (s) => ({
        ...s,
        loading: false,
      }),
      failure: (s) => ({
        ...s,
        token: null,
        error: payload.message || 'Error signing up. Please try again.',
      }),
      success: (s) => ({
        ...s,
        error: null,
      }),
    })),

    // For logout, we ignore all errors since we'll just wipe the token and hope
    // the request is processed. This may need to be improved?
    [API_LOGOUT]: handleApi(() => ({
      start: (s) => ({ ...s, token: null, user: null }),
    })),
  },
  initialState,
)

export function login(values) {
  return async function login(dispatch, getState) {
    const response = await dispatch({
      type: API_LOGIN,
      api: (client) =>
        client.post('/session', null, {
          data: {
            ...values,
          },
        }),
    })

    if (isLoggedInSelector(getState())) {
      dispatch(persistToken())
    }

    return response
  }
}

export function signUp(values) {
  return async function signUp(dispatch) {
    return dispatch({
      type: API_SIGN_UP,
      meta: {
        onSuccess: (response) => response,
      },
      api: (client) =>
        client.post('/partnerships', null, {
          data: {
            ...values,
          },
        }),
    })
  }
}

export function logout() {
  return async function logout(dispatch) {
    // We do not `await` here, so that logout happens optimistically
    dispatch({
      type: API_LOGOUT,
      api: (client) => client.delete('/session', null),
    })

    dispatch(persistToken())
  }
}

export function confirm(values, token, confirmType) {
  const endpoint = confirmType === 'user' ? 'invitations' : 'partnerships'

  return async function confirm(dispatch) {
    return dispatch({
      type: API_CONFIRM,
      meta: {
        onSuccess: () => {
          dispatch(persistToken())
        },
      },
      api: (client) =>
        client.patch(`/${endpoint}/${token}`, null, {
          data: {
            organization_membership: {
              status: 'accepted',
            },
            ...values,
          },
        }),
    })
  }
}

export function validate() {
  return async function validate(dispatch, getState) {
    await dispatch({
      type: API_VALIDATE,
      api: (client) => client.get('/session'),
    })

    if (!isLoggedInSelector(getState())) {
      dispatch(persistToken())
    }
  }
}

export function updateAccount(values) {
  return async function updateAccount(dispatch) {
    return dispatch({
      type: UPDATE_ACCOUNT,
      payload: values,
    })
  }
}

export function forgotPass(values) {
  return async function forgotPass(dispatch) {
    return dispatch({
      type: FORGOT_PASS,
      api: (client) =>
        client.post('/password-reset', null, {
          data: { ...values },
        }),
    })
  }
}

export function resetPassword(values, token) {
  return async function resetPassword(dispatch, getState) {
    const response = await dispatch({
      type: RESET_PASS,
      api: (client) =>
        client.patch(`/password-reset/${token}`, null, {
          data: { ...values },
        }),
    })

    if (isLoggedInSelector(getState())) {
      dispatch(persistToken())
      dispatch(addNotification('Password has been successfully reset.'))
    }

    return response
  }
}
