import * as React from 'react'
import { useIsComponentMounted } from './use-is-component-mounted'

// Basically making sure this component has the exact type definitions as a typical `useState`
type UseStateIsMountedOutputType<Type> = [
  Type,
  React.Dispatch<React.SetStateAction<Type>>,
]

/**
 * Like React's [useState](https://reactjs.org/docs/hooks-reference.html#usestate)
 * but it makes sure the component that uses this hook is mounted when updating state.
 *
 * @see Https://reactjs.org/docs/hooks-reference.html#usestate.
 * @param {any} initialValue - Initial value.
 * @returns {[any, any]} - An array of 2 items
 * the first is the current state, the second is a state update function
 * that does nothing if the component is not mounted.
 */
export function useStateIfMounted<Type>(
  initialValue: Type | (() => Type),
): UseStateIsMountedOutputType<Type> {
  const isComponentMounted = useIsComponentMounted()
  const [state, setState] = React.useState<Type>(initialValue)

  function newSetState(value: React.SetStateAction<Type>) {
    if (isComponentMounted.current) {
      setState(value)
    }
  }

  return [state, newSetState]
}
