/**
 * @module OrganizationInforForm
 */
import { useMemo, useState } from 'react'
import ProfilePhotoAttachment from 'components/misc/ProfilePhotoAttachment'
import { FileAttachmentField } from 'helpers/file-attachment-field'
import { normalizeUrlProtocol } from 'helpers/normalizers'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ImageField } from 'helpers/image-field'
import { Form, Formik } from 'formik'
import { useUpdateOrganization } from 'api/organizations'
import * as Yup from 'yup'
import { Save as SaveIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useAlert } from '@youversion/react'
import { useCreateAttachment } from 'api/attachments'
import { API_STATUS, FILE_MAX_BYTE_SIZE, orgStatuses } from 'helpers'
import { useTranslation } from 'react-i18next'
import styles from '../Organization.module.scss'
import { SelectStaffRepresentative } from './SelectRepresentative'

interface OrgInfoFormProps {
  org: Organization
  refetch: VoidFunction
}

interface UpdateorganizationFormProps {
  name: string
  website: string
  address: string
  phone: string
}

function OrgInfoForm({ org, refetch }: OrgInfoFormProps) {
  const navigate = useNavigate()
  const { throwAlert } = useAlert()
  const { t } = useTranslation(['organizations', 'common'])

  const UpdateOrganizationSchema = useMemo(
    () =>
      Yup.object().shape({
        address: Yup.string().required(
          t('organizations:edit_org.form.address.validation'),
        ),
        name: Yup.string().required(
          t('organizations:edit_org.form.name.validation'),
        ),
        phone: Yup.string().required(
          t('organizations:edit_org.form.phone.validation'),
        ),
        website: Yup.string().required(
          t('organizations:edit_org.form.website.validation'),
        ),
      }),
    [t],
  )

  const [profilePhoto, setProfilePhotoUrl] = useState(
    org?.profile_photo?.asset?.original_url,
  )
  const [staffRepresentative, setStaffRepresentative] = useState(
    org.staff_representative?.id,
  )
  const { mutateAsync } = useUpdateOrganization(org.id)
  const { mutateAsync: createAttachment, status: uploadAttachmentStatus } =
    useCreateAttachment(org.id)

  async function onDropHandler(
    acc: File[],
    cb?: (attachment: Attachment) => void,
  ) {
    const file = acc[0]

    if (!file) {
      return
    }

    if (file.size > FILE_MAX_BYTE_SIZE) {
      throw new Error(t('common:file_too_large', { size: '2' }))
    }

    const attachment = await createAttachment(file)
    if (cb) {
      await cb(attachment)
    }
  }

  const isPending = org.status === orgStatuses.PENDING

  const isUploadingAttachment = uploadAttachmentStatus === API_STATUS.LOADING

  async function handleSubmit(values: UpdateorganizationFormProps) {
    try {
      await mutateAsync({
        ...values,
        staff_representative_id: staffRepresentative,
      })
      await refetch()
      throwAlert({
        id: 'update_organization_success',
        key: 'update_organization_success',
        message: t('organizations:edit_org.update_organization_success'),
        timeout: 3000,
        type: 'success',
      })
      navigate(`/organizations/${org.id}`)
    } catch (e) {
      if (e instanceof Error) {
        throwAlert({
          id: 'update_organization_failed',
          key: 'update_organization_failed',
          message: t('organizations:edit_org.update_organization_failed', {
            message: e.message,
          }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  return (
    <Formik
      initialValues={{
        name: org.name,
        website: normalizeUrlProtocol(org.website),
        address: org.address,
        phone: org.phone,
      }}
      onSubmit={handleSubmit}
      validationSchema={UpdateOrganizationSchema}
    >
      {({ handleChange, values, errors, isSubmitting }) => (
        <Form>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            marginY={2}
          >
            <Box>
              <Typography variant="h1">
                {org.name}{' '}
                {isPending && t('organizations:single_org.pending_approval')}
              </Typography>
            </Box>
            <Box>
              <Box alignItems="center" display="flex" flexDirection="row">
                <Box>
                  <Button
                    disabled={isSubmitting || isUploadingAttachment}
                    size="large"
                    startIcon={<SaveIcon />}
                    type="submit"
                    variant="contained"
                  >
                    {t('common:save')}
                  </Button>
                </Box>
                <Box marginLeft={1}>
                  <Button
                    disabled={isSubmitting || isUploadingAttachment}
                    onClick={() => navigate(`/organizations/${org.id}`)}
                    size="large"
                    variant="outlined"
                  >
                    {t('common:cancel')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <div className={styles.orgForm}>
            <Box mb={3}>
              <ImageField
                ImageComponent={ProfilePhotoAttachment}
                className={styles.fileUploaderWrapper}
                onFileDrop={(files: File[]) =>
                  onDropHandler(files, async (attachment) => {
                    setProfilePhotoUrl(attachment.asset.original_url ?? '')
                    await mutateAsync({
                      profile_photo_id: attachment.id,
                    })
                  })
                }
                url={profilePhoto}
              />
            </Box>

            <Box mb={3}>
              <TextField
                error={Boolean(errors.name)}
                fullWidth={true}
                helperText={errors.name}
                label={t('organizations:edit_org.form.name.label')}
                name="name"
                onChange={handleChange}
                placeholder={t('organizations:edit_org.form.name.label')}
                value={values.name}
                variant="outlined"
              />
            </Box>

            <Box mb={3}>
              <TextField
                error={Boolean(errors.website)}
                fullWidth={true}
                helperText={errors.website}
                label={t('organizations:edit_org.form.website.label')}
                name="website"
                onChange={handleChange}
                placeholder={t('organizations:edit_org.form.website.label')}
                value={values.website}
                variant="outlined"
              />
            </Box>

            <Box mb={3}>
              <TextField
                error={Boolean(errors.address)}
                fullWidth={true}
                helperText={errors.address}
                label={t('organizations:edit_org.form.address.label')}
                maxRows={4}
                minRows={4}
                multiline={true}
                name="address"
                onChange={handleChange}
                placeholder={t('organizations:edit_org.form.address.label')}
                value={values.address}
                variant="outlined"
              />
            </Box>

            <Box mb={3}>
              <TextField
                error={Boolean(errors.phone)}
                fullWidth={true}
                helperText={errors.phone}
                label={t('organizations:edit_org.form.phone.label')}
                name="phone"
                onChange={handleChange}
                placeholder={t('organizations:edit_org.form.phone.label')}
                value={values.phone}
                variant="outlined"
              />
            </Box>

            <Box mb={3}>
              <SelectStaffRepresentative
                onChange={(data) => {
                  setStaffRepresentative(data)
                }}
                org={org}
                staffRepId={staffRepresentative}
              />
            </Box>

            <Box mb={3}>
              <FileAttachmentField
                className={styles.partnershipUploadWrapper}
                label={t(
                  'organizations:edit_org.upload_partnership_agreement.title',
                )}
                onFileDrop={(files: File[]) =>
                  onDropHandler(files, async (attachment) => {
                    await mutateAsync({
                      partnership_agreement_id: attachment.id,
                    })
                  })
                }
              />
            </Box>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OrgInfoForm
