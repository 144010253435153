/* eslint-disable react/prefer-stateless-function */
// TODO: ^ fix and disable above rule.
/**
 * @module PaginationBarComponent
 */
import ReactPaginate from 'react-paginate'
import { LinkButton } from 'components/Buttons/link-button'
import { colors } from '@youversion/design-system'
import { useTranslation } from 'react-i18next'
import { getLocalizedMonthAbbreviation } from 'utils/get-localized-month-abbreviation/getLocalizedMonthAbbreviation'
import styles from '../Plans.module.scss'

interface Props {
  pageCount: number
  totalCount: number
  perPageCount: number
  itemCount: number
  selected: number
  onPageChange: (selectedItem: { selected: number }) => void
  onMonthRangeChange?: (month: number) => void
  enableMonthRange?: boolean
  monthOffset?: number
}

export default function PaginationBar({
  pageCount,
  totalCount,
  perPageCount,
  itemCount,
  selected,
  onPageChange,
  onMonthRangeChange,
  enableMonthRange,
  monthOffset,
}: Props) {
  const { t, i18n } = useTranslation(['common'])
  const firstItemIndex = perPageCount * (selected - 1) + 1
  const lastItemIndex = firstItemIndex + itemCount - 1

  const localizedMonthRangeOne = `${getLocalizedMonthAbbreviation(
    i18n.language,
    0,
  )} — ${getLocalizedMonthAbbreviation(i18n.language, 5)}`

  const localizedMonthRangeTwo = `${getLocalizedMonthAbbreviation(
    i18n.language,
    6,
  )} — ${getLocalizedMonthAbbreviation(i18n.language, 11)}`

  return (
    <div className={styles.paginationBarWrapper} data-testid="PaginationBar">
      <div className={styles.paginationInfo}>
        {t('common:page_count.displayed_of_total', {
          start: firstItemIndex,
          end: lastItemIndex,
          count: totalCount,
        })}
      </div>

      {enableMonthRange && (
        <div className={styles.monthPagination}>
          <LinkButton
            onClick={(e) => {
              e.preventDefault()
              if (onMonthRangeChange) {
                onMonthRangeChange(0) // Offset by 0
              }
            }}
            style={{
              color: monthOffset === 6 ? colors.black : undefined,
            }}
          >
            {localizedMonthRangeOne}
          </LinkButton>
          <LinkButton
            onClick={(e) => {
              e.preventDefault()
              if (onMonthRangeChange) {
                onMonthRangeChange(6) // Offset by 0
              }
            }}
            style={{
              color: monthOffset === 6 ? colors.black : undefined,
            }}
          >
            {localizedMonthRangeTwo}
          </LinkButton>
        </div>
      )}

      <div className={styles.paginationOuter}>
        <ReactPaginate
          activeClassName={styles.activePage}
          breakClassName={styles.paginationBreak}
          breakLabel={<span>...</span>}
          containerClassName={styles.pagination}
          forcePage={selected - 1}
          marginPagesDisplayed={3}
          nextLabel={t('common:next')}
          onPageChange={onPageChange}
          pageCount={pageCount}
          pageRangeDisplayed={5}
          previousLabel={t('common:prev')}
        />
      </div>
    </div>
  )
}
