/**
 * @module DayScroller
 */
import * as React from 'react'
import { usePlan } from 'context'
import { Box, IconButton, Theme, Tooltip } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  InfoOutlined,
} from '@mui/icons-material'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DayScrollerDayBoxes } from './DayBoxes'
import styles from './DayScroller.module.scss'

const NUMBER_OF_DAYS_TO_SHOW_PER_PAGE = 8

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxesWrapper: {
      flex: 1,
      flexDirection: 'column',
      height: 70, // Box size.
      justifyContent: 'center',
      overflowX: 'auto',
      overflowY: 'hidden',
      position: 'relative',
    },
    dayBox: {
      backgroundColor: theme.palette.background.paper,
      border: '3px solid transparent',
      borderRadius: theme.spacing(5),
      padding: theme.spacing(1),
    },
    dayBoxes: {
      position: 'absolute',
      transition: 'margin-inline-start 1s',
      width: 'max-content',
    },
    planInfoButtonContainer: {
      alignItems: 'center',
      borderBlockEnd: `1px solid ${theme.palette.grey[400]}`,
      display: 'flex',
      height: 70,
      justifyContent: 'center',
      marginInlineEnd: theme.spacing(2),
      paddingInlineEnd: theme.spacing(2),
    },
    rewindButton: {
      alignItems: 'center',
      background: theme.palette.grey[300],
      color: theme.palette.grey[900],
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    scrollerWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      margin: '0',
    },
  }),
)

/**
 * Represents a scrollable component for displaying Day box components for a Plan.
 *
 * @returns {React.ReactElement} - The DayScroller component.
 */
export default function DayScroller() {
  const classes = useStyles()
  const { scrollRef, planDays } = usePlan()
  const { dayId, id: planId } = useParams()
  const { t } = useTranslation('plans')
  const [activeDay, setActiveDay] = React.useState<number>(1)

  React.useEffect(() => {
    const activeDayIndex = planDays.findIndex(
      (planDay) => planDay.id.toString() === dayId,
    )
    const nextActiveDay =
      activeDayIndex === -1 ? 1 : Math.ceil(activeDayIndex / 8)
    setActiveDay(nextActiveDay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function scrollToStart() {
    if (scrollRef !== undefined && scrollRef.current) {
      const nextDay = activeDay > 1 ? activeDay - 1 : 1
      const pageToShow = nextDay * NUMBER_OF_DAYS_TO_SHOW_PER_PAGE
      const element = Array.from(scrollRef.current.children)[
        pageToShow - NUMBER_OF_DAYS_TO_SHOW_PER_PAGE
      ]
      if (nextDay !== activeDay) {
        // This gets invoked when we have a page to scroll to.
        const intendedElement = element ?? scrollRef.current.firstElementChild
        intendedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
      setActiveDay(nextDay)
    }
  }

  function scrollToEnd() {
    if (scrollRef !== undefined && scrollRef.current) {
      const pageToShow = activeDay * NUMBER_OF_DAYS_TO_SHOW_PER_PAGE
      const element = Array.from(scrollRef.current.children)[
        pageToShow + NUMBER_OF_DAYS_TO_SHOW_PER_PAGE
      ]
      const limit = Math.ceil(planDays.length / NUMBER_OF_DAYS_TO_SHOW_PER_PAGE)
      const nextPage = activeDay === limit ? activeDay : activeDay + 1
      setActiveDay(nextPage)
      if (nextPage !== activeDay) {
        // This gets invoked when we have a page to scroll to.
        const intendedElement = element ?? scrollRef.current.lastElementChild
        intendedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }

  return (
    <div className={classes.scrollerWrapper}>
      <Box className={classes.planInfoButtonContainer}>
        <Tooltip placement="bottom" title="Plan Info">
          <IconButton
            aria-label={t('plans:day.plan_info')}
            className={classNames(
              classes.dayBox,
              !dayId ? styles.dayBoxSelected : null,
            )}
            component={Link}
            key="planInfo"
            size="large"
            to={`/plans/${planId}`}
          >
            <InfoOutlined color="primary" fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box display="flex" mr={1}>
        <IconButton
          aria-label={t('plans:day.scroll_left')}
          className={classes.rewindButton}
          onClick={scrollToStart}
          size="small"
        >
          <ArrowBackIosRounded color="inherit" fontSize="small" />
        </IconButton>
      </Box>

      <div className={classes.boxesWrapper}>
        <div
          className={classes.dayBoxes}
          data-testid="day-scroller-container"
          ref={scrollRef}
        >
          <DayScrollerDayBoxes />
        </div>
      </div>

      <Box display="flex" ml={1}>
        <IconButton
          aria-label={t('plans:day.scroll_right')}
          className={classes.rewindButton}
          onClick={scrollToEnd}
          size="small"
        >
          <ArrowForwardIosRounded color="inherit" fontSize="small" />
        </IconButton>
      </Box>
    </div>
  )
}
