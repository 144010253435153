import Table from 'components/misc/Table'
import Row from './Row'
import styles from './styles.module.scss'

interface Props {
  items: Array<Group>
}

export default function GroupsTable({ items }: Props) {
  return (
    <Table>
      <thead>
        <tr>
          <th className={styles.sortableHeading}>Name / Description</th>
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {items && items.map((group) => <Row group={group} key={group.id} />)}
      </tbody>
    </Table>
  )
}
