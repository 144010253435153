/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react'

interface Props {
  totalPartners: number
  totalPlans?: number
}

export const RowTotals: FC<Props> = ({ totalPartners, totalPlans }) => {
  const background = { background: '#f9f9f9' }

  return (
    <tr>
      <td />
      <td style={background}>
        Total Partners: <strong>{totalPartners}</strong>
      </td>
      <td style={background} />
      <td style={background}>
        {totalPlans !== 0 && (
          <span>
            Total Plans: <strong>{totalPlans}</strong>
          </span>
        )}
      </td>
    </tr>
  )
}
