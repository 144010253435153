/**
 * @module CategoryManager
 */
import { Plan } from 'components/Plans/types'
import { MAX_CATEGORIES } from 'helpers/constants'

/**
 * Adds or removes category from the list.
 *
 * @param {object} values - All Active categories.
 * @param {object} category - Category to be added/removed.
 *
 * @returns {Array<object>} - Categories data.
 */
export function toggleCategoryValue(
  values: Array<Plan.Category>,
  category: Plan.Category,
) {
  const newValues = values || []

  const foundIdx = newValues.findIndex((value) => value.id === category.id)
  if (foundIdx !== -1) {
    return [...newValues.slice(0, foundIdx), ...newValues.slice(foundIdx + 1)]
  }

  // Make sure the number is at or under the limit allowed.
  if (newValues.length === MAX_CATEGORIES) {
    return values
  }

  return [...newValues, category]
}
