/* eslint-disable jsdoc/require-param */
import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { NumberLike } from 'types/misc'
import { useUpdateUser } from 'api/users'
import { useAlert } from '@youversion/react'
import { updateAccount } from 'state/reducers/authReducer'
import { useTranslation } from 'react-i18next'
import { ViewUserDetails } from './view'
import { EditUserDetails, UpdateUserFormValues } from './edit'
import { UpdatePassword } from './updatePassword'

const UpdateUserDetails = Yup.object().shape({
  email: Yup.string().email('validation.email').required('validation.email'),
  firstName: Yup.string().required('validation.first_name'),
  lastName: Yup.string().required('validation.last_name'),
})

interface UserDetailsProps {
  user: LoggedInUser
  dispatch: Function
  orgId: NumberLike
}

const useStyles = makeStyles((theme) => ({
  editContainer: {
    display: 'flex',
    marginInlineEnd: theme.spacing(1),
  },
  editButton: {
    marginInlineStart: theme.spacing(1),
  },
}))

/**
 * Renders User details / Edit user form.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function UserDetails({ user, dispatch, orgId }: UserDetailsProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [isUpdatePasswordOpened, toggleUpdatePasswordModal] = useState(false)
  const { mutateAsync } = useUpdateUser()
  const { throwAlert } = useAlert()
  const { t, i18n } = useTranslation('users')
  const userLanguage = i18n.language
  const classes = useStyles()

  const handleSubmit = async (props: UpdateUserFormValues) => {
    try {
      await mutateAsync({
        first_name: props.firstName,
        last_name: props.lastName,
        email: props.email,
      })
      setIsEditing(false)

      // Update global user
      dispatch(
        updateAccount({
          first_name: props.firstName,
          last_name: props.lastName,
          email: props.email,
        }),
      )

      if (props.language && props.language !== userLanguage) {
        const newLanguage = props.language
        i18n.changeLanguage(newLanguage)
        localStorage.setItem('app_language', newLanguage)
      }

      throwAlert({
        id: 'update_user_success',
        key: 'update_user_success',
        message: t('messages.user_success'),
        timeout: 3000,
        type: 'success',
      })
    } catch (e) {
      if (e instanceof Error) {
        throwAlert({
          id: 'update_user_failed',
          key: 'update_user_failed',
          message: t('messages.user_error', { error: e.message }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          language: userLanguage,
        }}
        onSubmit={handleSubmit}
        validationSchema={UpdateUserDetails}
      >
        {({
          handleChange,
          values,
          errors,
          isSubmitting,
          touched,
          submitForm,
        }) => (
          <Form>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
            >
              <Typography variant="h1">{t('my_account')}</Typography>
              <Box alignItems="center" display="flex" flexDirection="row">
                {isEditing ? (
                  <>
                    <Button
                      disabled={isSubmitting}
                      onClick={() => submitForm()}
                      size="large"
                      startIcon={<SaveIcon />}
                      type="button"
                      variant="contained"
                    >
                      {t('save')}
                    </Button>
                    <Box className={classes.editButton}>
                      <Button
                        disabled={isSubmitting}
                        onClick={() => setIsEditing(false)}
                        size="large"
                        variant="outlined"
                      >
                        {t('cancel')}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className={classes.editContainer}>
                      <Button
                        onClick={() => setIsEditing(true)}
                        size="large"
                        startIcon={<EditIcon />}
                        variant="contained"
                      >
                        {t('edit')}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => toggleUpdatePasswordModal(true)}
                        size="large"
                        variant="outlined"
                      >
                        {t('update_password.edit_password')}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box mt={4}>
              {isEditing ? (
                <EditUserDetails
                  errors={errors}
                  handleChange={handleChange}
                  orgId={orgId}
                  touched={touched}
                  user={user}
                  values={values}
                />
              ) : (
                <ViewUserDetails user={user} />
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <UpdatePassword
        open={isUpdatePasswordOpened}
        setOpen={toggleUpdatePasswordModal}
      />
    </>
  )
}
