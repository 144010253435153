import { createFactory } from 'react'
import PropTypes from 'prop-types'
import ImageLoader from 'react-imageloader'
import fallbackUserAvatar from 'assets/fallback_user_avatar.svg'
import { CircularProgress } from '@mui/material'

import { useTranslation } from 'react-i18next'
import styles from './Misc.module.scss'

export default function ProfilePhotoAttachment({
  isEditing,
  placeholder,
  url,
  isLoading,
}) {
  const { t } = useTranslation('common')
  const isFallbackAvatar = url === '/fallback_user_avatar.svg'
  const imgProps = {
    style: {
      height: isFallbackAvatar ? 90 : '100%',
      width: isFallbackAvatar ? 90 : '100%',
    },
  }

  let output = (
    <ImageLoader
      imgProps={imgProps}
      preloader={() => <CircularProgress />}
      src={url}
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
      wrapper={createFactory('div')}
    >
      <img
        alt="user avatar"
        data-testid="profile-fallback-user-avatar"
        src={fallbackUserAvatar}
        style={{
          height: '90px',
          width: '90px',
        }}
      />
    </ImageLoader>
  )

  if (!url) {
    output = placeholder || (
      <img alt={t('user_avatar')} src={fallbackUserAvatar} />
    )
  }

  if (isLoading) {
    output = <CircularProgress />
  }

  return (
    <div
      className={styles.photoAttachWrapper}
      data-testid="profile-user-avatar"
    >
      {isEditing && (
        <div className={styles.uploaderWrapper}>
          <div className={styles.uploader}>
            <i className="icon icon-basic-photo icon-2x" />
          </div>
        </div>
      )}
      {output}
    </div>
  )
}

ProfilePhotoAttachment.propTypes = {
  isEditing: PropTypes.bool,
  placeholder: PropTypes.node,
  url: PropTypes.string,
}
