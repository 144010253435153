/* eslint-disable jsdoc/require-param */
import { useState } from 'react'
import styles from '../styles.module.scss'
import { DeleteGroupModal } from './delete-group'
import { UserGroup } from './group'

interface GroupsListProps {
  /** Group memberships data. */
  memberships: GroupMembership[]
  /** Handler to refetch. */
  refetch: () => void
}

/**
 * User's group lists.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function GroupsList({ memberships, refetch }: GroupsListProps) {
  const [open, setOpen] = useState(false)
  const [selectedMembership, setSelectedMembership] =
    useState<GroupMembership | null>(null)

  const items = memberships.map((membership) => (
    <UserGroup
      key={membership.id}
      membership={membership}
      onDelete={() => {
        setSelectedMembership(membership)
        setOpen(true)
      }}
    />
  ))

  return (
    <>
      <ul className={styles.groupsList}>{items}</ul>
      {selectedMembership ? (
        <DeleteGroupModal
          membership={selectedMembership}
          open={open}
          refetch={refetch}
          setOpen={setOpen}
        />
      ) : null}
    </>
  )
}
