/**
 * @module LanguagesRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import { Route, Routes } from 'react-router-dom'
import { Languages } from 'containers/languages'
import { Box } from '@mui/material'

export default function LanguagesRoutes() {
  return (
    <Box mt={2}>
      <Routes>
        <Route index={true} element={<Languages />} />
      </Routes>
    </Box>
  )
}
