/* eslint-disable jsdoc/require-param */
/* eslint-disable react/no-multi-comp */
/**
 * @module KeywordField
 */
import * as React from 'react'

import { Box, Chip } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Add as AddIcon } from '@mui/icons-material'
import update from 'immutability-helper'
import { Plan } from 'components/Plans/types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    categoryPills: {
      marginInlineEnd: theme.spacing(2),
      marginBlockStart: theme.spacing(1),
    },
    input: {
      borderRadius: theme.spacing(2),
      margin: theme.spacing(0),
      padding: '8px 15px',
      width: '100%',
    },
  }),
)

function addToListChecker(key: string) {
  switch (key) {
    case 'Enter':
      return true
    default:
      return false
  }
}

interface InputChipProps {
  /** Function to submit whatever that was entered. */
  onSubmit: (value: string) => void
  /** Function that allows us to do something when input loses focus. */
  onBlur: React.FocusEventHandler<HTMLInputElement>
  /** Boolean value that dictates when input should show. */
  showAddInput: boolean
}

/**
 * Component that shows up so users can add more keywords.
 *
 * @returns {React.ReactElement} - Input component.
 */
function InputChip({ onSubmit, onBlur, showAddInput }: InputChipProps) {
  const classes = useStyles()
  const { t } = useTranslation('plans')

  const ref = React.useRef<HTMLInputElement | null>(null)
  const [keyword, setKeyword] = React.useState('')

  React.useEffect(() => {
    if (showAddInput && ref && ref.current) {
      ref.current.focus()
    }
  }, [showAddInput])

  return (
    <input
      className={classes.input}
      data-testid="addKeyword"
      onBlur={onBlur}
      onChange={(e) => setKeyword(e.target.value)}
      onKeyDown={(e) => {
        if (addToListChecker(e.key)) {
          if (keyword.trim() !== '') {
            onSubmit(keyword.trim())
            setKeyword('')
          }
        }
      }}
      placeholder={t('edit_plan_info.keyword')}
      ref={ref}
      value={keyword}
    />
  )
}

/**
 * Component to manage keywords for plan.
 *
 * @returns {React.ReactElement} - Component to help add/remove keywords.
 */
export function KeywordField({
  plan,
  updatePlanDetails,
}: {
  plan: Plan.Plan
  updatePlanDetails: (planDetailToUpdate: Record<string, any>) => void
}) {
  const classes = useStyles()
  const { t } = useTranslation('plans')

  const [showAddInput, setShowAddInput] = React.useState(false)

  function addKeyword(newKeyword: string) {
    if (newKeyword.trim().length) {
      const oldKeywords = (plan.keywords || []).map((keyword) => {
        return keyword.toLowerCase()
      })
      if (!oldKeywords.includes(newKeyword.toLowerCase()))
        updatePlanDetails({
          keywords: [newKeyword, ...oldKeywords],
        })
    }
  }

  function removeKeyword(keywordIdx: number) {
    const filteredKeywords = update(plan.keywords, {
      $splice: [[keywordIdx, 1]],
    })

    updatePlanDetails({ keywords: filteredKeywords })
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap">
      <Box mr={1} mt={1}>
        {showAddInput ? (
          <InputChip
            onBlur={() => setShowAddInput(false)}
            onSubmit={addKeyword}
            showAddInput={showAddInput}
          />
        ) : (
          <Chip
            deleteIcon={<AddIcon data-testid="addKeywordIcon" />}
            label={t('edit_plan_info.add_keyword')}
            onDelete={() => setShowAddInput(true)}
            variant="outlined"
          />
        )}
      </Box>
      {plan.keywords?.map((singleKeyword, singleKeywordIdx) => {
        const key = `${singleKeyword} ${singleKeywordIdx}`
        return (
          <Chip
            className={classes.categoryPills}
            color="secondary"
            key={key}
            label={singleKeyword}
            onDelete={() => removeKeyword(singleKeywordIdx)}
          />
        )
      })}
    </Box>
  )
}
