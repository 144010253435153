import { TableCell, TableHead, TableRow } from '@mui/material'

const headCells = [
  {
    id: 'avatar',
    label: '',
  },
  { id: 'name', label: 'Name' },
  { id: 'actions', label: 'Actions' },
]

export function OrganizationMembershipsTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell align="left" key={headCell.id} padding="normal">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
