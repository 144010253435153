/**
 * @module LinkOffButton
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AsyncButton } from '@youversion/react'
import { gray } from '@youversion/react/styles/colors-v3'
import { CenterFocusStrong } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles(() => ({
  makeSourceButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[30],
    },
    marginInlineStart: '0.5rem',
    whiteSpace: 'nowrap',
  },
  submitButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[20],
    },
    marginInlineStart: '0.5rem',
  },
}))

export const MakeSourceButton = () => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const classes = useStyles()
  return (
    <>
      <AsyncButton
        className={classes.makeSourceButton}
        idle="Make Source Plan"
        // TODO placeholder function until we are ready to implement logic
        onClick={() => setIsDialogOpen(!isDialogOpen)}
        options={{ disableUpdateOnSuccess: true }}
        rejected="Error"
        startIcon={<CenterFocusStrong />}
        variant="contained"
      />
      <Dialog
        aria-describedby="make-source-plan-dialog-content"
        aria-labelledby="make-source-plan-dialog-title"
        onClose={() => setIsDialogOpen(!isDialogOpen)}
        open={isDialogOpen}
      >
        <DialogTitle id="make-source-plan-dialog-title">
          Make Source Plan
        </DialogTitle>
        {/* TODO Temporary Dialog Content until the API is ready. */}
        <DialogContent>
          <DialogContentText id="make-source-plan-dialog-content">
            The Spanish translation will replace the English translation as the
            Source Plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setIsDialogOpen(!isDialogOpen)}
          >
            Cancel
          </Button>
          <AsyncButton
            className={classes.submitButton}
            color="primary"
            idle="Confirm"
            onClick={() => setIsDialogOpen(!isDialogOpen)}
            options={{ disableUpdateOnSuccess: true }}
            pending="Confirm"
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
