/**
 * @module BibleReferencePills
 */
import { Box, Chip } from '@mui/material'
import { useGetBibleVersion } from 'api/bible-versions'

import { translateReference } from 'helpers'
import { useTranslation } from 'react-i18next'

interface Props {
  isEditing?: boolean
  onDeleteReference?: (reference: string) => void
  references: Array<string>
}

/**
 * BibleReferencePills component.
 *
 * @alias module:BibleReferencePills
 *
 * @param {object} props - The component props.
 * @param {boolean} [props.isEditing] - Whether or not the pills are being edited.
 * @param {Function} props.onDeleteReference - The onDelete handler.
 * @param {Array<string>} [props.references] - The USFM references array.
 *
 * @returns {React.ReactElement} - The BibleReferencePills component.
 *
 * @example
 * <BibleReferencePills
 *   isEditing={false}
 *   onDeleteReference={
 *     (reference, index) => handleDeleteReference(reference, index)
 *   }
 *   references={['jhn.1.1']}
 * />
 */
const BibleReferencePills = ({
  isEditing = false,
  onDeleteReference,
  references = [],
}: Props) => {
  const { t, i18n } = useTranslation(['common'])
  const { data, isLoading } = useGetBibleVersion(i18n.language)

  return (
    <Box display="flex" flexWrap="wrap" width="100%">
      {references.map((reference) => (
        <Box key={reference} mb={1} mr={1}>
          <Chip
            color="secondary"
            label={
              isLoading
                ? undefined
                : translateReference(
                    reference,
                    data?.books,
                    t('common:unknown_book'),
                  )
            }
            onDelete={
              isEditing && onDeleteReference
                ? (e) => {
                    e.preventDefault()
                    onDeleteReference(reference)
                  }
                : undefined
            }
          />
        </Box>
      ))}
    </Box>
  )
}

export default BibleReferencePills
