/**
 * @module UserList
 */
import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import ImageLoader from 'react-imageloader'
import { Loader } from 'components'
import styles from 'components/Organizations/UserTable.module.scss'

/**
 * The UserList component.
 *
 * @param {object} props - The component props object.
 * @param {Array<object>} props.users - The users list.
 *
 * @returns {React.ReactElement} - The UserList component.
 */
export default function UserList({ users }) {
  const navigate = useNavigate()

  if (users.length === 0) {
    return <p>This group does not have any users.</p>
  }

  return (
    <table className={styles.userList}>
      <thead>
        <tr>
          <th colSpan="2">Name</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <tr key={user.id} onClick={() => navigate(`/users/${user.id}`)}>
              <td className={styles.photo}>
                <div className={styles.listImageWrapper}>
                  <ImageLoader
                    preloader={() => <Loader />}
                    src={
                      user.profile_photo && user.profile_photo.asset.thumb_url
                    }
                    wrapper={React.createFactory('div')}
                  >
                    Image load failed!
                  </ImageLoader>
                </div>
              </td>
              <td>
                {user.first_name} {user.last_name}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

UserList.displayName = 'Groups/Pages/Show/Users/List'

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profile_photo: PropTypes.shape({
        asset: PropTypes.shape({
          thumb_url: PropTypes.string,
        }),
      }),
    }),
  ),
}

UserList.defaultProps = {
  users: [],
}
