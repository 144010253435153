import _ from 'lodash'
import { addNotification } from 'state/reducers/globalNotifications'
import { SubmissionError, reset, submit } from 'redux-form'
import { ORGS_PER_PAGE } from 'helpers/constants'
import { orgSchema, orgsSchema } from 'state/reducers/organizationsReducer'
import { persistActiveOrgId } from 'state/reducers/persistReducer'
import {
  CANCEL_EDIT_ORG,
  EDIT_ORG,
  LIST_ORGANIZATIONS,
  LOAD_ACTIVE_ORG,
  LOAD_INITIAL_ORG,
  LOAD_ORGS_ROUTE,
  SET_ORGS_FILTERS,
  SET_ORGS_SORT,
  SHOW_ORG,
  SUBMIT_FORM,
  SWITCH_ACTIVE_ORG,
  SWITCH_VIEW_ALL_ORGS,
  UPDATE_ORG,
} from './constants'
import { standardAction } from '../../utils'

const REDUX_FORM_ID = 'orgInfo'

function removeNulls(obj) {
  return _.pickBy(obj, _.identity)
}

export function approve(orgId) {
  return (dispatch) => {
    dispatch(update(orgId, { status: 'approved' }))
  }
}

export function switchActiveOrg(id) {
  return function switchActiveOrg(dispatch) {
    dispatch({
      type: SWITCH_ACTIVE_ORG,
      payload: id,
    })

    dispatch(persistActiveOrgId())
    dispatch(loadActiveOrg(id))
  }
}

/*
  Load a single organization for the activeOrgId in redux state
*/

export function loadActiveOrg(id) {
  return async function loadActiveOrg(dispatch) {
    return dispatch({
      type: LOAD_ACTIVE_ORG,
      api: async (client) => {
        return client.get(`/organizations/${id}`)
      },
    })
  }
}

/*
  For loading a single Org belonging to the logged in user in the
  scenario where they are logging into the application for the first time,
  or do not have an active organization id set in localstorage (orgId localstorage key)
 */
export function loadInitialOrg() {
  return async function loadInitialOrg(dispatch) {
    return dispatch({
      type: LOAD_INITIAL_ORG,
      api: async (client) => {
        return client.get(`/organizations?per=1`)
      },
    })
  }
}

export function viewAllOrgs() {
  return function viewAllOrgs(dispatch) {
    dispatch({ type: SWITCH_VIEW_ALL_ORGS })
    dispatch(persistActiveOrgId())
  }
}

export function show(id) {
  return async function read(dispatch) {
    return dispatch({
      type: SHOW_ORG,
      api: async (client) => {
        return client.get(`/organizations/${id}`)
      },
    })
  }
}

export function edit() {
  return standardAction(EDIT_ORG)
}

export function update(id, patchData) {
  return async function update(dispatch) {
    return dispatch({
      type: UPDATE_ORG,
      api: async (client) => {
        return client.patch(`/organizations/${id}`, orgSchema, {
          data: {
            ...patchData,
          },
        })
      },
    })
  }
}

export function handleSubmit(orgId, formValues) {
  return async (dispatch) => {
    dispatch(standardAction(SUBMIT_FORM))

    const {
      profile_photo: profilePhoto,
      partnership_agreement: partnershipAgreement,
      staff_representative: staffRepresentative,
      ...restValues
    } = formValues

    restValues.profile_photo_id = profilePhoto && profilePhoto.id
    restValues.partnership_agreement_id =
      partnershipAgreement && partnershipAgreement.id

    // Before sending the data, add "staff_representative_id" to the request,
    // (if staffRepresentative is present)
    // and set it to the value selected in the Staff Rep dropdown:
    restValues.staff_representative_id =
      staffRepresentative && staffRepresentative.value

    const response = await dispatch(update(orgId, restValues))

    if (response.error) {
      dispatch(
        addNotification(
          'Something went wrong updating the organization',
          'error',
        ),
      )

      throw new SubmissionError({
        ...response.details,
        _error: 'Something went wrong updating the organization.',
      })
    } else {
      dispatch(show(response.result.organization))
    }
  }
}

export function loadOrgsRoute() {
  return async function loadOrgsRoute(dispatch, getState) {
    const params = {
      pagination: {
        per: ORGS_PER_PAGE, // Always present.
      },
      filters: {},
      sorter: {},
    }

    // Add filters, but only if present:
    const { orgsFilters } = getState().organizations
    if (orgsFilters.name) {
      params.filters.name = orgsFilters.name
    }

    if (orgsFilters.plans_count) {
      params.filters.plans_count = orgsFilters.plans_count
    }
    if (orgsFilters.status) {
      params.filters.status = orgsFilters.status
    }
    if (orgsFilters.plan_updated_date) {
      params.filters.plan_updated_date = orgsFilters.plan_updated_date
    }
    if (orgsFilters.plan_language) {
      params.filters.plan_language = orgsFilters.plan_language
    }

    return dispatch(list(params, LOAD_ORGS_ROUTE))
  }
}

/** Organizations#list
 * GET request to return a list of Organizations.
 *
 * There are a handful of ways to customize this API call below.
 *
 * @param {object} options - Options to customize list API call.
 *
 * @param {object} options.pagination - Pagination options for paginated results.
 * @param {number} options.pagination.page - What page number to request.
 * @param {number} options.pagination.per - How many per page to request.
 *
 * @param {object} options.sorter - Sorting options.
 * @param {string} options.sorter.sort - Field to sort on.
 * @param {string} options.sorter.order - Sort direction - 'asc' | 'desc'.
 *
 * @param {object} options.filters - Filtering options.
 * @param {string} options.filters.name - Organization name filter.
 * @param {string} options.filters.status - Organization status - currently unused.
 * @param {number} options.filters.plans_count - Plans count filter.
 * @param { Date }   options.filters.plan_updated_date
 * @param {string} options.filters.plan_language - Plan language identifier.
 */

export function list(
  { pagination = {}, sorter = {}, filters = {} } = {},
  action_type = LIST_ORGANIZATIONS,
) {
  return async function list(dispatch) {
    const params = removeNulls({
      per: pagination.per || ORGS_PER_PAGE,
      page: pagination.page || 1,
      sort: sorter.sort,
      order: sorter.order,
      name: filters.name,
      plans_count: filters.plans_count,
      plan_language: filters.plan_language,
      plan_updated_date: filters.plan_updated_date,
    })

    return dispatch({
      type: action_type,
      api: async (client) => {
        return client.get('/organizations', orgsSchema, {
          params: {
            ...params,
          },
        })
      },
    })
  }
}

/**
 * Organizations#search.
 *
 *
 * This action should be used when you want to list Organizations
 * WHILE maintaining sorter and filters from Redux state.
 *
 * It is possible to override any option in the #search call.
 * Any value that is not overridden AND is present in Redux state
 * will be added to the params forwarded to #list.
 *
 * @see For all available options see #list action.
 *
 */

export function search(
  { pagination = {}, sorter = {}, filters = {} } = {},
  action_type = LIST_ORGANIZATIONS,
) {
  return async function search(dispatch, getState) {
    const { orgsSorter, orgsFilters } = getState().organizations

    const params = {}
    params.pagination = removeNulls({
      per: pagination.per || ORGS_PER_PAGE,
      page: pagination.page || 1,
    })
    params.sorter = removeNulls({
      sort: sorter.sort || orgsSorter.sort,
      order: sorter.order || orgsSorter.order,
    })
    params.filters = removeNulls({
      name: filters.name || orgsFilters.name,
      plans_count: filters.plans_count || orgsFilters.plans_count,
      plan_language: filters.plan_language || orgsFilters.plan_language,
      plan_updated_date:
        filters.plan_updated_date || orgsFilters.plan_updated_date,
    })

    return dispatch(list(params, action_type))
  }
}
