/**
 * @module SidebarItem
 */
import React, { FC } from 'react'
import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Can } from 'auth'
import { MuiNavLinkProps } from 'components/Navigation/mui-nav-link'
import { Tag } from '@youversion/design-system'

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      color: 'inherit',
    },
  },
  listItemIcon: {
    color: 'inherit',
    minWidth: 'fit-content',
    paddingInlineEnd: theme.spacing(1),
  },
  tagItem: {
    marginInlineStart: '0.5rem',
  },
  listItemText: {
    flex: 'none',
    marginInline: '0.5rem',
  },
  helpEndButton: {
    marginInlineStart: 'auto',
  },
}))

interface SidebarItemProps {
  // Permission(s) to check before displaying the item.
  can?: string
  // Button component at the end of the sidebar item, typically a MUI Icon Button.
  endButton?: React.ReactElement | null
  // Determines if the sidebar item will have the ripple effect or not.
  showRippleEffect?: boolean
  // Determines if the sidebar item is selected.
  isSelected?: boolean
  // MUI Icon at the beginning of the sidebar item.
  startIcon?: React.ReactElement
  // Name describing the SidebarItem.
  text: string | React.ReactElement
  // Function called when the SidebarItem is clicked.
  onClick?: Function
}

type ConditionalSideBarItemProp =
  | {
      component?: FC<MuiNavLinkProps>
      // React Router path of the page's destination.
      to: string
      href?: never
    }
  | {
      component?: 'a'
      // Link to redirect user when sidebar item is clicked.
      href: string
      to?: never
    }
  | {
      component?: never
      href?: never
      to?: never
    }

type Props = SidebarItemProps & ConditionalSideBarItemProp

export const SidebarItem: FC<Props> = ({
  can,
  endButton,
  href,
  showRippleEffect = false,
  isSelected = false,
  startIcon = null,
  text,
  to,
  onClick,
  component: componentProp,
}) => {
  const classes = useStyles()

  let component = (
    <ListItem
      className={classes.listItem}
      onClick={onClick ? () => onClick() : undefined}
      role="link"
      selected={isSelected}
    >
      {startIcon ? (
        <ListItemIcon
          classes={{
            root: classes.listItemIcon,
          }}
        >
          <Box fontSize="large">{startIcon}</Box>
        </ListItemIcon>
      ) : null}

      {text === 'Help' ? (
        <>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary={text}
          />
          <Tag
            className={classes.tagItem}
            message="New"
            size="sm"
            variant="info"
          />
          <IconButton
            classes={{ root: classes.helpEndButton }}
            data-testid="openInNewIcon"
            disabled={true}
            edge="end"
            size="large"
          >
            {endButton}
          </IconButton>
        </>
      ) : (
        <>
          <ListItemText
            classes={{ root: classes.listItemText }}
            primary={text}
          />
          {endButton}
        </>
      )}
    </ListItem>
  )

  interface ComponentTypeProps {
    component?: FC<MuiNavLinkProps> | string
    href?: string
    to?: string
    target?: string
  }
  const componentTypeProps: ComponentTypeProps = {
    component: undefined,
    href,
    to,
    target: undefined,
  }

  componentTypeProps.component = componentProp
  componentTypeProps.href = href
  componentTypeProps.to = to

  if (typeof componentTypeProps.component === 'string') {
    componentTypeProps.target = '_blank'
  }

  // If neither `href` or `to` prop is passed then we want the ListItem to be a regular ListItem component.
  // TypeScript does not allow ListItem `component` prop to be undefined.
  if (componentProp) {
    component = React.cloneElement(component, componentTypeProps)
  }

  // ListItem button prop only supports type `true`. Because of that,
  // we have to pass the `button` prop to ListItem in order to get the ripple effect.
  if (showRippleEffect) {
    component = React.cloneElement(component, { button: true })
  }
  return <>{can ? <Can user={can}>{component}</Can> : component}</>
}
