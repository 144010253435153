/* eslint-disable jsdoc/require-param */
/**
 * @module UserGroups
 */
import { ReactElement, useMemo } from 'react'
import { useGetGroups } from 'api/groups'
import { useGetGroupMemberships } from 'api/group_memberships'
import { ManageGroupMemberships } from 'components/MyAccount/Groups'
import { Alert, Box, Typography } from '@mui/material'
import { API_STATUS } from 'helpers'

interface UserGroupsProps {
  user: User
}

/**
 * The UserGroups component.
 *
 * @alias module:UserGroups
 *
 * @returns {ReactElement} - The UserGroups component.
 */
export function UserGroups({ user }: UserGroupsProps) {
  const {
    status: membershipLoadingStatus,
    data: groupMemberships,
    refetch: refetchGroupMemberships,
  } = useGetGroupMemberships(user.id)

  const { status: groupsLoadingStatus, data: groups } = useGetGroups()

  const remainingGroups = useMemo(() => {
    if (!groups || !groupMemberships) {
      return []
    }
    return groups.filter((group) => {
      return !groupMemberships.find((item) => item.group.id === group.id)
    })
  }, [groupMemberships, groups])

  if (
    membershipLoadingStatus === API_STATUS.LOADING ||
    groupsLoadingStatus === API_STATUS.LOADING
  ) {
    return (
      <Box mt={2}>
        <Typography>Loading Groups...</Typography>
      </Box>
    )
  }

  if (
    membershipLoadingStatus === API_STATUS.ERROR ||
    groupsLoadingStatus === API_STATUS.ERROR
  ) {
    return <Alert severity="error">Unable to load groups for this user.</Alert>
  }

  return (
    <>
      {groupMemberships && groups ? (
        <ManageGroupMemberships
          groups={remainingGroups}
          refetchGroupMemberships={refetchGroupMemberships}
          user={user}
          userGroupMemberships={groupMemberships}
        />
      ) : null}
    </>
  )
}
