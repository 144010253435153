/* eslint-disable jsdoc/require-param */
/**
 * @module NewOrganizationLandingPage
 */
import { Box, Button, Text } from '@youversion/design-system'
import { LazyImage } from '@youversion/react'
import PlanCardStack from 'assets/plan-card-stack.png'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './index.css'

interface Props {
  isViewingSingleOrg: boolean
}

const NewOrganization: FC<Props> = ({ isViewingSingleOrg }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('plans')

  return (
    <Box
      alignItems="center"
      className={styles.box}
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <LazyImage
        alt="plans-landing-page"
        aspectRatio="338.5/285.5"
        height="285.5px"
        src={PlanCardStack}
        width="338.5px"
      />
      <Text marginBlockStart={'sm'} variant="headline">
        {t('no_plans_title')}
      </Text>
      <Text marginBlockEnd={'xl'} variant="smallBody">
        {t('no_plans_desc')}
      </Text>
      {isViewingSingleOrg ? (
        <Button
          iconInlineStart={<AddIcon />}
          onClick={() => navigate('/plans/new')}
          target="blank"
          theme="contrast"
        >
          {t('button.get_started')}
        </Button>
      ) : null}

      <a
        href="https://www.partners.youversion.com/creating-a-plan"
        target="__blank"
      >
        <Text
          lineHeight="snug"
          marginBlockStart="base"
          spacing="2xl"
          variant="link"
        >
          {t('or_learn_more')}
        </Text>
      </a>
    </Box>
  )
}

export default NewOrganization
