import * as React from 'react'

/**
 * Checks if component is mounted or not.
 *
 * @returns {boolean} - Indicates whether or not a component is mounted.
 */
export function useIsComponentMounted() {
  const isMounted = React.useRef(false)
  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}
