import { Dispatch, SetStateAction } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { NumberLike } from 'types/misc'
import { AsyncButton, useAlert } from '@youversion/react'
import { API_STATUS } from 'helpers'
import { yvRed } from '@youversion/react/styles/colors-v3'
import { useUpdateOrganization } from 'api/organizations'
import { useTranslation } from 'react-i18next'

interface UpdateOrganizationModalProps {
  organizationId?: NumberLike
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  refetch: VoidFunction
}

const useStyles = makeStyles(() => ({
  deleteButton: {
    backgroundColor: yvRed,
    '&:hover': {
      backgroundColor: yvRed,
    },
  },
}))

export function RejectOrganization({
  organizationId,
  show,
  setShow,
  refetch,
}: UpdateOrganizationModalProps) {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'organizations'])

  const { mutateAsync: updateOrganization, status: loadingUpdateStatus } =
    useUpdateOrganization(organizationId)

  const { throwAlert } = useAlert()

  async function handleUpdateOrganization() {
    try {
      await updateOrganization({ status: 'rejected' })
      refetch()
      throwAlert({
        id: 'update_organization_success',
        key: 'update_organization_success',
        message: t('organizations:reject_organization.success_message'),
        timeout: 3000,
        type: 'success',
      })
      setShow(false)
    } catch (error) {
      throwAlert({
        id: 'update_organization_error',
        key: 'update_organization_error',
        message: t('organizations:reject_organization.failed_message'),
        timeout: 3000,
        type: 'error',
      })
    }
  }

  const isLoading = loadingUpdateStatus === API_STATUS.LOADING

  return (
    <>
      <Dialog maxWidth="xs" onClose={() => setShow(false)} open={show}>
        <DialogTitle>
          {t('organizations:reject_organization.title')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {t('organizations:reject_organization.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus={true}
            disabled={isLoading}
            onClick={() => setShow(false)}
            type="button"
          >
            {t('common:close')}
          </Button>
          <AsyncButton
            className={classes.deleteButton}
            color="primary"
            disabled={isLoading}
            idle={t('organizations:reject_organization.button.idle')}
            onClick={handleUpdateOrganization}
            options={{
              disableUpdateOnSuccess: true,
            }}
            pending={t('organizations:reject_organization.button.loading')}
            rejected={t('organizations:reject_organization.button.failed')}
            resolved={t('organizations:reject_organization.button.success')}
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
