/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @module NewPlan
 */
import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { LazyImage, throwAlert } from '@youversion/react'
import { Formik } from 'formik'
import { Breadcrumbs } from 'components/Navigation/breadcrumbs'
import ParPorHeart from 'assets/ParPorHeart.png'
// eslint-disable-next-line import/no-named-as-default
import CreatePlanForm from 'components/Plans/CreatePlanForm'
import { getActiveOrg } from 'helpers/get-active-org'
import { useCreatePlan } from 'api/plans'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  biblePlanText: {
    paddingBlock: theme.spacing(4),
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '500px',
    textAlign: 'center',
  },
  heartImg: {
    alignSelf: 'center',
  },
}))

interface FormProps {
  languageId: string | number
  planTitle: string
}

/**
 * When a Partner creates a plan for the first time, they will only have two fields to fill out to get started.
 *
 * @returns {React.ReactElement} - The NewPlan Component returns the NewForm component.
 */
export default function NewPlan() {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation('plans')

  const orgId = useMemo(() => getActiveOrg(), [])
  const { mutate, status } = useCreatePlan()

  // language initial value needs to be empty string
  // instead of 0 because MUI select only accepts strings.
  const initialFormValues = {
    languageId: '',
    planTitle: '',
  }

  async function handleSubmit({ planTitle, languageId }: FormProps) {
    if (typeof orgId !== 'number') {
      return throwAlert({
        id: 'select_org_to_continue',
        key: 'select_org_to_continue',
        message: t('select_org_to_continue'),
        timeout: 3000,
        type: 'error',
      })
    }

    if (languageId) {
      mutate(
        {
          languageId,
          titleValue: planTitle.trim(),
          organizationId: orgId,
        },
        {
          onSuccess: (response) => {
            navigate(`/plans/${response?.id}`)
          },
          onError: (error) => {
            if (error instanceof Error) {
              throwAlert({
                id: 'create_plan_error',
                key: 'create_plan_error',
                message: t('failed_to_create_plan', { message: error.message }),
                timeout: 3000,
                type: 'error',
              })
            }
          },
        },
      )
    }
  }

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" marginTop="16px">
        <Box mb="2rem">
          <Breadcrumbs
            pathProps={{
              new: { title: t('new') },
              plans: { title: t('plans') },
            }}
          />
          <Typography color="textPrimary" variant="h1">
            {t('create_plan')}
          </Typography>
        </Box>

        <section className={classes.formContainer}>
          <LazyImage
            alt={t('create_plan_img_alt')}
            aspectRatio={741 / 644}
            className={classes.heartImg}
            height="215px"
            src={ParPorHeart}
            width="250px"
          />
          <Typography
            className={classes.biblePlanText}
            color="textPrimary"
            variant="h2"
          >
            {t('create_plan_desc')}
          </Typography>
          <CreatePlanForm status={status} />
        </section>
      </Box>
    </Formik>
  )
}
