/* eslint-disable jsdoc/require-param */
import { Box, Grid, Typography } from '@mui/material'
import ProfilePhotoAttachment from 'components/misc/ProfilePhotoAttachment'
import { useTranslation } from 'react-i18next'
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage'
import { useGetTranslatableLanguages } from 'api/languages'
import styles from '../../MyAccount.module.scss'

interface ViewUserDetailsProps {
  user: LoggedInUser
}

/**
 * Renders User details.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function ViewUserDetails({ user }: ViewUserDetailsProps) {
  const { t, i18n } = useTranslation(['users', 'common'])
  const {
    data: languages,
    isError: isLanguagesError,
    isLoading: isLanguagesLoading,
  } = useGetTranslatableLanguages()

  const userLanguage = i18n.language

  let language: string | undefined

  if (isLanguagesLoading) {
    language = t('common:loading')
  } else if (isLanguagesError) {
    language = getLocalizedLanguage(userLanguage, userLanguage)
  } else if (languages) {
    const apiLanguage = languages.find((lang) => lang.locale === userLanguage)
    if (apiLanguage) {
      language = apiLanguage.localName
    } else {
      language = getLocalizedLanguage(userLanguage, userLanguage)
    }
  }

  return (
    <>
      <Box className={styles.fileUploaderWrapper} mb={5}>
        <ProfilePhotoAttachment
          isEditing={false}
          isLoading={false}
          url={user.profile_photo?.source_url}
        />
      </Box>
      <Grid container={true} spacing={2}>
        <Grid item={true} md={6} xs={12}>
          <Typography
            color="textSecondary"
            display="block"
            gutterBottom={true}
            variant="subtitle1"
          >
            {t('users:labels.first_name')}
          </Typography>

          <Typography display="block" gutterBottom={true} variant="body1">
            {user.first_name}
          </Typography>
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Typography
            color="textSecondary"
            display="block"
            gutterBottom={true}
            variant="subtitle1"
          >
            {t('users:labels.last_name')}
          </Typography>

          <Typography display="block" gutterBottom={true} variant="body1">
            {user.last_name}
          </Typography>
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Typography
            color="textSecondary"
            display="block"
            gutterBottom={true}
            variant="subtitle1"
          >
            {t('users:labels.email_address')}
          </Typography>

          <Typography display="block" gutterBottom={true} variant="body1">
            {user.email}
          </Typography>
        </Grid>
        {user.can('administrate:all_permissions') ? (
          <Grid item={true} md={6} xs={12}>
            <Typography
              color="textSecondary"
              display="block"
              gutterBottom={true}
              variant="subtitle1"
            >
              {t('users:labels.account_status')}
            </Typography>
            {user.status && <div>{t(`status.${user.status}`)}</div>}
          </Grid>
        ) : null}
        <Grid item={true} md={6} xs={12}>
          <Typography
            color="textSecondary"
            display="block"
            gutterBottom={true}
            variant="subtitle1"
          >
            {t('users:labels.account_language')}
          </Typography>
          <div>{language}</div>
        </Grid>
      </Grid>
    </>
  )
}
