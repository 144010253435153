/* eslint-disable jsdoc/require-param */
/**
 * @module Pagination
 */
import { ReactElement } from 'react'
import { Box, Fade, Pagination, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useSearchParams } from 'react-router-dom'
import { FetchPlans } from 'api/plans/types'

const useStyles = makeStyles((theme) => ({
  pagination: {
    alignSelf: 'stretch',
    borderBlockStart: `1px solid ${theme.palette.divider}`,
  },
}))

interface PaginationComponentProps {
  /** Indicates status of fetching plans. */
  dataIsPending: boolean
  /** Plans pagination data. */
  meta?: FetchPlans.PlansMeta
  /** Function to get pagination string. */
  getPaginationString: () => string
}

/**
 * Returns the pagination component.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function PaginationComponent({
  dataIsPending,
  meta,
  getPaginationString,
}: PaginationComponentProps) {
  const [query, setQuery] = useSearchParams()
  const classes = useStyles()

  return (
    <Fade in={!dataIsPending}>
      <Box
        alignItems="center"
        className={classes.pagination}
        data-testid="plans-grid-pagination-bar"
        display="flex"
        flexGrow={1}
        justifyContent="space-between"
        width="100%"
      >
        <Pagination
          count={meta?.total_pages ?? 0}
          onChange={(event, newPage) => {
            query.set('page', newPage.toString())
            setQuery(query)
          }}
          page={meta?.current_page ?? 0}
        />
        <Box
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
          pr={2}
        >
          <Typography color="textSecondary" variant="body1">
            {!dataIsPending ? getPaginationString() : ''}
          </Typography>
        </Box>
      </Box>
    </Fade>
  )
}
