import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

interface Props {
  group: Group
}

export default function Row({ group }: Props) {
  const navigate = useNavigate()

  return (
    <tr
      onClick={() => {
        navigate(`/groups/${group.id}`)
      }}
    >
      <td>
        <Box mb="1rem">
          <Typography variant="h3">{group.name}</Typography>
        </Box>
        <Typography variant="subtitle1">{group.description}</Typography>
      </td>
    </tr>
  )
}
