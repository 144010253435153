/* eslint-disable jsdoc/require-param */
/**
 * @module DeleteDialog
 */
import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { yvRed } from '@youversion/react/styles/colors-v3'
import { useNavigate } from 'react-router-dom'
import { useDeletePlan } from 'api/plans'
import MuiLoadingButton from 'components/Buttons/mui-loading-button'
import { useAlert } from '@youversion/react'
import { NumberLike } from 'types/misc'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    backgroundColor: yvRed,
    color: 'white',
    marginInlineEnd: theme.spacing(2),
    '&:hover': {
      backgroundColor: yvRed,
    },
  },
  dialogActions: {
    marginBlockEnd: theme.spacing(1),
  },
}))

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  planId: NumberLike
}

/**
 * Delete Dialog.
 *
 * @returns {React.ReactElement} - Delete a plan component.
 */
export function DeleteDialog({ open, setOpen, planId }: Props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'plans'])

  const { mutate, isLoading } = useDeletePlan()

  const handleClickCancel = () => {
    setOpen(false)
  }

  const { throwAlert } = useAlert()

  const handleClickDelete = async () => {
    mutate(planId, {
      onSuccess: () => {
        navigate('/plans')
        throwAlert({
          id: 'plan_delete_success',
          key: 'plan_delete_success',
          message: t('plans:edit_plan_info.successful_deletion'),
          type: 'success',
          timeout: 3000,
        })
      },
      onError: (error) => {
        if (error instanceof Error) {
          throwAlert({
            id: 'plan_delete_success',
            key: 'plan_delete_success',
            message: t('plans:edit_plan_info.unsucessful_deletion'),
            type: 'error',
            timeout: 3000,
          })
        }
      },
    })
  }

  return (
    <Dialog onClose={handleClickCancel} open={open}>
      <DialogTitle>{t('plans:edit_plan_info.delete_entire_plan')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('plans:edit_plan_info.delete_warning')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="primary"
          disabled={isLoading}
          onClick={handleClickCancel}
        >
          {t('common:cancel')}
        </Button>
        <MuiLoadingButton
          className={classes.deleteButton}
          isLoading={isLoading}
          onClick={handleClickDelete}
          size="medium"
          variant="contained"
        >
          {t('plans:edit_plan_info.delete')}
        </MuiLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

DeleteDialog.defaultProps = {
  planId: null,
}
