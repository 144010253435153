/**
 * Gets the user's authentication token.
 */
export const getToken = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const token = JSON.parse(localStorage.getItem('token') || '')
    if (token && typeof token === 'string' && token.length) {
      resolve(token)
    }
    reject(new Error('Bad token'))
  })
}
