import React from 'react'
import { handle } from 'lib/redux-pack'
import bookTranslations from 'assets/book-translations.json'

export * from './parse-date'
export * from './parse-day-number'
export * from './sort-days'
export * from './convert-html-to-text'
export * from './constants'
export * from './replace-url-param'
export * from './get-readable-time-from-seconds'
export * from './truncate-number'
export * from './is-valid-web-url'

/**
 * Gets the user's authentication token.
 *
 * @returns {Promise<string>} - The authentication token.
 */
export async function getToken() {
  return new Promise((resolve, reject) => {
    const token = JSON.parse(localStorage.getItem('token'))
    if (token && typeof token === 'string' && token.length) {
      resolve(token)
    }
    reject(new Error('Bad token'))
  })
}

export function shortFilename(path) {
  if (!path) {
    return
  }
  // input: "/some/long/path/file.pdf?123123123"
  // output: "file.pdf"
  return path.split('/').slice(-1)[0].split('?')[0]
}

export function handleApiAction(cb) {
  return (state, action) => handle(state, action, cb(state, action))
}

// https://davidwalsh.name/convert-xml-json
export function xmlToJson(xml) {
  // Create the return object
  let obj = {}

  if (xml.nodeType === 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj['@attributes'] = {}
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j)
        obj['@attributes'][attribute.nodeName] = attribute.nodeValue
      }
    }
  } else if (xml.nodeType === 3) {
    // text
    obj = xml.nodeValue
  }

  // do children
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i)
      const { nodeName } = item
      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xmlToJson(item)
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          const old = obj[nodeName]
          obj[nodeName] = []
          obj[nodeName].push(old)
        }
        obj[nodeName].push(xmlToJson(item))
      }
    }
  }
  return obj
}

export function translateBook(bookCode, books, defaultName) {
  if (!books) return defaultName

  const book = books.find((b) => b.usfm === bookCode)
  return book?.human ?? defaultName
}

export function translateReference(reference, books, defaultName) {
  const origSplit = reference.split('+')
  const ref1 = origSplit[0]

  const split = ref1.split('.')
  const book = translateBook(split[0], books, defaultName)
  const chapter = split[1]
  const verse = split[2]
  let humanReadable = `${book} ${chapter}`

  if (verse) {
    humanReadable = `${humanReadable}:${verse}`
  }

  if (origSplit.length > 1) {
    const lastVerse = origSplit[origSplit.length - 1].split('.')[2]
    humanReadable = `${humanReadable} - ${chapter}:${lastVerse}`
  }

  return humanReadable
}

export function getPaginationInfo(meta) {
  return {
    currentPage: meta.current_page,
    nextPage: meta.next_page,
    prevPage: meta.prev_page,
    totalPages: meta.total_pages,
    totalCount: meta.total_count,
  }
}

export function sorterFor(key, sortData) {
  if (key !== sortData.sort) {
    return null
  }

  return sortData.order === 'desc' ? (
    <i className="fa fa-caret-down" />
  ) : (
    <i className="fa fa-caret-up" />
  )
}

export const urlForImageAsset = (image) => {
  if (!image) {
    return null
  }

  if (image.asset?.original_url) {
    return image.asset.original_url
  }

  if (image.asset?.thumb_url) {
    return image.asset.thumb_url
  }

  if (
    image.source_url &&
    !image.source_url.startsWith('https://cassi.thewardro.be/')
  ) {
    return image.source_url
  }

  return null
}

export { hashString } from './hash-string'
