/**
 * @description - Contains type definitions for the plan module.
 *
 * TODO: Types not 100% defined, will keep updating them!
 */

import { Organization } from 'components/Organizations/types'
import {
  Empty,
  NullableDate,
  NullableNumber,
  NullableString,
  NullableStringList,
  NumberLike,
  NumberList,
} from 'types/misc'
import { submissionStatuses } from 'helpers/constants'

export namespace Plan {
  export enum DayStatus {
    DRAFT = 'draft',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    SUBMITTED = 'submitted',
    PUBLISHED = 'published',
    MISSING_NARRATED_AUDIO_STATUS = 'missing-narrated-audio',
  }

  export interface NarratedAudioAttachment {
    id: string
    created_at: Date
    updated_at: Date
    file_url: string
    service_status: string
  }

  export interface DevotionalContentBlocksContentError {
    /** Disable retrying of an upload. */
    disableRetry: boolean
    /** The error heading. */
    heading: string
    /** The error message. */
    message: string
  }

  export interface DevotionalContentBlocksContent {
    /** Some html. */
    html?: string
    /** Some alt text. */
    alt?: string
    /** A file attachment id. */
    file_id?: string
    /**  A file's source url. */
    source_url?: string
    /** A url to display, typically for the image block. */
    display_url?: string
    type?: string
    src?: string
    status?: string
    /**  A file's postprocessing url, typically for the audio block. */
    postprocessing_url?: string
    /** A SoundCloud embed url. */
    soundcloud_embed_url?: string
    /** A YouTube video id. */
    youtube_video_id?: string
    /** The Poster video url. */
    poster_url?: string
    /** The video sources. */
    sources?: Array<{
      url: string
      html_mime_type: string
      video_codec: string | null
      audio_codec: string | null
    }>
    /** An error object. */
    error?: DevotionalContentBlocksContentError
  }
  export interface DevotionalContentBlocks {
    /** The block type. */
    type: string
    /** The uuid v4 block id. */
    block_id: string
    /** The block content object. */
    content: Partial<DevotionalContentBlocksContent>
  }

  export interface Day {
    id: NumberLike
    /** The overall workflow status of the day. Example: "published". */
    status: DayStatus
    narrated_audio_attachment?: NarratedAudioAttachment | null
    /** The day's draft sort order position (in ascending order). */
    position: number
    /** Draft scripture references. Example: ["eph2.8-9", "jhn3.16","1n5.13"]. */
    references?: NullableStringList
    /** The draft devotional content blocks. */
    devotional_content_blocks?: Array<DevotionalContentBlocks> | null
    /** The day's published sort order position (in ascending order). */
  }

  export enum Status {
    DRAFT = 'draft',
    APPROVED = 'approved',
    PUBLISHED = 'published',
    REJECTED = 'rejected',
    SUBMITTED = 'submitted',
  }

  export enum ImageStatus {
    PROCESSED = 'processed',
  }

  export interface ImageAsset {
    thumb_url?: string
    original_url?: string
    source_url?: string
  }

  export interface Image {
    id: number
    created_at: Date
    updated_at: Date
    organization_id: NullableNumber
    status: ImageStatus
    youtube_video_id: number
    youtube_status_details: NullableString
    source_url: NullableString
    asset: ImageAsset
  }

  export interface NarratedAudioFeature {
    id: number
    created_at: Date
    updated_at: Date
    metadata: string
    submission_status: typeof submissionStatuses[keyof typeof submissionStatuses]
  }

  export interface PollyVoice {
    audio_preview_url: string
    created_at: Date
    id: number
    language_id: number
    object: string
    title: string
    updated_at: Date
    yv_api_id: string
  }

  export interface Plan {
    alternate_organizations: Array<Organization.SimpleOrganization>
    attribution_text: NullableString
    categories: Array<string>
    created_at: Date
    creator: User
    description: NullableString
    external_plan_id: NullableNumber
    external_plan_url: NullableString
    id: number
    keywords: Array<string>
    language_id: number
    large_image: Attachment | null
    last_edited_by: User
    last_edited_by_org_user: User | null
    launches_at: NullableDate
    name: string
    narrated_audio_feature: NarratedAudioFeature | null
    overall_status: Status
    partner_url: NullableString
    polly_voice_id: null | number
    primary_organization_id: number
    primary_organization_name: NullableString
    publishable: boolean
    publishable_preflight_errors: Array<string>
    small_image: Attachment | null
    source_id: null | number
    status: Status
    submitted_at: NullableDate
    system_status: boolean
    title_slug: string
    translations: null | NumberList
    updated_at: Date
  }

  export interface PlanMeta {
    id: number | string
    isPublishable: boolean
    name: string
    primaryOrganizationId: number
    status: Status
  }

  export interface Comment {
    id: number
    created_at: Date
    updated_at: Date
    body_html: string
    body_json: Empty
    author: User
    action?: string
  }

  export interface ApiCategory {
    id: number
    created_at: Date
    updated_at: Date
    order: number
    data: Array<Record<string, Array<string>>>
  }

  export interface Category {
    id: NumberLike
    name: string
    translatedName?: string
    sections: Array<string>
  }

  export interface ExportPlan {
    id: number
    plan_id: number
    created_at: Date
    updated_at: Date
  }
}
