/* eslint-disable jsdoc/valid-types */
/* eslint-disable jsdoc/check-param-names */
/* eslint-disable react/no-multi-comp */
/**
 * @module PlanRoute
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import React, { useMemo } from 'react'
import {
  Route,
  Link as RouterLink,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Typography,
  capitalize,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ErrorIcon from '@mui/icons-material/Error'
import VolumeUp from '@mui/icons-material/VolumeUp'
import { EmptyState, LazyImage } from '@youversion/react'
import { border } from '@youversion/react/styles/colors-v3'
import { statusTypes } from '@youversion/utils'
import classnames from 'classnames'
import WarningAccordion from 'components/Accordions/WarningAccordion'
import DayScroller from 'components/Plans/DayScroller'
import StatusBadge from 'components/Plans/StatusBadge'
import {
  API_STATUS,
  IMAGEPROXY_URL,
  submissionStatuses,
} from 'helpers/constants'
import { parseDayNumber, urlForImageAsset } from 'helpers'
import { ownAvatarUrlSelector } from 'state/selectors'
import { useGetPlans, useUpdatePlanDay } from 'api/plans'
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio'
import { PlanProvider, usePlan } from 'context'
import PlanBox from 'components/Plans/PlanBox'
import DayBox from 'components/Plans/DayBox'
import { NewDay } from 'components/Plans/NewDay/index'
import LoaderOverlay from 'components/LoaderOverlay'
import PlanSubmitter from 'components/Plans/PlanSubmitter'
import { Breadcrumbs } from 'components/Navigation/breadcrumbs'
import { useAuth } from 'auth'
import { TranslationPicker } from 'components/TranslationPicker'
import { useGetLanguages } from 'api/languages'
import { Page404 } from 'components/404Page'
import { State } from 'state/reducers'
import moment from 'moment'
import { Plan } from 'components/Plans/types'
import { TranslationPickerProps } from 'components/TranslationPicker/types'
import { useTranslation } from 'react-i18next'
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage'
import PlanEdit from './edit'
import PlanDayEdit from './day/edit'

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(4),
    marginBlockEnd: theme.spacing(2),
    padding: theme.spacing(2),
  },
  smallPlanImage: {
    borderColor: border[theme.palette.mode],
    borderRadius: theme.spacing(2),
    flex: 1,
    verticalAlign: 'middle',
  },
  statusBadge: {
    fontWeight: 'normal',
    marginInlineStart: theme.spacing(2),
    verticalAlign: 'center',
  },
  largePlanImage: {
    borderColor: border[theme.palette.mode],
    borderRadius: theme.spacing(2),
    flex: 1440 / 810,
    verticalAlign: 'middle',
  },
  smallImageContainer: {
    aspectRatio: '1 / 1',
    marginInlineStart: theme.spacing(1),
  },
  largeImageContainer: {
    aspectRatio: '1440 / 810',
  },
}))

const stateToProps = (state: State) => ownAvatarUrlSelector(state)

/**
 * The Plan component.
 *
 * @returns {React.ReactElement} - The Plan component.
 */
// eslint-disable-next-line react/no-multi-comp
// TODO: Move PlanComponent to src/containers.
export function PlanComponent() {
  const navigate = useNavigate()
  const { id: idFromParam, dayId: dayIdFromParam } = useParams()
  const { user } = useAuth()
  const classes = useStyles()
  const ownAvatarUrl = useSelector(stateToProps)
  const { t, i18n } = useTranslation(['plans', 'common'])

  const id = useMemo(() => {
    if (idFromParam) return idFromParam
    return ''
  }, [idFromParam])

  const dayId = useMemo(() => {
    if (dayIdFromParam) return dayIdFromParam
    return ''
  }, [dayIdFromParam])

  const { data: languages, status: loadingLanguagesStatus } = useGetLanguages()
  const { mutateAsync: updatePlanDay } = useUpdatePlanDay()
  const {
    dayNumber: dayNumberContext,
    handleApprovePlanInfo,
    loadingStatus,
    plan,
    planDays,
    getMissingNarratedAudioDays,
    planError,
    refreshPlanData,
    primaryOrganization,
    planLanguage,
  } = usePlan()

  const { data: planInfo, status: statusForLinkedPlans } = useGetPlans({
    filters: {
      source_id: 'id' in plan ? plan.source_id ?? plan.id : id,
    },
  })

  const [dayNumber, setDayNumber] = React.useState<number>()

  const hasNarratedAudio = useMemo(() => {
    if ('id' in plan) {
      return planHasNarratedAudio(plan)
    }

    return false
  }, [plan])

  const lastUpdatedDate = useMemo(() => {
    if ('id' in plan) {
      return plan?.updated_at
        ? moment(plan.updated_at).locale(i18n.language).format('ll')
        : ''
    }
    return ''
  }, [plan, i18n.language])

  const smallImgUrl = useMemo(() => {
    if ('id' in plan) {
      return `${IMAGEPROXY_URL}/${urlForImageAsset(plan?.small_image)}`
    }

    return ''
  }, [plan])

  const largeImgUrl = useMemo(() => {
    if ('id' in plan) {
      return `${IMAGEPROXY_URL}/${urlForImageAsset(plan?.large_image)}`
    }

    return ''
  }, [plan])

  React.useEffect(() => {
    if (Array.isArray(planDays)) {
      setDayNumber(() => parseDayNumber(planDays, parseInt(dayId, 10)))
    }
  }, [dayId, planDays])

  const displayExportError = useMemo(() => {
    return (
      user &&
      user.role === 'admin' &&
      'id' in plan &&
      ((plan?.status === submissionStatuses.APPROVED && !plan?.publishable) ||
        plan?.publishable_preflight_errors?.length)
    )
  }, [plan, user])

  const plansCurrentTranslationsList =
    useMemo((): Array<TranslationPickerProps> => {
      if (planInfo?.data && languages) {
        return planInfo?.data.map(
          // eslint-disable-next-line camelcase
          ({ id: planId, status, language_id, source_id, translations }) => {
            let languageName = languages?.[language_id].name ?? ''
            const languageTag = languages?.[language_id].code ?? ''
            // We only want (SOURCE) text to be visible if a plan has at least 1 translation.
            // eslint-disable-next-line camelcase
            if (
              source_id === null &&
              translations &&
              translations.length >= 1
            ) {
              languageName = t('plans:language_source', {
                lang: getLocalizedLanguage(languageTag, i18n.language),
              })
            }

            return {
              id: planId,
              languageName,
              language_id,
              status: t(`plans:status.${status}`),
              languageTag,
            }
          },
        )
      }
      return []
    }, [languages, planInfo?.data, t, i18n.language])

  if (loadingStatus === statusTypes.REJECTED) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100vh"
        justifyContent="center"
      >
        <EmptyState
          actionButton={
            <Button
              color="primary"
              component={RouterLink}
              to="/plans"
              variant="contained"
            >
              {t('plans:view_all_plans')}
            </Button>
          }
          headerText={t('common:not_found')}
          muiHeaderIcon={<ErrorIcon />}
          subtitleText={t('plans:not_found_subtext')}
        />
      </Box>
    )
  }

  if (planError?.message === 'PlanNotFound') {
    return <Page404 />
  }

  if (
    !plan ||
    !('name' in plan) ||
    !planLanguage ||
    !('id' in planLanguage) ||
    !primaryOrganization ||
    !('id' in primaryOrganization) ||
    loadingStatus !== API_STATUS.SUCCESS ||
    loadingLanguagesStatus !== API_STATUS.SUCCESS ||
    statusForLinkedPlans !== API_STATUS.SUCCESS
  ) {
    return <LoaderOverlay />
  }

  async function handleApproveDayInfo(callback: (res: Plan.Day) => void) {
    try {
      const response = await updatePlanDay({
        data: {
          status: submissionStatuses.APPROVED,
        },
        dayId,
        planId: id,
      })
      if (response) {
        refreshPlanData()
        if (callback) {
          callback(response)
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message)
      }
    }
  }

  const planImages = (
    <Box display="flex" gap={1} width="100%">
      <Box flex={1} className={classes.smallImageContainer}>
        <LazyImage
          className={classes.smallPlanImage}
          height="100%"
          src={smallImgUrl}
          width="100%"
        />
      </Box>
      <Box flex={1440 / 810} className={classes.largeImageContainer}>
        <LazyImage
          className={classes.largePlanImage}
          height="100%"
          src={largeImgUrl}
          width="100%"
        />
      </Box>
    </Box>
  )

  let displayComponent

  switch (dayId) {
    // Non-existent dayId means we're at the plan info route.
    case '':
      displayComponent = (
        <PlanBox
          onApprovePlanInfo={handleApprovePlanInfo}
          ownAvatarUrl={ownAvatarUrl}
          plan={plan}
          planDays={planDays}
          primaryOrganization={primaryOrganization}
        />
      )
      break
    case 'new':
      displayComponent = (
        <NewDay isDayInBetween={false} key="newDayEditor" plan={plan} />
      )
      break
    case 'newDay':
      if (typeof dayNumberContext !== 'number') {
        navigate(`/plans/${id}`)
      }
      displayComponent = (
        <NewDay isDayInBetween={true} key="tempNewDayEditor" plan={plan} />
      )
      break
    default:
      displayComponent = (
        <DayBox
          dayNumber={dayNumber ?? 0}
          hasNarratedAudio={hasNarratedAudio}
          key={dayId}
          onApproveDayInfo={handleApproveDayInfo}
          planStatus={plan?.overall_status}
        />
      )
      break
  }

  return (
    <>
      {/* TODO: Uncomment when MLP API is ready */}
      {/* {user.can('administrate:all_permissions') ? (
        <AdminBar
          overallStatus={plan.overall_status}
          isPublishable={plan.publishable}
          planId={plan.id}
        />
      ) : null} */}
      <Box mt={3}>
        <Breadcrumbs
          pathProps={{
            [dayId]: {
              title: dayId === 'new' ? t('plans:new_day') : t('plans:day_info'),
            },
            // Disabling this path because it's only there to inform versus be a route for people
            // to actually land on. For example, a user should not be able to visit `/plans/:planId/days`,
            // as days is only in the URL to help describe the day being viewed.
            days: { disabled: true },
            [id]: { title: t('common:edit') },
            plans: { title: t('plans:plans') },
          }}
        />

        <Box mt={1} mb={1}>
          <TranslationPicker
            plan={plan}
            planId={plan.id}
            plansCurrentTranslations={plansCurrentTranslationsList}
          />
        </Box>

        {/* This is the container for title, button, and images. */}
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={6} md={6} xs={12}>
            <Typography variant="h1">
              {plan?.name}
              {plan?.overall_status ? (
                <StatusBadge
                  className={classes.statusBadge}
                  status={capitalize(
                    String(
                      plan?.system_status === false
                        ? submissionStatuses.ARCHIVED
                        : plan.overall_status,
                    ),
                  )}
                />
              ) : null}
            </Typography>
            <Box>
              {lastUpdatedDate.length ? (
                <Typography variant="caption">
                  {t('plans:last_updated_on', { date: lastUpdatedDate })}
                </Typography>
              ) : null}
            </Box>
            <Box mt={2}>
              <PlanSubmitter
                plan={plan}
                planDays={planDays}
                planLanguage={planLanguage}
                getMissingNarratedAudioDays={getMissingNarratedAudioDays}
              />
            </Box>
          </Grid>
          <Grid item={true} lg={6} md={6} xs={12}>
            {planImages}
          </Grid>
        </Grid>

        {displayExportError ? (
          <WarningAccordion
            title={
              plan?.publishable_preflight_errors?.length
                ? t(
                    'plans:export_plan_error_messages.number_of_reasons.count',
                    {
                      count: plan?.publishable_preflight_errors?.length,
                    },
                  )
                : t('plans:export_plan_error_messages.not_publishable_yet')
            }
            warnings={plan?.publishable_preflight_errors}
          />
        ) : null}

        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={2}
          mt={2}
          width="100%"
        >
          <Typography color="textSecondary" variant="body1">
            {t('plans:number_of_days.count', { count: planDays.length })}
          </Typography>

          {hasNarratedAudio ? (
            <Chip
              icon={<VolumeUp fontSize="inherit" />}
              label={t('plans:narrated_audio_is_enabled')}
              size="small"
              variant="outlined"
            />
          ) : null}
        </Box>

        <Paper className={classnames(classes.paper, 'surface-secondary')}>
          <Box mb={2} width="100%">
            <DayScroller />
          </Box>
          <div style={{ display: 'flex', flex: 1, width: '100%' }}>
            {displayComponent}
          </div>
        </Paper>
      </Box>
    </>
  )
}

/**
 * The PlanRoute component.
 *
 * @alias module:PlanRoute
 *
 * @param {object} props - The component props object.
 * @param {Function} props.dispatch - Redux dispatch.
 *
 * @returns {React.ReactElement} - The PlanRoute component.
 */
function PlanRoute() {
  const { id: idFromParam } = useParams()

  const id = useMemo(() => {
    if (idFromParam) return idFromParam
    return ''
  }, [idFromParam])

  return (
    <PlanProvider id={id}>
      <Routes>
        <Route index={true} element={<PlanComponent />} />

        {/* 🐣 Nested routes only below this line. */}
        <Route path="edit" element={<PlanEdit />} />
        {/* There is no `/days` component, so days nesting goes here. */}
        <Route path="days/:dayId" element={<PlanComponent />} />
        <Route path="days/:dayId/edit" element={<PlanDayEdit />} />
      </Routes>
    </PlanProvider>
  )
}

export default PlanRoute
