import { Dispatch, SetStateAction, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Save as SaveIcon } from '@mui/icons-material'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useInviteUserToOrganization } from 'api/organization_memberships'
import { NumberLike } from 'types/misc'
import { AsyncButton, useAlert } from '@youversion/react'
import { QUERY_KEYS } from 'helpers'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

const AddMemberSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email.')
    .required("Please enter user's email."),
  firstName: Yup.string().required("Please enter member's first name."),
  lastName: Yup.string().required("Please enter member's last name."),
})

interface AddMemberModalProps {
  orgId: NumberLike
  isAddingUser: boolean
  setIsAddingUser: Dispatch<SetStateAction<boolean>>
}

interface AddMemberFormProps {
  firstName: string
  lastName: string
  email: string
}

export function AddMemberModal({
  orgId,
  isAddingUser,
  setIsAddingUser,
}: AddMemberModalProps) {
  const { mutateAsync } = useInviteUserToOrganization()
  const [isDoneAdding, setIsDoneAdding] = useState(false)
  const { t } = useTranslation(['organizations', 'common'])
  const queryClient = useQueryClient()

  const { throwAlert } = useAlert()

  const title = isDoneAdding
    ? t('organizations:single_org.add_member.invitation_sent')
    : t('organizations:single_org.add_member.title')

  async function handleSubmit(values: AddMemberFormProps) {
    try {
      await mutateAsync({
        ...values,
        orgId,
      })
      queryClient.invalidateQueries([QUERY_KEYS.ORGANIZATION_MEMBERSHIPS])
      setIsDoneAdding(true)
    } catch (e) {
      if (e instanceof Error) {
        throwAlert({
          id: 'add_member_failed',
          key: 'add_member_failed',
          message: t('organizations:single_org.add_member.add_member_failed', {
            message: e.message,
          }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  const closeModal = () => {
    setIsAddingUser(false)
    setIsDoneAdding(false)
  }

  return (
    <Dialog
      maxWidth="xs"
      onClose={() => setIsAddingUser(false)}
      open={isAddingUser}
    >
      <DialogTitle>{title}</DialogTitle>
      {isDoneAdding ? (
        <>
          <DialogContent>
            <DialogContentText>
              {t(
                'organizations:single_org.add_member.once_the_user_accepts_they_will_be_ready_to_go',
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus={true}
              onClick={closeModal}
              type="button"
              variant="outlined"
            >
              {t('common:close')}
            </Button>
          </DialogActions>
        </>
      ) : (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={AddMemberSchema}
        >
          {({
            handleChange,
            values,
            errors,
            isSubmitting,
            touched,
            submitForm,
          }) => (
            <Form>
              <DialogContent>
                <DialogContentText>
                  {t('organizations:single_org.add_member.description')}
                </DialogContentText>
                <Box marginY={3}>
                  <TextField
                    error={touched.firstName && Boolean(errors.firstName)}
                    fullWidth={true}
                    helperText={touched.firstName && errors.firstName}
                    label={t(
                      'organizations:single_org.add_member.form.first_name',
                    )}
                    name="firstName"
                    onChange={handleChange}
                    placeholder={t(
                      'organizations:single_org.add_member.form.first_name',
                    )}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    error={touched.lastName && Boolean(errors.lastName)}
                    fullWidth={true}
                    helperText={touched.lastName && errors.lastName}
                    label={t(
                      'organizations:single_org.add_member.form.last_name',
                    )}
                    name="lastName"
                    onChange={handleChange}
                    placeholder={t(
                      'organizations:single_org.add_member.form.last_name',
                    )}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Box>

                <Box mb={3}>
                  <TextField
                    error={touched.email && Boolean(errors.email)}
                    fullWidth={true}
                    helperText={touched.firstName && errors.email}
                    label={t(
                      'organizations:single_org.add_member.form.email_address',
                    )}
                    name="email"
                    onChange={handleChange}
                    placeholder={t(
                      'organizations:single_org.add_member.form.email_address',
                    )}
                    value={values.email}
                    variant="outlined"
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <AsyncButton
                  color="primary"
                  disabled={
                    isSubmitting ||
                    !values.firstName ||
                    !values.lastName ||
                    !values.email
                  }
                  idle={t(
                    'organizations:single_org.add_member.form.button.idle',
                  )}
                  onClick={submitForm}
                  options={{
                    disableUpdateOnSuccess: true,
                  }}
                  pending={t(
                    'organizations:single_org.add_member.form.button.loading',
                  )}
                  rejected={t(
                    'organizations:single_org.add_member.form.button.failed',
                  )}
                  resolved={t(
                    'organizations:single_org.add_member.form.button.success',
                  )}
                  startIcon={<SaveIcon />}
                  variant="contained"
                />
                <Button autoFocus={true} onClick={closeModal} type="button">
                  {t('common:cancel')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  )
}
