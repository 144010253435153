/**
 * @module UnauthLayout
 */
import { FC, PropsWithChildren } from 'react'
import Helmet from 'react-helmet'
import { Box, Typography, useMediaQuery } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@mui/material/styles'
import { AlertProvider, Alerts } from '@youversion/react'
import { orange } from '@youversion/react/styles/colors-v3'
import { BASE_TITLE } from 'helpers/constants'
import partnerPortalLogo from 'assets/yv-pp-logo.svg'
import LoginSideArt from 'assets/yv-pp-side-art.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    sideArt: {
      [theme.breakpoints.up('md')]: {
        aspectRatio: '0.58',
        backgroundImage: `url(${LoginSideArt})`,
        backgroundSize: 'cover',
        maxWidth: '30%',
      },
    },
    sideArtText: {
      maxWidth: '94%',
      fontSize: '2rem',
      color: orange[50],
      lineHeight: '2rem',
      marginBlockEnd: theme.spacing(2),
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.5rem',
        lineHeight: '2.5rem',
      },
    },
  }),
)

const UnauthLayout: FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const desktopScreenSize = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('login')

  return (
    <AlertProvider>
      <Alerts
        snackbar={true}
        snackbarProps={{
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        }}
      />
      <Helmet title={`${BASE_TITLE} | ${t('login')}`} />

      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        height="100vh"
      >
        <Box className={classes.sideArt} data-testid="sideart-background">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
            maxWidth="388px"
            p={{
              xs: 2,
              sm: 6,
              lg: 8,
            }}
          >
            <Box maxWidth="100%" width="260px">
              <img
                alt={t('welcome_img_alt')}
                height="100%"
                src={partnerPortalLogo}
                width="100%"
              />
            </Box>
            {desktopScreenSize ? (
              <Typography className={classes.sideArtText}>
                {t('welcome_msg')}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          overflow="scroll"
          p={{ xs: 2, sm: 8 }}
          width="100%"
        >
          {children}
        </Box>
      </Box>
    </AlertProvider>
  )
}

export default UnauthLayout
