/**
 * @module organizationsActionsConstants
 */
export const SET_ORGS_FILTERS = 'orgs/SET_ORGS_FILTERS'
export const SET_ORGS_SORT = 'orgs/SET_ORGS_SORT'
export const LOAD_ORGS_ROUTE = 'orgs/LOAD_ORGS_ROUTE'
export const LOAD_ACTIVE_ORG = 'orgs/api/LOAD_ACTIVE_ORG'
export const LOAD_INITIAL_ORG = 'orgs/api/LOAD_INITIAL_ORG'
export const LIST_ORGANIZATIONS = 'orgs/api/LIST_ORGANIZATIONS'
export const SHOW_ORG = 'orgs/api/SHOW_ORG'
export const EDIT_ORG = 'orgs/EDIT_ORG'
export const CANCEL_EDIT_ORG = 'orgs/CANCEL_EDIT_ORG'
export const UPDATE_ORG = 'orgs/api/UPDATE_ORG'
export const SUBMIT_FORM = 'orgs/SUBMIT_FORM'
export const SWITCH_ACTIVE_ORG = 'orgs/SWITCH_ACTIVE_ORG'
export const SWITCH_VIEW_ALL_ORGS = 'orgs/VIEW_ALL_ORGS'
