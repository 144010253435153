/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable jsdoc/require-param */
/**
 * @module Comment
 */
import { useState } from 'react'
import { Box, Card, Chip, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { red } from '@mui/material/colors'
import { Create as CreateIcon } from '@mui/icons-material'
import { LazyImage } from '@youversion/react'
import classNames from 'classnames'
import { Plan } from 'components/Plans/types'
import { NumberLike } from 'types/misc'
import { useTranslation } from 'react-i18next'
import { CommentPrimaryActions } from '../CommentPrimaryActions'
import { CommentCloseAction } from '../CommentCloseAction'
import { EditComment } from '../EditComment'
import { DeleteComment } from '../DeleteComment'

import styles from '../../Card.module.scss'

const useStyles = makeStyles((theme) => ({
  commentCardActionsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBlockStart: theme.spacing(1),
  },
  commentCardContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  commentCardRoot: {
    border: 'none',
    borderRadius: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  deleteCommentText: {
    fontStyle: 'italic',
  },
  pencilOnAvatarContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    fontSize: '1.2rem',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  primaryActionsContainer: {
    height: 27,
  },
  youversionChip: {
    backgroundColor: red[50],
    borderColor: red[50],
    color: red[900],
    fontSize: '0.7rem',
    fontWeight: 'bold',
    height: '20px',
  },
  youversionChipContainer: {
    insetBlockEnd: -10,
    insetInlineStart: 15,
    position: 'absolute',
    zIndex: 1,
  },
}))

const COMMENT_CARD_STATUS = {
  DEFAULT: 'default',
  DELETE: 'delete',
  EDIT: 'edit',
}

interface CommentProps {
  /** The comment object. */
  comment: Plan.Comment
  /** The day id. */
  dayId?: NumberLike
  /** Boolean specifying whether or note the current user created the specified comment. */
  didCurrentUserCreateThis: boolean
  /** The plan id. */
  planId: NumberLike
  /** Refetch comments. */
  refetch: VoidFunction
}

/**
 * Represents a Comment card containing the specified comment.
 *
 * @returns {React.ReactElement} A Comment card component.
 */
export function Comment({
  comment,
  dayId,
  didCurrentUserCreateThis,
  planId,
  refetch,
}: CommentProps) {
  const { t, i18n } = useTranslation(['plans'])
  const classes = useStyles()
  const [commentCardStatus, setCommentCardStatus] = useState(
    COMMENT_CARD_STATUS.DEFAULT,
  )
  const {
    first_name: firstName,
    last_name: lastName,
    profile_photo: profilePhoto,
    role,
  } = comment.author

  const avatarUrl = profilePhoto?.asset && profilePhoto?.asset.thumb_url

  let rejectedText
  if (comment.action === 'plan_rejected') {
    rejectedText = <span>&nbsp;{t('plans:comment.rejected_this_info')}</span>
  }

  const commentEdited = Boolean(comment.created_at !== comment.updated_at)
  const DEFAULT_MODE = Boolean(
    commentCardStatus === COMMENT_CARD_STATUS.DEFAULT,
  )
  const DELETE_MODE = Boolean(commentCardStatus === COMMENT_CARD_STATUS.DELETE)
  const EDIT_MODE = Boolean(commentCardStatus === COMMENT_CARD_STATUS.EDIT)

  // The comment user's role is necessary to check if the user is in the admin group.
  const isAdmin = Boolean(role === 'admin')

  return (
    <Card
      aria-label={comment.body_html}
      className={classNames(
        classes.commentCardRoot,
        styles.commentCardContainer,
      )}
      variant="outlined"
    >
      <Box className={classes.commentCardContentContainer}>
        <Box className={styles.avatarWrap} mr={2}>
          <Box className={styles.avatar}>
            {avatarUrl ? (
              <LazyImage height="100%" src={avatarUrl} width="100%" />
            ) : null}
            {EDIT_MODE ? (
              <Box className={classes.pencilOnAvatarContainer}>
                <CreateIcon color="inherit" fontSize="inherit" />
              </Box>
            ) : null}
            {isAdmin ? (
              <Box className={classes.youversionChipContainer}>
                <Chip
                  className={classes.youversionChip}
                  color="secondary"
                  data-testid="yv-admin-badge"
                  label="YV"
                  size="small"
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box className={styles.commentData}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <strong>
                {firstName} {lastName}
              </strong>
              <Typography color="textSecondary" variant="caption">
                {rejectedText}&nbsp;&bull;&nbsp;
                {moment(comment.created_at).locale(i18n.language).fromNow()}
              </Typography>
            </Box>
            {DEFAULT_MODE ? (
              <Box className={classes.primaryActionsContainer}>
                {didCurrentUserCreateThis ? (
                  <CommentPrimaryActions
                    triggerDeleteState={() =>
                      setCommentCardStatus(COMMENT_CARD_STATUS.DELETE)
                    }
                    triggerEditState={() =>
                      setCommentCardStatus(COMMENT_CARD_STATUS.EDIT)
                    }
                  />
                ) : null}
              </Box>
            ) : (
              <CommentCloseAction
                onClose={() =>
                  setCommentCardStatus(COMMENT_CARD_STATUS.DEFAULT)
                }
              />
            )}
          </Box>
          {DEFAULT_MODE ? (
            <>
              {commentEdited ? (
                <Box mt={-0.7}>
                  <Typography color={'textSecondary'} variant={'caption'}>
                    {t('plans:comment.edited')}
                  </Typography>
                </Box>
              ) : null}
            </>
          ) : null}

          {DEFAULT_MODE ? (
            <Box className={styles.commentText}>{comment.body_html}</Box>
          ) : null}
          {DELETE_MODE ? (
            <Box className={styles.commentText}>
              <Typography className={classes.deleteCommentText}>
                {t('plans:comment.are_you_sure_you_want_to_delete')}
              </Typography>
            </Box>
          ) : null}

          {EDIT_MODE ? (
            <Box>
              <EditComment
                comment={comment}
                dayId={dayId}
                planId={planId}
                updateLocalComments={async () => {
                  await refetch()
                  setCommentCardStatus(COMMENT_CARD_STATUS.DEFAULT)
                }}
              />
            </Box>
          ) : null}
          {DELETE_MODE ? (
            <Box className={classes.commentCardActionsContainer}>
              <DeleteComment
                comment={comment}
                dayId={dayId}
                planId={planId}
                updateLocalComments={() => refetch()}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Card>
  )
}

Comment.defaultProps = {
  dayId: null,
}
