/* eslint-disable jsdoc/require-param */
/**
 * @module CompletionChecklist
 */
import * as React from 'react'
import {
  Box,
  Card,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ChevronRight as ChevronRightIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material'
import { blue, gray, green } from '@youversion/react/styles/colors-v3'
import {
  calculatePercentage,
  checkField,
  checkIfFieldIsActive,
  checklistFields,
  fieldsArray,
  getKebabCase,
} from 'helpers/completion-checklist'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Plan } from '../types'

const useStyles = makeStyles((theme) => ({
  checkCircleIcon: {
    color: green[30],
  },
  chevronRightIcon: {
    color: gray[20],
  },
  linearProgress: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: blue[40],
    },
    backgroundColor: blue[20],
    marginInlineStart: theme.spacing(1),
    width: '100%',
  },
  listItem: {
    padding: '8px 12px',
  },
  unCheckedCircleIcon: {
    color: gray[20],
  },
  fieldNameContainer: {
    flex: 1,
  },
  listItemContainer: {
    paddingInlineEnd: 10,
  },
}))

interface RedirectTFormFieldsProps {
  field: string
  value: string
}

/**
 * Complete Checklist shows stats of which fields have been filled yet!
 *
 * @returns {React.ReactElement} - Component to display progress of plan.
 */
export default function CompletionChecklist({
  plan,
  planDays,
}: {
  plan: Plan.Plan
  planDays: Plan.Day[]
}) {
  const { t } = useTranslation(['plans'])
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()

  const percentage = React.useMemo(
    () => calculatePercentage(plan, planDays),
    [plan, planDays],
  )

  const isEditing = pathname.includes('edit')
  const allFieldsCompleted = percentage === 100

  function redirectToFormField({ field, value }: RedirectTFormFieldsProps) {
    let basePath = `/plans/${plan.id}`
    basePath += field !== 'plan_days' ? '/edit' : ''
    navigate(`${basePath}#${value}`)
  }

  return (
    <Card variant="outlined">
      <Box p="12px">
        <Typography variant={isEditing ? 'h2' : 'h3'}>
          {allFieldsCompleted
            ? t('plans:plan_checklist.checklist_complete')
            : t('plans:plan_checklist.checklist_incomplete')}
        </Typography>
        {isEditing ? (
          <Box>
            <Typography color="textSecondary" variant="body1">
              {t('plans:plan_checklist.your_progress_will_be_shown')}
            </Typography>
          </Box>
        ) : null}
        <Box alignItems="center" display="flex" flexDirection="row" mt={1}>
          <Typography>
            {t('plans:plan_checklist.percentage', { percentage })}
          </Typography>
          <LinearProgress
            className={classes.linearProgress}
            color={'primary'}
            value={percentage}
            variant="determinate"
          />
        </Box>
      </Box>
      {allFieldsCompleted ? (
        <Box m="12px">
          <Typography color="textSecondary" variant="body1">
            {t('plans:plan_checklist.you_can_now_submit_your_plan')}
          </Typography>
        </Box>
      ) : (
        <List data-testid="checklist-wrapper">
          {fieldsArray.map((field) => {
            const redirectLink = getKebabCase(checklistFields[field].value)
            const checkIfCurrentFieldIsActive = checkIfFieldIsActive({
              field: redirectLink,
              hash,
            })

            const onClick = () => {
              if (field === 'plan_days' && planDays && planDays.length < 3) {
                return navigate(`/plans/${plan.id}/days/new`)
              }

              return redirectToFormField({
                field,
                value: redirectLink,
              })
            }

            return (
              <ListItem
                button={true}
                className={classes.listItem}
                dense={true}
                key={field}
                onClick={onClick}
                style={
                  checkIfCurrentFieldIsActive
                    ? { backgroundColor: blue[10] }
                    : undefined
                }
              >
                <Grid
                  className={classes.listItemContainer}
                  container={true}
                  spacing={1}
                >
                  <Grid item={true} lg={2}>
                    {checkField(plan, planDays)(field) ? (
                      <CheckCircleOutlineIcon
                        className={classes.checkCircleIcon}
                        data-testid={`${field}-checked`}
                      />
                    ) : (
                      <RadioButtonUncheckedIcon
                        className={classes.unCheckedCircleIcon}
                        data-testid={`${field}-not-checked`}
                      />
                    )}
                  </Grid>
                  <Grid
                    className={classes.fieldNameContainer}
                    item={true}
                    lg={9}
                  >
                    <Typography variant="body1">
                      {t(checklistFields[field].label)}
                    </Typography>
                  </Grid>
                  <Grid item={true} lg={1}>
                    <ChevronRightIcon className={classes.chevronRightIcon} />
                  </Grid>
                </Grid>
              </ListItem>
            )
          })}
        </List>
      )}
    </Card>
  )
}
