export function isValidUrl(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(str)
}

export function isHttpsPrepended(str: string) {
  return /^https?:\/\//i.test(str)
}

export function prependHttps(str: string) {
  const prefix = 'https://'
  const httpPrefix = 'http://'
  let updatedUrl = str

  if (str.substr(0, httpPrefix.length) === httpPrefix) {
    updatedUrl = prefix + str.slice(httpPrefix.length, str.length)
    return updatedUrl
  }

  if (str.substr(0, prefix.length) !== prefix) {
    updatedUrl = prefix + updatedUrl
  }

  return updatedUrl
}
