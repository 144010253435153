/**
 * @module DevoContentWrapper
 */

import React from 'react'
import { Typography } from '@mui/material'
import DevotionalContentBlocks from 'components/Plans/devotional-content-blocks'
import { useTranslation } from 'react-i18next'
import { Plan } from '../types'

/**
 * @typedef {object} BlockContentError
 * @property {boolean} [disableRetry] - Disable retrying of an upload.
 * @property {string} [heading] - The error heading.
 * @property {string} message - The error message.
 */

/**
 * @typedef {object} BlockContentObject
 * @property {string} [alt] - Some alt text.
 * @property {string} [display_url] - A url to display, typically for the image block.
 * @property {string} [file_id] - A file attachment id.
 * @property {string} [html] - Some html.
 * @property {string} [postprocessing_url] - A file's postprocessing url, typically for the audio block.
 * @property {string} [soundcloud_url] - A SoundCloud embed url.
 * @property {string} [source_url] - A file's source url.
 * @property {string} [youtube_video_id] - A YouTube video id.
 * @property {BlockContentError} [error] - An error object.
 */

/**
 * @typedef {object} ContentBlock
 * @property {string} block_id - The uuid v4 block id.
 * @property {string} type - The block type.
 * @property {BlockContentObject} content - The block content object.
 */

/**
 * The DevoContentWrapper.
 *
 * @param {object} props - The component props object.
 * @param {Array<ContentBlock>} props.blocks - An array of content blocks.
 * @param {Function} props.onChange - The onChange function will run when changes are made to Devotional Content Blocks.
 * @param {object} props.planMeta - An object with meta info about the plan.
 * @returns {React.ReactElement} - The DevoContentWrapper component.
 */

export interface DevotionalContentWrapperType {
  /** An array of devotional content blocks to render. */
  blocks: Array<Plan.DevotionalContentBlocks>
  /** Function to sync block state with its parent component. */
  onChange: (blocks: Array<Plan.DevotionalContentBlocks>) => void
  /** The meta data associated with a plan. */
  planMeta: Plan.PlanMeta
}

const DevoContentWrapper: React.FC<DevotionalContentWrapperType> = ({
  blocks,
  onChange,
  planMeta,
}) => {
  const { t } = useTranslation(['plan_days'])
  return (
    <>
      <Typography variant="h3">
        {t('plan_days:plan_day_edit.devotional_content.title')}
      </Typography>
      <Typography variant="subtitle1">
        {t('plan_days:plan_day_edit.devotional_content.subText')}
      </Typography>
      <DevotionalContentBlocks
        blocks={blocks}
        onChange={onChange}
        planMeta={planMeta}
      />
    </>
  )
}

export default DevoContentWrapper
