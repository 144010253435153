import React, { FC } from 'react'
import { Box, Button, DialogContent, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Lottie from 'lottie-react'
import planSubmissionSuccessLottie from 'assets/lottie/plan-submit-success.json'
import { LazyImage } from '@youversion/react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    button: {
      marginBlock: theme.spacing(1),
      marginInline: theme.spacing(2),
    },
    congrats: {
      marginBlockStart: theme.spacing(1),
    },
    dialog: {
      width: '100%',
    },
    lottie: {
      align: 'center',
      height: '100%',
      position: 'absolute',
      width: '100%',
    },
    smallImage: { borderRadius: theme.spacing(2) },
  }),
)

interface Props {
  handleClose: () => void
  planImage?: string | undefined
}

const PlanSuccessDialog: FC<Props> = ({ handleClose, planImage }) => {
  const classes = useStyles()
  const { t } = useTranslation(['plans', 'common'])

  return (
    <>
      <DialogContent id="dialog-wrapper-content">
        <Box className={classes.box}>
          <Lottie
            animationData={planSubmissionSuccessLottie}
            autoplay={true}
            className={classes.lottie}
            loop={true}
          />
          <LazyImage
            alt="Plan-Image"
            className={classes.smallImage}
            height={'250px'}
            src={planImage || ''}
            width={'50%'}
          />
          <Typography
            align="center"
            className={classes.congrats}
            color="textPrimary"
            variant="h2"
          >
            {t('plans:submit_plan.success_submission.title')}
          </Typography>
          <Typography
            align="center"
            className={classes.congrats}
            color="textSecondary"
            variant="body1"
          >
            {t('plans:submit_plan.success_submission.description')}
          </Typography>
        </Box>
      </DialogContent>
      <Box alignItems="center" display="flex" justifyContent="center">
        <Button
          className={classes.button}
          color="primary"
          onClick={handleClose}
          size="small"
        >
          {t('common:dismiss')}
        </Button>
      </Box>
    </>
  )
}

export default PlanSuccessDialog
