import { FC } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { statusTypes } from '@youversion/utils'
import { useAlert } from '@youversion/react'
import { RefactoredLanguages } from 'api/languages'
import { Plan } from 'components/Plans/types'
import { useLinkPlanLanguage } from 'api/plans'
import { API_STATUS, ERROR_RESPONSES_FOR_MULTI_LANGUAGE_PLANS } from 'helpers'
import { Trans, useTranslation } from 'react-i18next'
import { usePlan } from 'context'
import { Empty } from 'types/misc'
import MuiLoadingButton from 'components/Buttons/mui-loading-button'

interface Props {
  handleBack: () => void
  handleClose: () => void
  selectedPlan: Plan.Plan
  languages: RefactoredLanguages
}

const isJson = (e: Error) => {
  try {
    JSON.parse(e.message)
    return true
  } catch (err) {
    return false
  }
}

const FinalDialog: FC<Props> = ({
  handleBack,
  handleClose,
  languages,
  selectedPlan,
}) => {
  const { throwAlert } = useAlert()
  const { plan: currentPlan } = usePlan()
  const { t } = useTranslation(['plans', 'common'])
  const { mutate, status } = useLinkPlanLanguage()
  const isSubmitting = status === API_STATUS.LOADING

  function handleConnect() {
    if ('id' in currentPlan) {
      let sourcePlanId = currentPlan.id
      let translationPlanId = selectedPlan.id

      // Both plans are source plans
      if (
        currentPlan.translations?.length &&
        selectedPlan.translations?.length
      ) {
        throwAlert({
          id: 'link-translation-language-plan-failed',
          key: 'link-translation-language-plan-failed',
          message: t('plans:link_plan.link_plans_warning'),
          timeout: 5000,
          type: statusTypes.ERROR,
        })
        return
      }

      // Current plan is the source plan.
      if (currentPlan.translations?.length) {
        sourcePlanId = currentPlan.id
        translationPlanId = selectedPlan.id
      }

      // Selected plan is the source plan.
      if (selectedPlan.translations?.length) {
        sourcePlanId = selectedPlan.id
        translationPlanId = currentPlan.id
      }

      // When working with a translation and a standalone plan. We pick the source plan id from the translation plan
      if (currentPlan.source_id) {
        sourcePlanId = currentPlan.source_id
        translationPlanId = selectedPlan.id
      }

      mutate(
        {
          planId: translationPlanId,
          sourceId: sourcePlanId,
        },
        {
          onSuccess: () => {
            throwAlert({
              id: 'link-translation-language-plan-success',
              key: 'link-translation-language-plan-success',
              message: t('plans:link_plan.link_plans_success'),
              timeout: 5000,
              type: statusTypes.SUCCESS,
            })
            handleClose()
            window.location.replace(`/plans/${sourcePlanId}`)
          },
          onError: (e) => {
            if (e instanceof Error) {
              if (isJson(e)) {
                const errors: Array<string> = JSON.parse(e.message)
                errors.forEach((error, ind) => {
                  throwAlert({
                    id: `link-translation-language-plan-error-${ind}`,
                    key: `link-translation-language-plan-error-${ind}`,
                    message: ERROR_RESPONSES_FOR_MULTI_LANGUAGE_PLANS(error),
                    timeout: 5000,
                    type: statusTypes.ERROR,
                  })
                })
              } else {
                throwAlert({
                  id: 'link-translation-language-plan-error',
                  key: 'link-translation-language-plan-error',
                  message: e.message,
                  timeout: 5000,
                  type: statusTypes.ERROR,
                })
              }
            }
          },
        },
      )
    }
  }

  function parsePlanInfo(plan: Plan.Plan | undefined | Empty): string {
    if (plan && 'id' in plan) {
      const planName = plan.name
      const planLanguage = languages[plan.language_id].name
      return `${planName} (${planLanguage})`
    }

    return t('common:loading')
  }

  const selectedPlanInfo = parsePlanInfo(selectedPlan)
  const sourcePlanInfo = parsePlanInfo(currentPlan)

  return (
    <>
      <DialogContent id="dialog-wrapper-content">
        <Typography color="textSecondary" variant="caption">
          {t('common:step_of_total', {
            step: 2,
            total: 2,
          })}
        </Typography>
        <div>
          <Trans
            components={{
              strong: <strong />,
            }}
            i18nKey="plans:transfer_plan.you_are_about_to_connect_the"
            t={t}
            values={{
              sourcePlanInfo,
              selectedPlanInfo,
            }}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          autoFocus={true}
          color="primary"
          disabled={isSubmitting}
          onClick={handleBack}
        >
          {t('common:back')}
        </Button>
        <Box mr={3}>
          <MuiLoadingButton
            color="primary"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleConnect}
            variant="contained"
          >
            {t('plans:col_header.connect')}
          </MuiLoadingButton>
        </Box>
      </DialogActions>
    </>
  )
}

export default FinalDialog
