/* eslint-disable jsdoc/require-param */
/**
 * @module CategoryField
 */
import { useState } from 'react'
import { Box, Chip } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { Add as AddIcon } from '@mui/icons-material'
import { toggleCategoryValue } from 'helpers/category-manager'
import { Plan } from 'components/Plans/types'
import { useTranslation } from 'react-i18next'
import { CategoryFieldAdder } from './CategoryFieldAdder'

const useStyles = makeStyles((theme) =>
  createStyles({
    addEditCategoryButton: {
      fontSize: 12,
      margin: '15px 0',
      padding: '7px 10px',
    },
    categoryLabel: { fontSize: '0.75rem', fontWeight: 'bold' },
    categoryPills: {
      marginInlineEnd: theme.spacing(2),
      marginBlockStart: theme.spacing(1),
    },
  }),
)

interface CategoryFieldProps {
  activeCategories: Array<Plan.Category>
  categories: Array<Plan.Category>
  sections: Array<string>
  setActiveCategories: (categories: Array<Plan.Category>) => void
}

/**
 * Represents a field component to list and manage categories.
 *
 * @returns {React.ReactElement} - The CategoryField Component.
 */
export function CategoryField({
  activeCategories,
  categories,
  sections,
  setActiveCategories,
}: CategoryFieldProps) {
  const classes = useStyles()
  const { t } = useTranslation('plans')

  const [isCategoryAdderOpen, setIsCategoryAdderOpen] = useState(false)

  function removeCategory(singleCategory: Plan.Category) {
    const updatedActiveCategories = toggleCategoryValue(
      activeCategories,
      singleCategory,
    )
    setActiveCategories(updatedActiveCategories)
  }

  return (
    <div>
      <CategoryFieldAdder
        activeCategories={activeCategories}
        categories={categories}
        isOpen={isCategoryAdderOpen}
        onComplete={(newCategories) => {
          setIsCategoryAdderOpen(false)
          if (newCategories) {
            setActiveCategories(newCategories)
          }
        }}
        sections={sections}
      />

      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        mt={1}
      >
        <Box mr={1} mt={1}>
          <Chip
            deleteIcon={<AddIcon />}
            label={t('edit_plan_info.add_category')}
            onDelete={() => setIsCategoryAdderOpen(true)}
            variant="outlined"
          />
        </Box>

        {activeCategories?.map((singleCategory) => (
          <Chip
            className={classes.categoryPills}
            color="secondary"
            data-testid={singleCategory.id}
            key={singleCategory.id}
            label={t(`plans:categories.${singleCategory.name}`)}
            onDelete={() => removeCategory(singleCategory)}
          />
        ))}
      </Box>
    </div>
  )
}
