/* eslint-disable jsdoc/require-param */
import { ChangeEventHandler, useState } from 'react'
import { Box, Grid, MenuItem, TextField } from '@mui/material'
import ProfilePhotoAttachment from 'components/misc/ProfilePhotoAttachment'
import { ImageField } from 'helpers/image-field'
import { FormikErrors, FormikTouched } from 'formik'
import { useCreateAttachment } from 'api/attachments'
import { useUpdateUser } from 'api/users'
import { NumberLike } from 'types/misc'

import { FILE_MAX_BYTE_SIZE } from 'helpers'
import { useTranslation } from 'react-i18next'
import { useGetTranslatableLanguages } from 'api/languages'
import styles from '../../MyAccount.module.scss'

interface EditUserDetailsProps {
  user: LoggedInUser
  values: UpdateUserFormValues
  errors: FormikErrors<UpdateUserFormValues>
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  touched: FormikTouched<UpdateUserFormValues>
  orgId: NumberLike
}

export interface UpdateUserFormValues {
  firstName: string
  lastName: string
  email: string
  language?: string
}

/**
 * Renders edit user form.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function EditUserDetails({
  user,
  values,
  errors,
  handleChange,
  touched,
  orgId,
}: EditUserDetailsProps) {
  const [profilePhoto, setProfilePhotoUrl] = useState(
    () => user.profile_photo?.source_url,
  )
  const { mutateAsync } = useUpdateUser()
  const { mutateAsync: createAttachment } = useCreateAttachment(orgId)
  const { t } = useTranslation(['users', 'common'])
  const {
    data: languages,
    isLoading: isLoadingLanguages,
    isError: isLanguageError,
  } = useGetTranslatableLanguages()

  async function onDropHandler(acc: File[]) {
    const file = acc[0]

    if (!file) {
      throw new Error(t('users:validation.no_file_selected'))
    }

    if (file.size > FILE_MAX_BYTE_SIZE) {
      throw new Error(t('users:validation.file_size_limit', { size: '2MB' }))
    }

    const attachment = await createAttachment(file)
    if (attachment) {
      setProfilePhotoUrl(attachment.asset.original_url ?? '')
      await mutateAsync({
        profile_photo_id: attachment.id,
      })
    }
  }

  let languagesOptions: Array<{ value: string; label: string }> = []

  if (isLoadingLanguages) {
    languagesOptions = [{ value: '', label: t('common:loading') }]
  } else if (isLanguageError) {
    languagesOptions = [
      { value: '', label: t('users:messages.error_fetching_languages') },
    ]
  } else if (languages) {
    languagesOptions = languages.map((language) => ({
      value: language.locale,
      label: language.localName,
    }))
  }

  return (
    <>
      <Box mb={5}>
        <ImageField
          ImageComponent={ProfilePhotoAttachment}
          className={styles.fileUploaderWrapper}
          onFileDrop={onDropHandler}
          url={profilePhoto}
        />
      </Box>
      <Grid container={true} spacing={4}>
        <Grid item={true} md={8} xs={12}>
          <TextField
            error={touched.firstName && Boolean(errors.firstName)}
            fullWidth={true}
            helperText={
              touched.firstName && errors.firstName
                ? t(errors.firstName)
                : undefined
            }
            label={t('users:labels.first_name')}
            name="firstName"
            onChange={handleChange}
            placeholder={t('users:labels.first_name')}
            value={values.firstName}
            variant="outlined"
          />
        </Grid>
        <Grid item={true} md={8} xs={12}>
          <TextField
            error={touched.lastName && Boolean(errors.lastName)}
            fullWidth={true}
            helperText={
              touched.lastName && errors.lastName
                ? t(errors.lastName)
                : undefined
            }
            label={t('users:labels.last_name')}
            name="lastName"
            onChange={handleChange}
            placeholder={t('users:labels.last_name')}
            value={values.lastName}
            variant="outlined"
          />
        </Grid>
        <Grid item={true} xs={8}>
          <TextField
            error={touched.email && Boolean(errors.email)}
            fullWidth={true}
            helperText={
              touched.email && errors.email ? t(errors.email) : undefined
            }
            label={t('users:labels.email_address')}
            name="email"
            onChange={handleChange}
            placeholder={t('users:labels.email_address')}
            value={values.email}
            variant="outlined"
          />
        </Grid>
        <Grid item={true} xs={8}>
          <TextField
            error={touched.language && Boolean(errors.language)}
            fullWidth={true}
            helperText={
              touched.language && errors.language
                ? t(errors.language)
                : t('users:messages.language_helper')
            }
            label={t('users:labels.account_language')}
            name="language"
            onChange={handleChange}
            placeholder={t('users:labels.account_language')}
            select={true}
            type="text"
            value={languages?.length ? values.language : ''}
            variant="outlined"
          >
            {languagesOptions.map((lang) => (
              <MenuItem key={lang.value} value={lang.value}>
                {lang.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  )
}
