/* eslint-disable react/no-multi-comp */
/**
 * @module Organization
 */
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Can, useAuth } from 'auth'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useGetOrganization, useUpdateOrganization } from 'api/organizations'
import LoaderOverlay from 'components/LoaderOverlay'
import { PaneContent } from 'components/misc'
import { UserTable } from 'components/Organizations/UserTable'
import { canEditOrg } from 'components/Organizations/permissions'
import styles from 'components/Organizations/Organization.module.scss'
import LoadingButton from 'components/misc/LoadingButton'
import { API_STATUS } from 'helpers/constants'
import { CloudDownload, Edit as EditIcon } from '@mui/icons-material'
import { AddMember } from 'components/Organizations/AddMember'
import { Page404 } from 'components/404Page'
import ProfilePhotoAttachment from 'components/misc/ProfilePhotoAttachment'
import { OrganizationTransferList } from 'components/Organizations/TransferList'
import { useTranslation } from 'react-i18next'
import { OrganizationContentPartners } from '../../../components/Organizations/OrganizationContentPartners'

/**
 * The Organization component.
 *
 * @alias module:Organization
 *
 * @returns {React.ReactElement} - The Organization component.
 */
export function Organization() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { user } = useAuth()
  const {
    data: org,
    status: loadingStatus,
    error: serverError,
  } = useGetOrganization(id)
  const { mutate, status: loadingUpdateStatus } = useUpdateOrganization(id)
  const { t } = useTranslation(['organizations', 'common'])

  const isApproving = loadingUpdateStatus === API_STATUS.LOADING

  const orgId = id ? parseInt(id, 10) : null

  const organizationError = React.useMemo(() => {
    if (serverError && serverError instanceof Error) {
      return serverError
    }
    return null
  }, [serverError])

  if (organizationError?.message === 'OrganizationNotFound') {
    return <Page404 />
  }

  if (loadingStatus === API_STATUS.LOADING || !org || !user || !orgId) {
    return <LoaderOverlay />
  }

  const canUserEditOrganization =
    user && (user.can('edit:organization') || canEditOrg(user, orgId))

  const isPending = org?.status === 'pending'

  const isAdmin = user?.role === 'admin'

  return (
    <div className={styles.fullWidth}>
      <PaneContent className={styles.orgWrapper}>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          marginY={2}
        >
          <Box>
            <Typography variant="h1">
              {org?.name}{' '}
              {isPending && t('organizations:single_org.pending_approval')}
            </Typography>
          </Box>
          <Box>
            {isPending && (
              <LoadingButton
                className={styles.toolbarButtonActive}
                isLoading={isApproving}
                onClick={() => mutate({ status: 'approved' })}
              >
                {t('organizations:approve_organization.title')}
              </LoadingButton>
            )}

            <Box alignItems="center" display="flex" flexDirection="row">
              {canUserEditOrganization ? (
                <>
                  <AddMember orgId={orgId} />
                  <Box marginLeft={1}>
                    <Button
                      onClick={() => navigate(`/organizations/${orgId}/edit`)}
                      size="large"
                      startIcon={<EditIcon />}
                      variant="contained"
                    >
                      {t('common:edit')}
                    </Button>
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className={styles.fileUploaderWrapper}>
            <ProfilePhotoAttachment
              isLoading={false}
              url={org?.profile_photo?.asset?.original_url}
            />
          </Box>

          <Grid container={true} spacing={2}>
            <Grid item={true} xs={6}>
              <Typography
                color="textSecondary"
                display="block"
                gutterBottom={true}
                variant="subtitle1"
              >
                {t('organizations:single_org.website')}
              </Typography>

              <Typography
                component="a"
                display="block"
                gutterBottom={true}
                href={org?.website}
                rel="noopener noreferrer"
                target="_blank"
                variant="body1"
              >
                {org?.website}
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Box>
                <Typography
                  color="textSecondary"
                  display="block"
                  gutterBottom={true}
                  variant="subtitle1"
                >
                  {t('organizations:single_org.youversion_rep')}
                </Typography>
                <Typography display="block" gutterBottom={true} variant="body1">
                  {org?.staff_representative?.email || t('common:not_set')}
                </Typography>
              </Box>
            </Grid>

            <Grid item={true} xs={6}>
              <Typography
                color="textSecondary"
                display="block"
                gutterBottom={true}
                variant="subtitle1"
              >
                {t('organizations:single_org.address')}
              </Typography>
              <Typography display="block" gutterBottom={true} variant="body1">
                {org?.address}
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Box>
                <Typography
                  color="textSecondary"
                  display="block"
                  gutterBottom={true}
                  variant="subtitle1"
                >
                  {t('organizations:single_org.partner_agreement')}
                </Typography>

                {org && Object.keys(org?.partnership_agreement || {}).length ? (
                  <>
                    <Typography
                      display="block"
                      gutterBottom={true}
                      variant="body1"
                    >
                      {t('organizations:single_org.updated_date', {
                        date: new Date(
                          org.partnership_agreement.updated_at,
                        ).toLocaleDateString(),
                      })}
                    </Typography>
                    <Button
                      href={org?.partnership_agreement?.source_url}
                      size="medium"
                      startIcon={<CloudDownload />}
                      target="blank"
                      variant="outlined"
                    >
                      {t('organizations:single_org.download')}
                    </Button>
                  </>
                ) : (
                  <Typography
                    display="block"
                    gutterBottom={true}
                    variant="caption"
                  >
                    {t(
                      'organizations:single_org.no_partnership_agreement_attached',
                    )}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography
                color="textSecondary"
                display="block"
                gutterBottom={true}
                variant="subtitle1"
              >
                {t('organizations:single_org.phone')}
              </Typography>
              <Typography display="block" gutterBottom={true} variant="body1">
                {org?.phone}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Can user="administrate:all_permissions">
          <OrganizationTransferList orgId={orgId} />
        </Can>

        {user ? (
          <Can user="edit:organization_membership">
            <UserTable currentUser={user} isEditing={false} orgId={orgId} />
          </Can>
        ) : null}

        {isAdmin ? (
          <OrganizationContentPartners organizationId={orgId} />
        ) : null}
      </PaneContent>
    </div>
  )
}
