/**
 * @module LoggedInLayout
 */
import React from 'react'
import { useDispatch } from 'react-redux'
import { AlertProvider, Alerts } from '@youversion/react'
import { loadLoggedInRoute } from 'state/actions'

/**
 * Loading wrapper for logged in routes.
 *
 * @param {object} props - The component props.
 * @param {React.ReactChildren} props.children - React children.
 *
 * @returns {React.ReactElement} - The LoggedInLayout component.
 */
function LoggedInLayout({ children }: React.PropsWithChildren) {
  const dispatch: (thunk: any) => Promise<{ error: Error; message: string }> =
    useDispatch()

  React.useEffect(() => {
    dispatch(loadLoggedInRoute())
  }, [dispatch])

  return (
    <AlertProvider>
      <Alerts
        snackbar={true}
        snackbarProps={{
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        }}
      />
      {children}
    </AlertProvider>
  )
}

export default LoggedInLayout
