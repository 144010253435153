/**
 * @module LanguageNumIcon
 */
import React, { FC } from 'react'
import { Badge, Box } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'

export interface NumberOfLanguagesProps {
  numberOfLangs: number
}

const LanguageNumIcon: FC<NumberOfLanguagesProps> = ({ numberOfLangs }) => {
  if (numberOfLangs > 1) {
    return (
      <Box position="relative">
        <Box position="absolute" right={4} top={-4}>
          <Badge
            badgeContent={numberOfLangs}
            color="primary"
            overlap="rectangular"
          />
        </Box>
        <LanguageIcon fontSize="large" />
      </Box>
    )
  }

  return <LanguageIcon fontSize="large" />
}

export default LanguageNumIcon
