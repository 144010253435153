/* eslint-disable jsdoc/require-param */
/**
 * @module EditComment
 */
// import { useState } from 'react'
import { Box, TextareaAutosize } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEditComment } from 'api/plans'
import { grey } from '@mui/material/colors'
import { AsyncButton, useAlert } from '@youversion/react'
import { Plan } from 'components/Plans/types'
import { NumberLike } from 'types/misc'
import { API_STATUS } from 'helpers'
import { useStateIfMounted } from 'hooks/use-state-if-mounted'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  commentCardActionsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editButton: {
    '&:hover': {
      backgroundColor: grey['400'],
    },
    backgroundColor: grey['300'],
    paddingInlineEnd: theme.spacing(2),
    paddingInlineStart: theme.spacing(2),
  },
  editingComment: {
    backgroundColor: grey['300'],
    border: 'none',
    borderRadius: theme.spacing(1),
    marginBlockEnd: theme.spacing(1),
    marginBlockStart: theme.spacing(1),
    outline: 'none',
    resize: 'none',
  },
}))

interface EditCommentProps {
  /** The specified comment object.. */
  comment: Plan.Comment
  /** The day id. */
  dayId?: NumberLike
  /** The plan id. */
  planId: NumberLike
  /** Handler function to update the local comments after update button is clicked.  */
  updateLocalComments: (value: string) => void
}

/**
 * Represents a Comment edit form.
 *
 * @returns {ReactElement} A Comment edit component.
 */
export function EditComment({
  comment,
  dayId,
  planId,
  updateLocalComments,
}: EditCommentProps) {
  const { t } = useTranslation(['plans'])
  const { throwAlert } = useAlert()
  const classes = useStyles()
  const [value, setValue] = useStateIfMounted(comment.body_html)
  const { mutateAsync, status: loadingStatus } = useEditComment({
    dayId,
    planId,
  })

  const submitting = loadingStatus === API_STATUS.LOADING

  // Check and make sure the value was updated before setting the button to active.
  const commentIsNotUpdated = Boolean(comment.body_html === value)

  async function handleSubmit() {
    try {
      await mutateAsync({
        comment: value,
        commentId: comment.id,
      })
      updateLocalComments(value) // Update comment locally.
      throwAlert({
        id: 'edit_comment_success',
        key: 'edit_comment_success',
        message: t('plans:comment.comment_updated_successfully'),
        timeout: 3000,
        type: 'success',
      })
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'update_comment_error',
          key: 'update_comment_error',
          message: t('plans:comment.unable_to_update_comment', {
            message: error.message,
          }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextareaAutosize
        autoComplete="off"
        className={classes.editingComment}
        disabled={submitting}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        placeholder={
          comment.body_html ?? t('plans:comment.write_a_new_comment')
        }
        value={value}
      />

      <Box className={classes.commentCardActionsContainer}>
        <AsyncButton
          className={classes.editButton}
          disabled={commentIsNotUpdated}
          idle={t('plans:comment.update')}
          onClick={handleSubmit}
          options={{
            disableUpdateOnSuccess: true,
          }}
          pending={t('plans:comment.updating')}
          rejected={t('plans:comment.error')}
        />
      </Box>
    </form>
  )
}
