import * as React from 'react'
import { Box } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  downloadContainer: {
    padding: theme.spacing(4),
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    maxWidth: 800,
  },
}))

type Props = {
  children?: React.ReactNode
}
/**
 * A download section.
 *
 * @param {React.ReactNode} children - The react children to render within the component.
 *
 * @returns {React.ReactElement} - A download section in the block content preview component.
 */
const DownloadContainer = ({ children }: Props) => {
  const classes = useStyles()
  return (
    <Box
      alignItems="center"
      className={classes.downloadContainer}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {children}
    </Box>
  )
}

export default DownloadContainer
