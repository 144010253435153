/**
 * Determines the localized month abbreviation for a given numeric value (ex. 0 = January).
 *
 * @param locale
 *
 * @param monthIndex
 *
 * @example
 * ```typescript
 * const monthAbbreviation = getLocalizedMonthAbbreviation(i18n.language, 1);
 * ```
 *
 * Returns a string value like "Feb".
 *
 * @returns String.
 */
export function getLocalizedMonthAbbreviation(
  locale: string,
  monthIndex: number,
) {
  const currentYear = new Date().getFullYear()
  const date = new Date(currentYear, monthIndex, 1)
  const abbreviatedMonthFormat = new Intl.DateTimeFormat(locale, {
    month: 'short',
  })

  return abbreviatedMonthFormat.format(date)
}
