/**
 * @module AppReducer
 */

import update from 'immutability-helper'
import { handleActions } from 'redux-actions'

import { APP_BOOTSTRAP_COMPLETED } from 'state/actions/app/constants'

const initialState = {
  bootstrapped: false,
  bootstrappedAt: null,
}

export default handleActions(
  {
    [APP_BOOTSTRAP_COMPLETED]: (state) => {
      return update(state, {
        bootstrapped: { $set: true },
        bootstrappedAt: { $set: Date.now() },
      })
    },
  },
  initialState,
)
