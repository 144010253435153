/**
 * @module GlobalNotifications
 */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import NotificationSystem from 'react-notification-system'
import { removeNotification } from 'state/reducers/globalNotifications'
import { State } from 'state/reducers'
import NotificationsDisplay, { Notification } from '../NotificationDisplay'

const mapStateToProps = (state: State) => {
  const globalNotifications = state.globalNotifications as {
    notifications: Array<Notification>
  }

  return globalNotifications.notifications
}

function GlobalNotifications() {
  const dispatch: (thunk: any) => Promise<{ error: Error; message: string }> =
    useDispatch()
  const notifications = useSelector(mapStateToProps)

  const handleRemoveNotification = React.useCallback(
    (uid: NotificationSystem.Notification['uid']) => {
      dispatch(removeNotification(uid))
    },
    [dispatch],
  )
  return (
    <NotificationsDisplay
      notifications={notifications}
      onRemoveNotification={handleRemoveNotification}
    />
  )
}

export default GlobalNotifications
