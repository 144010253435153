import { API_ADDRESS, getToken } from 'helpers'
import { useMutation, useQuery } from '@tanstack/react-query'
import { NumberLike } from 'types/misc'

const getGroupPermissions = async (
  groupId?: NumberLike,
): Promise<Array<GroupPermission>> => {
  const authToken = await getToken()
  const response = await fetch(
    `${API_ADDRESS}/v1/group_permissions?group_id=${groupId}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'GET',
    },
  )
  const jsonResponse = await response.json()
  return jsonResponse.data
}

export const useGetGroupPermissions = (groupId?: NumberLike) =>
  useQuery(['group-permissions', groupId], () => getGroupPermissions(groupId))

interface Props {
  groupId: number
  permissionId: string
}

const createGroupPermissions = async ({
  groupId,
  permissionId,
}: Props): Promise<GroupPermission> => {
  if (!groupId || !permissionId) {
    throw new Error(
      '`groupId` and `permissionId` are required and must be a number or string.',
    )
  }
  const authToken = await getToken()
  const response = await fetch(`${API_ADDRESS}/v1/group_permissions`, {
    body: JSON.stringify({
      group_permission: {
        group_id: groupId,
        permission_id: permissionId,
      },
    }),
    headers: {
      authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
  const jsonResponse = await response.json()
  return jsonResponse.data
}

export const useCreateGroupPermissions = () =>
  useMutation(createGroupPermissions)

const deleteGroupPermissions = async (groupPermissionId: number) => {
  if (!groupPermissionId) {
    throw new Error(
      '`groupPermissionId` is required and must be a number or string.',
    )
  }
  const authToken = await getToken()
  const response = await fetch(
    `${API_ADDRESS}/v1/group_permissions/${groupPermissionId}`,
    {
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
  )
  return response.status
}

export const useDeleteGroupPermissions = () =>
  useMutation(deleteGroupPermissions)
