import { Organization } from 'containers/organizations/organization'
import { EditOrganization } from 'containers/organizations/organization/edit'
import { Route, Routes } from 'react-router-dom'

/**
 * The OrganizationRoutes component.
 *
 * @alias module:OrganizationRoutes
 *
 * @returns {React.ReactElement} - The OrganizationRoutes component.
 */
export function OrganizationRoutes() {
  return (
    <Routes>
      <Route element={<Organization />} index={true} />

      {/* 🐣 Nested routes only below this line. */}
      <Route element={<EditOrganization />} path="edit" />
    </Routes>
  )
}
