import React from 'react'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from '@mui/material'
import { NumberLike } from 'types/misc'
import { useTranslation } from 'react-i18next'
import { AddMemberModal } from './AddMemberModal'

interface AddMemberProps {
  orgId: NumberLike
}

export function AddMember({ orgId }: AddMemberProps) {
  const [isAddingUser, setIsAddingUser] = React.useState(false)
  const { t } = useTranslation(['organizations'])

  return (
    <>
      <Button
        onClick={() => setIsAddingUser(true)}
        size="large"
        startIcon={<AddIcon />}
        variant="outlined"
      >
        {t('organizations:single_org.add_member.add_member')}
      </Button>

      <AddMemberModal
        isAddingUser={isAddingUser}
        orgId={orgId}
        setIsAddingUser={setIsAddingUser}
      />
    </>
  )
}
