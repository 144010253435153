/**
 * @module LinkIcon
 */

import React, { FC } from 'react'
import { SvgIcon } from '@mui/material'

const LinkIcon: FC = () => {
  return (
    <SvgIcon color="primary" fontSize="small" viewBox="0 0 24 24">
      <path d="M21.4 7.50005C22.2 8.30005 22.2 9.60005 21.4 10.3001L18.6 13.1001L10.8 5.30005L13.6 2.50005C14.4 1.70005 15.7 1.70005 16.4 2.50005L18.2 4.30005L21.2 1.30005L22.6 2.70005L19.6 5.70005L21.4 7.50005ZM15.6 13.3001L14.2 11.9001L11.4 14.7001L9.3 12.6001L12.1 9.80005L10.7 8.40005L7.9 11.2001L6.4 9.80005L3.59999 12.6001C2.79999 13.4001 2.79999 14.7001 3.59999 15.4001L5.39999 17.2001L1.39999 21.2001L2.79999 22.6001L6.8 18.6001L8.6 20.4001C9.4 21.2001 10.7 21.2001 11.4 20.4001L14.2 17.6001L12.8 16.2001L15.6 13.3001Z" />
    </SvgIcon>
  )
}

export default LinkIcon
