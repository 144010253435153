/**
 * @module AnalyticsRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import { Route, Routes } from 'react-router-dom'
import { Alert, Box, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

/**
 * The Analytics Routes component.
 *
 * @alias module:AnalyticsRoutes
 *
 * @returns {React.ReactElement} - The Analytics Routes component.
 */
export default function AnalyticsRoutes() {
  const { t } = useTranslation('analytics')

  return (
    <Box mb={3} mt={3}>
      <Typography color="textPrimary" gutterBottom={true} variant="h1">
        {t('analytics_title')}
      </Typography>

      <Box mt={2}>
        <Routes>
          <Route
            index={true}
            element={
              <>
                <Alert severity="warning">
                  <Trans
                    i18nKey={'analytics:analytics_message'}
                    components={{
                      a: (
                        <a
                          href="mailto:partnerships@youversion.com"
                          style={{
                            color: '#603E14',
                            textDecorationColor: '#603E14',
                          }}
                          rel="noreferrer"
                        />
                      ),
                    }}
                  />
                </Alert>
              </>
            }
          />
        </Routes>
      </Box>
    </Box>
  )
}
