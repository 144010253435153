/**
 * @module NarratedAudioIntroDialog
 */
/* eslint-disable react/no-multi-comp, import/prefer-default-export */
import React from 'react'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import LaunchIcon from '@mui/icons-material/Launch'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as NarratedAudioIllustration } from 'assets/introducing-narrated-audio.svg'

const useStyles = makeStyles((theme) => ({
  buttonDismiss: {
    color: theme.palette.text.secondary,
  },
  buttonLearnMore: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  buttonClose: {
    position: 'absolute',
    insetInlineEnd: theme.spacing(1),
    insetBlockStart: theme.spacing(1),
  },
  dialog: {
    position: 'relative',
    maxWidth: 500,
    width: '100%',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />
})

export const narratedAudioIntroDismissedKey =
  'feature-narrated-audio-intro-dismissed'

// Date(year, monthIndex, day), where monthIndex is zero-based:
const rightAboutNow = new Date()
const dialogStartDate = new Date(2021, 6, 5)
// Teach users about Narrated Audio until end of the current year.
const dialogEndDate = new Date(2021, 11, 31)

const isNarratedAudioDismissed = JSON.parse(
  localStorage.getItem(narratedAudioIntroDismissedKey),
)

/**
 * Narrated Audio Intro Dialog Component.
 * Introduces the Narrated Audio feature to the user. This component will only appear if it hasn't been manually dismissed and the current date fits within the specified time-frame.
 *
 * @alias module:NarratedAudioIntroDialog
 *
 * @returns {React.ReactElement} Narrated Audio Intro Dialog Component.
 */
export function NarratedAudioIntroDialog() {
  const [showDialog, setShowDialog] = React.useState(
    () => !isNarratedAudioDismissed,
  )
  const classes = useStyles()

  function hideFeatureDialog() {
    localStorage.setItem(narratedAudioIntroDismissedKey, JSON.stringify(true))
    setShowDialog(false)
  }

  // This feature introduction component will only show within the allotted time.
  if (!(rightAboutNow >= dialogStartDate && rightAboutNow < dialogEndDate)) {
    return null
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      classes={{ paper: classes.dialog }}
      onClose={() => setShowDialog(false)}
      open={showDialog}
    >
      <IconButton
        className={classes.buttonClose}
        onClick={() => setShowDialog(false)}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="align"
        m={4}
        mb={2}
      >
        <Box clone={true} mb={2}>
          <NarratedAudioIllustration height="100%" width="75%" />
        </Box>
        <Typography align="center" gutterBottom={true} variant="h2">
          Introducing Plan Narrated Audio
        </Typography>
        <Typography align="center" color="textSecondary" variant="body1">
          Your Plans, narrated in the voice you choose. Select Narrated Audio
          for new or existing Plans, and add your own custom audio.
        </Typography>
        <Box clone={true} mt={2}>
          <Button
            className={classes.buttonLearnMore}
            color="secondary"
            component="a"
            endIcon={<LaunchIcon fontSize="inherit" />}
            href="https://partners.youversion.com/blog/now-the-bible-app-can-read-your-plans-to-your-community"
            rel="noopener noreferrer"
            target="_blank"
            variant="contained"
          >
            Learn More
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            className={classes.buttonDismiss}
            onClick={hideFeatureDialog}
            size="small"
            variant="text"
          >
            Don&apos;t Show Again
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
