/**
 * @module CustomList
 */
import React, { FC } from 'react'
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import styles from '../TransferList.module.scss'

interface Props {
  checked: PermissionItem[]
  handleToggle: Function
  items: PermissionItem[]
}

// Note: Majority of the code in customList is directly from MaterialUI. It is updated as needed.
// eslint-disable-next-line react/no-multi-comp

const CustomList: FC<Props> = ({ checked, handleToggle, items }) => {
  return (
    <Paper>
      <List
        className={styles.customList}
        component="div"
        dense={true}
        role="list"
      >
        {items.map((permissionItem: PermissionItem) => {
          const labelId = `transfer-list-item-${permissionItem.key}-label`
          return (
            <ListItem
              button={true}
              key={permissionItem.key}
              onClick={handleToggle(permissionItem)}
              role="listitem"
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(permissionItem) !== -1}
                  disableRipple={true}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${permissionItem.label}`} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )
}

export default CustomList
