/* eslint-disable jsdoc/require-param */
/**
 * @module NameField
 */
import { ReactElement, useState } from 'react'
import { Box, InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { grey } from '@mui/material/colors'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
    marginInlineStart: theme.spacing(2),
  },
  closeBorder: {
    borderColor: grey[300],
    cursor: 'pointer',
    borderBlockEndWidth: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
  },
  inputForName: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    backgroundColor: grey['100'],
    borderRadius: theme.spacing(5),
    padding: `0px ${theme.spacing(1)}`,
  },
  noBorder: {
    border: 'none',
  },
}))

/**
 * Input Component for filtering by plan names.
 *
 * @returns {ReactElement} - Returns an input component.
 */
export function NameField() {
  const classes = useStyles()
  const { t } = useTranslation('plans')
  const [query, setQuery] = useSearchParams()
  const [name, setName] = useState(query.get('name') ?? '')

  return (
    <TextField
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
        className: classes.inputForName,
        endAdornment:
          name === '' ? null : (
            <InputAdornment position="end">
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <Box
                  aria-label={t('label.clear_search_input')}
                  className={classes.closeBorder}
                  onClick={() => {
                    setName('')
                    query.delete('name')
                    setQuery(query)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </Box>
                <Box
                  aria-label={t('label.search_plans')}
                  className={classes.clickable}
                  onClick={() => {
                    query.set('name', name)
                    setQuery(query)
                  }}
                >
                  <SearchIcon fontSize="inherit" />
                </Box>
              </Box>
            </InputAdornment>
          ),
        startAdornment:
          name === '' ? (
            <InputAdornment
              aria-label={t('label.search_for_plan')}
              position="start"
            >
              <SearchIcon fontSize="inherit" />
            </InputAdornment>
          ) : null,
      }}
      fullWidth={true}
      onChange={(e) => setName(e.target.value)}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          query.set('name', name)
          setQuery(query)
        }
      }}
      placeholder={t('label.search_for_plan')}
      type="text"
      value={name}
      variant={'outlined'}
    />
  )
}
