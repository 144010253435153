import React, { FC, useState } from 'react'
import { Dialog, DialogTitle, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { LazyImage } from '@youversion/react'
import LinkPlanIcon from 'assets/link-icon.png'
import { useGetLanguages } from 'api/languages'
import { useGetTranslatableWithPlans } from 'api/plans'
import { usePlan } from 'context'
import { Plan } from 'components/Plans/types'
import { API_STATUS } from 'helpers'
import { useTranslation } from 'react-i18next'
import InitialDialog from './InitialDialog'
import FinalDialog from './FinalDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '16px',
      height: '145px',
      width: '170px',
    },
    cardContainer: {
      height: '200px',
      margin: '4px 0px',
      width: '170px',
    },

    checkIcon: {
      backgroundColor: 'grey',
      borderRadius: '16px',
    },
    dialog: {
      width: '400px',
    },
    icon: {
      alignSelf: 'center',
      marginHorizontal: '50px',
      marginVertical: '50px',
    },
    iconImage: {
      marginInlineEnd: theme.spacing(4),
    },
    list: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: 'scroll',
    },
    mainCardContainer: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxHeight: 250,
      width: '100%',
    },
    rowPadding: { padding: '25px 0px' },
    selectedCard: {
      borderStartStartRadius: '16px',
      borderStartEndRadius: '16px',
      height: '200px',
      margin: '4px 0px',
      width: '170px',
    },
    textField: {
      borderRadius: '4px',
      marginVertical: '10px',
    },
  }),
)

const steps = {
  step1: '1',
  step2: '2',
}

interface Props {
  handleClose: () => void
  isDialogOpen: boolean
}

const LinkPlanLanguage: FC<Props> = ({ handleClose, isDialogOpen }) => {
  const { t } = useTranslation('plans')
  const classes = useStyles()
  const { data: languages, isLoading: isLoadingLanguages } = useGetLanguages()
  const { plan: currentPlan } = usePlan()

  const [step, setStep] = React.useState(steps.step1)
  const [selectedPlan, setSelectedPlan] = useState<Plan.Plan>()

  const translatableWithFilter =
    'id' in currentPlan ? String(currentPlan.id) : undefined
  const {
    data: translatableWithPlanResponse,
    status: statusForTranslatablePlans,
  } = useGetTranslatableWithPlans(translatableWithFilter)

  function handleStep(stepper: string) {
    setStep(stepper)
  }

  const isLoadingTranslatableWithPlans =
    statusForTranslatablePlans === API_STATUS.LOADING

  const isSteppableToStep2 =
    'id' in currentPlan && languages && selectedPlan && step === steps.step2

  return (
    <Dialog
      aria-describedby="dialog-wrapper"
      aria-labelledby="dialog-wrapper-label"
      classes={{ paper: classes.dialog }}
      onClose={handleClose}
      open={isDialogOpen}
    >
      <DialogTitle id="dialog-wrapper-title">
        <LazyImage
          alt="link-icon"
          className={classes.iconImage}
          height="10px"
          src={LinkPlanIcon}
          width="20px"
        />
        {t('plans:label.connect_an_existing_plan')}
      </DialogTitle>

      {step === steps.step1 ? (
        <InitialDialog
          handleClose={handleClose}
          handleStep={() => handleStep('2')}
          isLoadingPlans={isLoadingLanguages || isLoadingTranslatableWithPlans}
          languages={languages}
          plans={translatableWithPlanResponse?.data}
          setSelectedPlan={setSelectedPlan}
        />
      ) : null}

      {isSteppableToStep2 ? (
        <FinalDialog
          handleBack={() => handleStep('1')}
          handleClose={handleClose}
          languages={languages}
          selectedPlan={selectedPlan}
        />
      ) : null}
    </Dialog>
  )
}

export default LinkPlanLanguage
