import url from 'url'
import _ from 'lodash'
import { normalize } from 'normalizr'

import { apiAddressSelector, tokenSelector } from 'state/selectors'
import { xmlToJson } from 'helpers'
import { validate } from 'state/reducers/authReducer'

import * as Sentry from '@sentry/browser'

function _getQueryParamString(params) {
  return url.format({ query: params })
}

export default class ApiClient {
  constructor(dispatch, getState) {
    ;['get', 'post', 'put', 'patch', 'delete'].forEach((method) => {
      this[method] = async (path, schema, query = {}) => {
        const { data } = query
        const { params } = query
        const { formData } = query
        const authData = query.auth

        let apiAddress = apiAddressSelector(getState()).toLowerCase()
        if (apiAddress.indexOf('://') === -1) {
          apiAddress = `${window.location.origin}/${apiAddress}`
        }

        const adjustedPath = path[0] !== '/' ? `/${path}` : path

        // If path contains URI schema, just use it instead of prepending API.
        const isApiPath = path.indexOf('://') === -1
        const resolvedPath = isApiPath ? apiAddress + adjustedPath : path

        if (!resolvedPath) {
          throw new Error('API path not available.')
        }

        let bodyData = null
        const headers = new Headers()

        const token = tokenSelector(getState())
        if (token && isApiPath) {
          headers.set('Authorization', `Bearer ${token}`)
        }

        if (authData) {
          const authBase64 = btoa(`${authData.user}:${authData.password}`)
          headers.set('Authorization', `Basic ${authBase64}`)
        }

        if (formData) {
          const _tempFormData = new FormData()
          _.forOwn(formData, (value, key) => _tempFormData.append(key, value))
          bodyData = _tempFormData
        } else {
          headers.set('Content-type', 'application/json')
        }

        if (data) {
          bodyData = JSON.stringify(data)
        }

        const request = new Request(
          resolvedPath + _getQueryParamString(params),
          {
            method: method.toUpperCase(),
            headers,
            mode: 'cors',
            body: bodyData || undefined, // Must be undefined. Edge errors with null
          },
        )

        let response = {}
        try {
          response = await fetch(request)
        } catch (ಠ_ಠ) {}

        let result = null
        try {
          const contentType = response.headers.get('Content-Type') || ''
          if (contentType.indexOf('application/xml') !== -1) {
            const text = await response.text()
            const parser = new DOMParser()
            const xml = parser.parseFromString(text, 'application/xml')
            result = xmlToJson(xml)
          } else {
            result = await response.json()
          }
        } catch (ಠ_ಠ) {
          // Do nothing if the call to json() fails to parse
        }

        // Rails responds with errors e.g.:
        //   {
        //     user.email: ["may not be empty", "is not a valid email"],
        //     user.password: [...],
        //     organization.name: [...],
        //   }
        //
        // They can nest levels deep. We translate that back into a normal JS
        // object so that redux-form will map the errors back to fields for us.
        if (result && result.details) {
          let realDetails = {}
          Object.keys(result.details).forEach((detailKey) => {
            const deDotted = detailKey.split('.').reduceRight((acc, key) => {
              return { [key]: acc[0] || acc }
            }, result.details[detailKey])
            realDetails = _.merge(realDetails, deDotted)
          })

          result._origDetails = result.details
          result.details = realDetails
        }

        if (!response.ok) {
          /* eslint-disable func-names, no-unused-vars */

          // We check the response status because we don't want to send
          // this error to Sentry if we know it's a 401/auth error. Those
          // are no longer useful in this context.
          if (process.env.REACT_APP_SENTRY_DSN && response.status !== 401) {
            // According to my reading of Sentry docs, this is the new way to
            // add specific extra info to reported exceptions, that will ONLY
            // apply to this report (we scope this message):
            // See: https://docs.sentry.io/platforms/javascript/#extra-context
            // This will be changed only for the error caught inside
            // and automatically discarded afterward...
            Sentry.withScope(function () {
              Sentry.setExtra('result', JSON.stringify(result))
              Sentry.setExtra('response', JSON.stringify(response))
              Sentry.captureException(
                new Error('API Client received a bad response.'),
              )
            })
          }
          /* eslint-enable func-names, no-unused-vars */

          // If we get a 401, cause the app to check token validation and
          // potentially log out
          if (token && response.status === 401) {
            dispatch(validate())
          }

          // In case of empty response, prevent against json.message undefined
          const genericError = `A network error occurred: ${response.statusText}`
          const json = {
            isError: true,
            error: response.statusText || 'Unknown Error',
            message: genericError,
            details: { _error: genericError },
            ...result,
          }

          throw json
        }

        if (schema) {
          return normalize(result, schema)
        }

        return result
      }
    })
  }
}
