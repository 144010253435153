/**
 * @module Root
 */
import React, { FC, PropsWithChildren } from 'react'
import Helmet from 'react-helmet'
import 'normalize.css'
import 'react-datepicker/dist/react-datepicker.css'
import faviconIco from '@youversion/react/assets/images/favicon.ico'
import '@youversion/mui-block-editor/dist/text-block.css'
import 'styles/linea.scss'
import { BASE_TITLE } from 'helpers/constants'
import GlobalNotifications from 'components/Notifications/GlobalNotifications'
import styles from './Root.module.scss'

const Root: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className={styles.base}>
      <Helmet title={BASE_TITLE}>
        <link href={faviconIco} id="favicon" rel="icon" type="image/x-icon" />
      </Helmet>
      <GlobalNotifications />
      {children}
    </div>
  )
}

export default Root
