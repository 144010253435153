/**
 * Gets the active organization.
 */
export const getActiveOrg = () => {
  const orgId = localStorage.getItem('orgId')

  if (orgId) {
    const parsedOrg = JSON.parse(orgId)
    if (typeof parsedOrg === 'number') {
      return parsedOrg
    }
  }

  if (orgId === 'ALL_ORGS') {
    return orgId
  }

  return new Error('No Org Found')
}
