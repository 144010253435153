import React from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '@youversion/design-system'
import { useTranslation } from 'react-i18next'

interface AdminWrapperProps {
  children: React.ReactElement | React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: 'white',
    color: colors['green-50'],
    padding: `0 ${theme.spacing(2)}`,
    position: 'absolute',
    insetBlockStart: '-10px',
  },
  wrapper: {
    border: '1px solid lightgrey',
    borderColor: colors['gray-15'],
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    width: '100%',
  },
}))

export default function AdminWrapper({ children }: AdminWrapperProps) {
  const classes = useStyles()
  const { t } = useTranslation('plans')

  return (
    <Box className={classes.wrapper} position="relative">
      <span className={classes.title}>
        {t('plans:new_translation.admin_only')}
      </span>
      {children}
    </Box>
  )
}
