/* eslint-disable jsdoc/require-param */
/**
 * @module DeleteComment
 */
import makeStyles from '@mui/styles/makeStyles'
import { useDeleteComment } from 'api/plans'
import { red } from '@mui/material/colors'
import { AsyncButton, useAlert } from '@youversion/react'
import { Plan } from 'components/Plans/types'
import { NumberLike } from 'types/misc'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    '&:hover': {
      backgroundColor: red['400'],
    },
    backgroundColor: red['300'],
    borderRadius: theme.spacing(3),
    color: theme.palette.common.white,
  },
}))

interface DeleteCommentProps {
  /** The specified comment object. */
  comment: Plan.Comment
  /** The day id. */
  dayId?: NumberLike
  /** The plan id. */
  planId: NumberLike
  /** Handler function to delete the specified comment.  */
  updateLocalComments: () => void
}

/**
 * Represents a Comment delete action button.
 *
 * @returns {React.ReactElement} A Comment delete action button component.
 */
export function DeleteComment({
  comment,
  dayId,
  planId,
  updateLocalComments,
}: DeleteCommentProps) {
  const { t } = useTranslation(['plans'])
  const { throwAlert } = useAlert()
  const classes = useStyles()
  const { mutateAsync } = useDeleteComment({ planId, dayId })

  async function handleDelete() {
    try {
      await mutateAsync(comment.id)
      updateLocalComments() // Delete comment locally.
      throwAlert({
        id: 'delete_comment_success',
        key: 'delete_comment_success',
        message: t('plans:comment.comment_deleted_successfully'),
        timeout: 3000,
        type: 'success',
      })
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'delete_comment_error',
          key: 'delete_comment_error',
          message: t('plans:comment.unable_to_delete_comment', {
            message: error.message,
          }),
          timeout: 3000,
          type: 'error',
        })
      }
    }
  }

  return (
    <AsyncButton
      className={classes.deleteButton}
      idle={t('plans:comment.delete')}
      onClick={handleDelete}
      options={{
        disableUpdateOnSuccess: true,
      }}
      pending={t('plans:comment.deleting')}
      rejected={t('plans:comment.error')}
    />
  )
}
