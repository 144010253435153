/**
 * @module CreateStore
 */

import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { middleware as reduxPackMiddleware } from 'lib/redux-pack'

import createApiMiddleware from './createApiMiddleware'
import reducer from './reducers'
import ApiClient from './ApiClient'

const createApiClient = (...args) => new ApiClient(...args)

export default function create() {
  const middleware = [
    createApiMiddleware(createApiClient),
    thunk,
    reduxPackMiddleware,
  ]

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middleware)),
  )

  return store
}
