/* eslint-disable jsdoc/require-param */
/**
 * @module CommentSection
 */
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { useAuth } from 'auth'
// import { grey } from '@mui/material/colors'
import { NumberLike } from 'types/misc'
// import { useGetComments } from 'api/plans'
// import { API_STATUS } from 'helpers'
import { Plan } from 'components/Plans/types'
import { useTranslation } from 'react-i18next'
import { Comment } from './Comment'
import { CreateComment } from './CreateComment'

interface CommentSectionProps {
  /** The day id. */
  dayId?: NumberLike
  /** The plan id. */
  planId: NumberLike
  /** Comments. */
  comments: Array<Plan.Comment>
  /** Refetch Comments. */
  refetch: VoidFunction
}

/**
 * List all comments of a plan or plan day.
 *
 * @returns {React.ReactElement} Returns jsx.
 */
function CommentSection({
  dayId,
  planId,
  comments,
  refetch,
}: CommentSectionProps) {
  const { user } = useAuth()
  const { t } = useTranslation(['plans'])

  return (
    <Box data-testid="comment-section-container">
      {/* Only render comment form if this user is allowed
         to create comments: - disable shared partner login (user 7449) from commenting. Replace when permissions is rolled out.  */}
      {user && user.can('create:comment') && user.id !== 7449 ? (
        <>
          <CreateComment dayId={dayId} planId={planId} refetch={refetch} />
        </>
      ) : null}

      {comments && comments.length > 0 ? (
        comments.map((comment) => (
          <Comment
            comment={comment}
            dayId={dayId}
            didCurrentUserCreateThis={
              user ? Boolean(user.id === comment.author.id) : false
            }
            key={comment.id}
            planId={planId}
            refetch={refetch}
          />
        ))
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          mt={2}
          pb={2}
          pt={4}
          width="100%"
        >
          <Box color="text.secondary" data-testid="chatbubble-icon" mb={1}>
            <ChatBubbleIcon color="inherit" />
          </Box>
          <Typography align="center" color="textSecondary">
            {t('plans:comment.comments_will_appear_here')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

CommentSection.propTypes = {
  dayId: PropTypes.number,
  planId: PropTypes.number.isRequired,
}

CommentSection.defaultProps = {
  dayId: null,
  ownAvatarUrl: '',
}

export default CommentSection
