import { Button, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { gray } from '@youversion/react/styles/colors-v3'
import { useTransferPlan } from 'api/plans'
import { NumberLike } from 'types/misc'
import { statusTypes } from '@youversion/utils'
import { useAlert } from '@youversion/react'
import { API_STATUS } from 'helpers'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

const useStyles = makeStyles(() => ({
  buttonProgress: {
    backgroundColor: gray[20],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[20],
    },
    marginInlineEnd: '.5rem',
    marginBlockEnd: '1rem',
    position: 'relative',
  },
  circularLoading: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    color: 'black',
  },
  transferButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[30],
    },
    marginInlineEnd: '.5rem',
    marginBlockEnd: '1rem',
  },
}))

interface Props {
  planId: NumberLike
  transferOrganization: Organization | null
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

export function TransferPlanButton({
  planId,
  transferOrganization,
  isOpen,
  setOpen,
}: Props) {
  const classes = useStyles()
  const { throwAlert } = useAlert()
  const navigate = useNavigate()
  const { mutateAsync: transferPlan, status: loadingStatus } = useTransferPlan()

  async function handleSubmit() {
    try {
      await transferPlan({
        planId,
        data: {
          plan: {
            primary_organization_id: transferOrganization?.id,
          },
        },
      })
      navigate('/plans')
      throwAlert({
        id: 'transfer_plan_success',
        key: 'transfer_plan_success',
        message: t('plans:transfer_plan.success_transfer'),
        timeout: 5000,
        type: 'success',
      })
    } catch (error) {
      if (error instanceof Error) {
        setOpen(!isOpen)
        return throwAlert({
          id: 'transfer_plan_error',
          key: 'transfer_plan_error',
          message: t('plans:transfer_plan.error_transfer'),
          timeout: 5000,
          type: statusTypes.ERROR,
        })
      }
    }
  }

  if (loadingStatus === API_STATUS.LOADING) {
    return (
      <Button className={classes.buttonProgress} variant="contained">
        {t('plans:transfer_plan.title')}
        <CircularProgress
          className={classes.circularLoading}
          color="secondary"
          size={24}
        />
      </Button>
    )
  }
  return (
    <Button
      className={classes.transferButton}
      onClick={handleSubmit}
      variant="contained"
    >
      {t('plans:transfer_plan.title')}
    </Button>
  )
}
