/* eslint-disable no-plusplus */
export interface XMLToJSONProps {
  [data: string]: any
}

export function xmlToJson(xml: XMLDocument | Node) {
  let obj: XMLToJSONProps | string | null = {}

  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i)
      const { nodeName } = item

      if (typeof obj[nodeName] === 'undefined') {
        obj[nodeName] = xmlToJson(item)
      } else {
        if (typeof obj[nodeName].push === 'undefined') {
          const old = obj[nodeName]

          obj[nodeName] = []
          obj[nodeName].push(old)
        }
        obj[nodeName].push(xmlToJson(item))
      }
    }
  } else {
    obj = xml.textContent
  }
  return obj
}
