import { Alert } from '@mui/material'
import { useGetPlanStats } from 'api/stats'
import { API_STATUS } from 'helpers'
import styles from './Stats.module.scss'

export function StatsList() {
  const { data: stats, status: loadingStatus } = useGetPlanStats()

  if (loadingStatus === API_STATUS.SUCCESS && stats) {
    return (
      <div>
        <ul className={styles.inlineList}>
          <li>Drafts: {stats.draft}</li>
          <li>Submitted: {stats.submitted}</li>
          <li>Approved: {stats.approved}</li>
          <li>Published: {stats.published}</li>
          <li>Rejected: {stats.rejected}</li>
        </ul>
      </div>
    )
  }

  if (loadingStatus === API_STATUS.ERROR) {
    return <Alert severity="error">Failed to load data.</Alert>
  }

  return (
    <div>
      <ul className={styles.inlineList}>
        <li>Loading stats...</li>
      </ul>
    </div>
  )
}
