/**
 * @module UserRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import { User } from 'containers/users/user'

/**
 * The UserRoutes component.
 *
 * @alias module:UserRoutes
 *
 * @returns {ReactElement} - The UserRoutes component.
 */
export function UserRoutes() {
  return (
    <Routes>
      <Route index={true} element={<User />} />
    </Routes>
  )
}
