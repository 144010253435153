/**
 * @module TranslationOption
 */
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage'
import { TranslationIcon } from '../TranslationIcon'
import { TranslationPickerProps } from '../types'

export function TranslationOption({
  languageTag,
  languageName,
  status,
  optionProps,
}: TranslationPickerProps) {
  const { t, i18n } = useTranslation('plans')
  const language =
    languageTag &&
    languageName.includes(
      t('plans:language_source', {
        lang: getLocalizedLanguage(languageTag, i18n.language),
      }),
    )
      ? languageName
      : getLocalizedLanguage(languageTag ?? 'en', i18n.language)

  return (
    <li {...optionProps}>
      <Box
        alignItems="center"
        data-test-id="translation-option"
        display="flex"
        py={0.5}
      >
        <Box alignItems="center" display="flex" mr={1}>
          <TranslationIcon status={status} />
        </Box>
        <Typography color="textPrimary" variant="body1">
          {language}
        </Typography>
      </Box>
    </li>
  )
}
