/**
 * @module GroupNew
 */
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { Box, TextField } from '@mui/material'
import { useAlert } from '@youversion/react'
import { useCreateGroup } from 'api/groups'
import { PaneContent } from 'components/misc'
import { Button, Toolbar } from 'components/Navigation/Toolbar'
import styles from 'components/Groups/Groups.module.scss'
import formStyles from 'components/Groups/Form/styles.module.scss'
import { API_STATUS } from 'helpers'

/**
 * The GroupNew component.
 *
 * @returns {ReactElement} - The GroupNew component.
 */
export function GroupNew() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { throwAlert } = useAlert()

  const { mutate, status: loadingStatus } = useCreateGroup()

  const handleSubmit = (formValues: { name: string; description: string }) => {
    mutate(
      { data: formValues },
      {
        onError: () => {
          throwAlert({
            id: 'create_group_error',
            key: 'create_group_error',
            message: 'Failed to create group',
            timeout: 3000,
            type: 'error',
          })
        },
        onSuccess: (groupResponse) => {
          queryClient.invalidateQueries(['groups', groupResponse.id])
          throwAlert({
            id: 'create_group_success',
            key: 'create_group_success',
            message: `Group '${formValues.name}' created successfully`,
            timeout: 3000,
            type: 'success',
          })
          navigate(`/groups/${groupResponse.id}`)
        },
      },
    )
  }

  const isLoading = loadingStatus === API_STATUS.LOADING

  return (
    <PaneContent className={styles.paneContent}>
      <h1>Add a Group</h1>
      <Formik
        initialValues={{
          description: '',
          name: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className={formStyles.form}>
            <Toolbar>
              <Button
                disabled={
                  Object.values(values).some((current) => !current) || isLoading
                }
                type="submit"
                variant="active"
              >
                Save
              </Button>
              <Button onClick={() => navigate(-1)} type="button">
                Cancel
              </Button>
            </Toolbar>
            <Box mb={2}>
              <TextField
                aria-label="Group Name"
                fullWidth={true}
                id="name"
                label="Group Name"
                name="name"
                onChange={(event) => setFieldValue('name', event.target.value)}
                placeholder="Group Name"
                type="text"
                value={values.name}
                variant="outlined"
              />
            </Box>
            <Box>
              <TextField
                aria-label="Group Description"
                fullWidth={true}
                id="description"
                label="Group Description"
                minRows={6}
                multiline={true}
                name="description"
                onChange={(event) =>
                  setFieldValue('description', event.target.value)
                }
                placeholder="Group Description"
                value={values.description}
                variant="outlined"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </PaneContent>
  )
}
