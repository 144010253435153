/* eslint-disable consistent-return */
/* eslint-disable jsdoc/require-param */
/**
 * @module commentMethods
 */
import { useMutation } from '@tanstack/react-query'
import { Plan } from 'components/Plans/types'
import { NumberLike } from 'types/misc'
import { fetchClient } from 'helpers/transport'

export interface CreateCommentParams {
  /** The comment to save to the plan or plan day. */
  comment: string
  /** The plan's id. */
  planId: NumberLike
  /** The plan day's id. */
  dayId?: NumberLike
}

const idTypes = ['number', 'string']
/**
 * POST the plan or plan day comment.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The comment's data.
 *
 * @example
 * // creates plan comments
 * createComments({ comment: 'This is a comment', planId: 12 })
 * // creates plan day comments
 * createComments({ comment: 'This is a comment', planId: 12, dayId: 13 })
 * // throws an error
 * createComments({ comment: 'This is a comment', planId: 12, dayId: '' })
 */
export async function createComment({
  comment,
  planId,
  dayId,
}: CreateCommentParams): Promise<Plan.Comment | undefined> {
  if (!planId || !idTypes.includes(typeof planId)) {
    throw new Error('`planId` is required and must be a number or string.')
  }

  let path = `/plans/${planId}/comments`

  if (dayId) {
    path = `/plans/${planId}/days/${dayId}/comments`
  }

  const response = await fetchClient<{ comment: Plan.Comment }>(path, {
    body: JSON.stringify({
      comment: {
        body_html: comment,
      },
    }),
    method: 'POST',
  })
  return response.parsedBody.comment
}

export const useCreateComment = (props: {
  planId: NumberLike
  dayId?: NumberLike
}) => useMutation((comment: string) => createComment({ ...props, comment }))

interface UpdateCommentParams {
  /** The comment to update to the plan or plan day. */
  comment: string
  /** The plan's id. */
  planId: NumberLike
  /** The plan day's id. */
  dayId?: NumberLike
  /** The comment's id. */
  commentId: NumberLike
}

/**
 * Function to update the plan or plan day comment.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The updated comment's data.
 *
 * @example
 * // updates plan comments
 * updateComment({ comment: 'This is a comment', planId: 12, commentId: 27 })
 * // updates plan day comments
 *
 * updateComment({ comment: 'This is a comment', planId: 12, commentId: 27, dayId: 13 })
 * // throws an error
 *
 * updateComment({ comment: 'This is a comment', planId: 12, dayId: '' })
 */
export async function updateComment({
  comment,
  commentId,
  dayId,
  planId,
}: UpdateCommentParams) {
  let path = `/plans/${planId}/comments/${commentId}`

  if (dayId) {
    path = `/plans/${planId}/days/${dayId}/comments/${commentId}`
  }

  const response = await fetchClient<{ comment: Plan.Comment }>(path, {
    body: JSON.stringify({
      comment: {
        body_html: comment,
      },
    }),
    method: 'PATCH',
  })
  return response.parsedBody.comment
}

export const useEditComment = (props: {
  planId: NumberLike
  dayId?: NumberLike
}) =>
  useMutation((input: { commentId: NumberLike; comment: string }) =>
    updateComment({ ...props, ...input }),
  )

interface DeleteCommentParams {
  /** The plan's id. */
  planId: NumberLike
  /** The plan day's id. */
  dayId?: NumberLike
  /** The comment's id. */
  commentId: NumberLike
}

/**
 * Function to delete the plan or plan day comment.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The response from the deleted comment.
 *
 * @example
 * // delete plan comments
 * deleteComment({ commentId: 27, planId: 12 })
 *
 * // delete plan day comments
 *deleteComment({ commentId: 27, planId: 12, dayId: 13 })
 *
 * // throws an error
 * deleteComment({ dayId: '', planId: 12 })
 */

export async function deleteComment({
  commentId,
  dayId,
  planId,
}: DeleteCommentParams) {
  let path = `/plans/${planId}/comments/${commentId}`

  if (dayId) {
    path = `/plans/${planId}/days/${dayId}/comments/${commentId}`
  }

  return fetchClient(path, {
    method: 'DELETE',
  })
}

export const useDeleteComment = (props: {
  planId: NumberLike
  dayId?: NumberLike
}) =>
  useMutation((commentId: NumberLike) => deleteComment({ ...props, commentId }))
