/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsdoc/require-param */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module ImageField
 */

import { ReactElement, useCallback, useEffect, useState } from 'react'
import Dropzone, { FileRejection } from 'react-dropzone'
import { statusTypes } from '@youversion/utils'
import { PhotoAttachment } from 'components/ProfilePhotoAttachment'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ImageFieldProps {
  /** Class name for this component's styles. */
  className?: string
  /** The function to be run when the file is dropped. */
  onFileDrop: (
    acc: Array<File>,
    rej: Array<FileRejection>,
  ) => Promise<Attachment | undefined>
  /** The component to display until image is uploaded. */
  placeholder: ReactElement
  /** Update Plan Object with image. */
  onChange: (attachment: Attachment) => void
  /** Image. */
  image: Attachment | null
}

/**
 * Creates a ImageField component for forms.
 *
 * @returns {React.ReactElement|Node} - The ImageField Component.
 */
export function ImageField({
  className,
  onFileDrop,
  placeholder,
  onChange,
  image,
}: ImageFieldProps) {
  const [loadingStatus, setLoadingStatus] = useState(statusTypes.IDLE)
  const [imageFile, setImageFile] = useState<Attachment | null>(null)
  const { t } = useTranslation('plans')

  useEffect(() => {
    if (image) {
      setImageFile(image)
    }
  }, [])

  const onDrop = useCallback(
    async (acc: Array<File>, rej: Array<FileRejection>) => {
      if (!onFileDrop) {
        return
      }

      setLoadingStatus(statusTypes.PENDING)
      try {
        const attachment = await onFileDrop(acc, rej)
        if (attachment) {
          setImageFile(attachment)
          onChange(attachment)
        }
      } finally {
        setLoadingStatus(statusTypes.RESOLVED)
      }
    },
    [onFileDrop, onChange],
  )

  return (
    <>
      <Dropzone
        accept="image/jpeg,image/png,image/jpg"
        multiple={false}
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={className}
            data-testid="dropzone"
            style={{ position: 'relative' }}
          >
            <input {...getInputProps()} />
            <PhotoAttachment
              isLoading={loadingStatus === statusTypes.PENDING}
              placeholder={placeholder}
              url={imageFile ? imageFile.asset.original_url : ''}
            />
            {imageFile ? (
              <div
                style={{
                  position: 'absolute',
                  insetBlockStart: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0,0,0,.1)',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  paddingBlockEnd: 10,
                }}
              >
                <Button color="primary" variant="contained">
                  {t('edit_plan_info.change_image')}
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </Dropzone>
    </>
  )
}
