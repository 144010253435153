import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { statusTypes, titleCase } from '@youversion/utils'
import { useAlert } from '@youversion/react'
import AdminWrapperComponent from 'components/AdminWrapper/AdminWrapper'
import { useAuth } from 'auth'
import { useGetLanguages } from 'api/languages'
import { useCreatePlanTranslation } from 'api/plans'
import { API_STATUS, ERROR_RESPONSES_FOR_MULTI_LANGUAGE_PLANS } from 'helpers'
import MuiLoadingButton from 'components/Buttons/mui-loading-button'
import { usePlan } from 'context'
import { useTranslation } from 'react-i18next'
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage'
import { AddTranslationLanguageProps } from '../../types'

const useStyles = makeStyles(() => ({
  devotionText: {
    marginBlockStart: '8px',
    marginInlineEnd: '50px',
  },
  dialog: {
    width: '425px',
  },
  topTextField: {
    marginVertical: '20px',
  },
  planTitle: {
    marginBlockEnd: '16px',
    '& p': {
      marginInlineStart: '0',
    },
  },
  circularLoading: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    color: 'black',
    marginInlineStart: '55px',
    marginBlockStart: '5px',
  },
}))

const isJson = (e: Error) => {
  try {
    JSON.parse(e.message)
    return true
  } catch (err) {
    return false
  }
}

export default function AddTranslationModal({
  handleClose,
  isDialogOpen,
}: AddTranslationLanguageProps) {
  const classes = useStyles()
  const { user } = useAuth()
  const { t, i18n } = useTranslation('plans')
  const { throwAlert } = useAlert()
  const { plan } = usePlan()
  const { data: transferLanguages } = useGetLanguages()
  const { mutate, status: loadingStatus } = useCreatePlanTranslation()
  const [titleValue, setTitleValue] = React.useState('')
  const [languageValue, setLanguageValue] = React.useState('')
  const [translationSource, setTranslationSource] =
    React.useState('partner_portal')
  const isAdmin = user?.role === 'admin'
  const disabled = !titleValue || !languageValue

  function capitalizePlanTitle(event: React.ChangeEvent<HTMLInputElement>) {
    const newPlanName = event.target.value.trim()
    setTitleValue(!newPlanName ? '' : titleCase(event.target.value))
  }

  function handleCloseDialog() {
    setLanguageValue('')
    setTitleValue('')
    handleClose()
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTranslationSource(event.target.value)
  }

  async function submitTranslation() {
    if ('id' in plan) {
      mutate(
        {
          planId: plan.source_id ?? plan.id,
          languageId: languageValue,
          contentSource: translationSource,
          titleValue,
        },
        {
          onSuccess: (response) => {
            if (response) {
              handleCloseDialog()
              window.location.replace(`/plans/${response?.id}`)
            }
          },
          onError: (e) => {
            if (e instanceof Error) {
              if (isJson(e)) {
                const errors: Array<string> = JSON.parse(e.message)
                errors.forEach((error, ind) => {
                  throwAlert({
                    id: `add-translation-error-${ind}`,
                    key: `add-translation-error-${ind}`,
                    message: ERROR_RESPONSES_FOR_MULTI_LANGUAGE_PLANS(error),
                    timeout: 5000,
                    type: statusTypes.ERROR,
                  })
                })
              } else {
                throwAlert({
                  id: 'add-translation-error',
                  key: 'add-translation-error',
                  message: e.message,
                  timeout: 5000,
                  type: statusTypes.ERROR,
                })
              }
            }
          },
        },
      )
    }
  }

  return (
    <Dialog
      aria-describedby="dialog-wrapper"
      aria-labelledby="dialog-wrapper-label"
      classes={{ paper: classes.dialog }}
      maxWidth="sm"
      onClose={handleClose}
      open={isDialogOpen}
    >
      <DialogTitle id="dialog-wrapper-title">
        {t('plans:new_translation.add_a_translation')}
      </DialogTitle>
      <DialogContent id="dialog-wrapper-content">
        <Typography color="textSecondary" variant="subtitle1">
          {t('plans:new_translation.adding_translation_text')}
        </Typography>
        <TextField
          className={classes.topTextField}
          fullWidth={true}
          id="language"
          label={t('plans:col_header.language')}
          margin="normal"
          onChange={(event) => setLanguageValue(event.target.value)}
          placeholder={t('plans:col_header.language')}
          required={true}
          select={true}
          value={languageValue ?? ''}
          variant="outlined"
        >
          {transferLanguages
            ? Object.values(transferLanguages).map((language) => (
                <MenuItem key={language.code} value={language.id}>
                  {getLocalizedLanguage(language.code, i18n.language)}
                </MenuItem>
              ))
            : []}
        </TextField>
        <TextField
          className={classes.planTitle}
          fullWidth={true}
          helperText={t('plans:create_plan_title_helper')}
          id="planTitle"
          label={t('plans:label.plan_title')}
          margin="normal"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            capitalizePlanTitle(e)
          }
          placeholder={t('plans:label.plan_title')}
          required={true}
          type="text"
          value={titleValue ?? ''}
          variant="outlined"
        />
        {isAdmin && (
          <AdminWrapperComponent>
            <Typography
              className={classes.devotionText}
              color="textSecondary"
              variant="body1"
            >
              {t('plans:new_translation.translate_devotional_content')}
            </Typography>
            <Box ml={1} mt={1}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name={t('plans:new_translation.translate_devotional_content')}
                  onChange={handleChange}
                  value={translationSource}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={t('plans:new_translation.using_crowdin')}
                    value="crowdin"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={t('plans:new_translation.manually')}
                    value="partner_portal"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </AdminWrapperComponent>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} color="primary" onClick={handleCloseDialog}>
          {t('plans:day.cancel')}
        </Button>
        <Box mb={1} mr={2}>
          <MuiLoadingButton
            color="primary"
            disabled={loadingStatus === API_STATUS.LOADING || disabled}
            isLoading={loadingStatus === API_STATUS.LOADING}
            onClick={submitTranslation}
            variant="contained"
          >
            {t('plans:new_translation.add_translation')}
          </MuiLoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
