import { useQuery } from '@tanstack/react-query'
import { fetchClient } from '../../helpers/transport'
import { QUERY_KEYS } from '../../helpers'

interface Session {
  success: boolean
  errors: Array<{ key: string; message: string }>
}

export async function getSession(): Promise<Session | undefined> {
  try {
    const response = await fetchClient<Session>('/session')
    return response.parsedBody
  } catch (error) {
    const errorResponse: Response = error as Response
    return errorResponse.json()
  }
}

export const useGetSession = (userId?: number) =>
  useQuery([QUERY_KEYS.SESSION, userId], () => getSession(), {
    enabled: Boolean(userId),
  })
