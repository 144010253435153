import React, { FC } from 'react'
import classnames from 'classnames'
import makeStyles from '@mui/styles/makeStyles'
import type { ButtonProps } from '../types'

const useStyles = makeStyles({
  buttonCssReset: {
    backgroundColor: 'none',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
  },
})

export const LinkButton: FC<ButtonProps> = ({ className, ...props }) => {
  const classes = useStyles()
  return (
    <button
      className={classnames(classes.buttonCssReset, className)}
      {...props}
    />
  )
}
