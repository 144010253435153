/* eslint-disable jsdoc/require-param */
/**
 * @module getFileBlockProcessingErrors
 */
import {
  AttachmentFileType,
  GetFileProcessingStatusResponse,
  getFileProcessingStatus,
  isAttachmentFileType,
} from 'api/active-storage'
import { Plan } from 'components/Plans/types'

const blockTypesWithStatuses: Record<
  string,
  keyof Plan.DevotionalContentBlocksContent
> = {
  audio: 'postprocessing_url',
  video: 'youtube_video_id',
  yv_video: 'poster_url',
}

/**
 * Fetch additional data for an array of blocks.
 *
 * @alias module:getFileBlockProcessingErrors
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 * @returns {Promise<Array>} - The updated blocks array.
 */
export async function getFileBlockProcessingErrors(
  blocks: Plan.DevotionalContentBlocks[],
) {
  const blocksArray = [...blocks]

  const statusPromises = blocksArray.map(
    (block): Promise<GetFileProcessingStatusResponse | undefined> | null => {
      // Only request status info if the block's post-processed value is null.
      if (
        Object.keys(blockTypesWithStatuses).includes(block.type) &&
        block.content.file_id &&
        !block.content[blockTypesWithStatuses[block.type]]
      ) {
        let fileType: AttachmentFileType
        if (block.type === 'yv_video') {
          fileType = 'video'
        } else if (isAttachmentFileType(block.type)) {
          fileType = block.type
        } else {
          throw new Error(`Invalid block type: ${block.type}`)
        }

        return getFileProcessingStatus({
          fileId: block.content?.file_id,
          fileType,
        })
      }
      return null
    },
  )
  try {
    const statusResponses = await Promise.all(statusPromises)

    statusResponses.forEach((response, responseIdx) => {
      if (!response) {
        return
      }
      const currentBlock = blocksArray[responseIdx]

      // Grab the error for an audio block.
      if (
        currentBlock.type === 'audio' &&
        (response.service_status === 'failed' ||
          response.postprocessing_state === 'failed')
      ) {
        blocksArray[responseIdx].content.error = {
          disableRetry: true,
          heading: 'Failed to Process File',
          message:
            response.postprocessing_status_details ||
            'Audio file failed to process.',
        }
      }

      // Grab the error for a video block.
      if (
        currentBlock.type === 'video' &&
        response.service_status === 'failed'
      ) {
        blocksArray[responseIdx].content.error = {
          disableRetry: true,
          heading: 'Failed to Process File',
          message:
            response.youtube_status_details || 'Video file failed to process.',
        }
      }

      // Grab the error for a yv_video block.
      if (
        currentBlock.type === 'yv_video' &&
        response.service_status === 'failed'
      ) {
        blocksArray[responseIdx].content.error = {
          disableRetry: true,
          heading: 'Failed to Process File',
          message:
            response.zencoder_job_details?.input_media_file?.error_message ||
            'Video file failed to process.',
        }
      }
    })
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
  return blocksArray
}
