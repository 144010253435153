/**
 * @module TranslationMenuActions
 */
import React, { FC } from 'react'
import { Box, Chip, Divider, Fade, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CancelIcon from '@mui/icons-material/Cancel'
import AddIcon from '@mui/icons-material/Add'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import { useTranslation } from 'react-i18next'
import LinkIcon from '../LinkIcon'
import AddLanguageTranslation from './AddLanguageTranslation'
import LinkPlanLanguage from './LinkLanguage'
import { TranslationMenuActionsProps } from '../types'

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #2B3031',
    },
    color: '#2B3031',
  },
  tooltip: {
    backgroundColor: '#2B3031',
    border: '1px solid #2B3031',
    width: '160px',
  },
}))

const TranslationMenuActions: FC<TranslationMenuActionsProps> = ({
  handleToggleSort,
  disableNewLanguageButton = false,
  isSortedAlphabetically = true,
  isOrgPlanListGreaterThanOne,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('plans')
  const [addTranslation, setAddTranslation] = React.useState(false)
  const [openLinkPlanDialog, setOpenLinkPlanDialog] = React.useState(false)

  function handleAddTranslationOption() {
    setAddTranslation(true)
  }
  function handleLinkPlanLanguage() {
    setOpenLinkPlanDialog(true)
  }

  return (
    <>
      <Box pb={1} pt={2} px={1}>
        <Fade in={!isSortedAlphabetically}>
          <Box
            alignItems="center"
            display={isSortedAlphabetically ? 'none' : 'flex'}
            gap="8px"
          >
            <Tooltip
              arrow={true}
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title={
                disableNewLanguageButton
                  ? 'Plan translations can only be created after the original language has been submitted.'
                  : ''
              }
            >
              <span>
                <Chip
                  color="primary"
                  disabled={disableNewLanguageButton}
                  icon={<AddIcon />}
                  label={t('plans:new')}
                  onClick={handleAddTranslationOption}
                  size="small"
                />
              </span>
            </Tooltip>

            {!isOrgPlanListGreaterThanOne ? (
              <Chip
                color="secondary"
                icon={<LinkIcon />}
                label={t('plans:link')}
                onClick={handleLinkPlanLanguage}
                size="small"
              />
            ) : null}
            <Divider flexItem={true} orientation="vertical" />
            <Chip
              color="primary"
              icon={<SortByAlphaIcon />}
              label={t('plans:sort')}
              onClick={handleToggleSort}
              size="small"
              variant="outlined"
            />
          </Box>
        </Fade>
        <Fade in={isSortedAlphabetically}>
          <Box display={!isSortedAlphabetically ? 'none' : 'flex'}>
            <Chip
              color="primary"
              icon={<CancelIcon />}
              label={t('plans:unsort')}
              onClick={handleToggleSort}
              size="small"
            />
          </Box>
        </Fade>
      </Box>
      <AddLanguageTranslation
        handleClose={() => setAddTranslation(false)}
        isDialogOpen={addTranslation}
      />
      <LinkPlanLanguage
        handleClose={() => setOpenLinkPlanDialog(false)}
        isDialogOpen={openLinkPlanDialog}
      />
    </>
  )
}

export default TranslationMenuActions
