/* eslint-disable import/prefer-default-export */
/**
 * @module SystemAlert
 */
import React from 'react'
import { Alert, Box, Collapse } from '@mui/material'

const rightAboutNow = new Date()

export interface SystemAlertProps {
  /** Date to discontinue showing this alert. If this is `undefined`, the alert will always display, as long as it has not yet been dismissed. */
  endDate?: Date
  /** HTML string to render inside the Alert component. */
  html: string
  /** Key for local storage to store when alert message is dismissed. */
  localStorageKey: string
  /** Material UI variant for the Alert component. Defaults to 'info'. */
  muiAlertSeverity?: 'info' | 'error' | 'success' | 'warning'
  /** Date to begin showing this alert. */
  startDate: Date
}

/**
 * System Alert Component.
 *
 * A dismissible alert message to display within specified date parameters. When the alert is
 * dismissed, data is stored within local storage to prevent the alert from appearing again.
 *
 * @alias module:SystemAlert
 *
 * @returns {React.ReactElement} - The Alert Message component.
 */
export function SystemAlert({
  endDate,
  html,
  localStorageKey,
  muiAlertSeverity = 'info',
  startDate,
}: SystemAlertProps) {
  const [isClosed, setIsClosed] = React.useState(() =>
    Boolean(localStorage.getItem(localStorageKey)),
  )

  function handleAlertClose() {
    setIsClosed(true)
    localStorage.setItem(localStorageKey, JSON.stringify(true))
  }

  return (
    <Collapse
      in={Boolean(
        !isClosed &&
          rightAboutNow >= startDate &&
          (endDate ? rightAboutNow < endDate : !endDate),
      )}
    >
      <Box mt={2}>
        <Alert onClose={handleAlertClose} severity={muiAlertSeverity}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Alert>
      </Box>
    </Collapse>
  )
}
