import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
// "@types/i18next-browser-languagedetector": "2.2.4",
// @ts-ignore
import LanguageDetector from 'i18next-browser-languagedetector'

const userAppLang = localStorage.getItem('app_language') ?? navigator.language

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // to detect user language
  .use(LanguageDetector)

  // passes i18n down to react-i18next
  .use(initReactI18next)

  .init({
    fallbackLng: 'en-US',
    lng: userAppLang,
    ns: [
      'analytics',
      'common',
      'create_account',
      'menu',
      'plans',
      'users',
      'organizations',
      'plan_days',
      'login',
      'uncommon_languages',
    ],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
