/* eslint-disable jsdoc/require-param */
/**
 * @module CommentCloseAction
 */
import PropTypes from 'prop-types'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { grey } from '@mui/material/colors'
import { Close as CloseIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  iconButton: {
    '&:hover': {
      backgroundColor: grey['300'],
    },
    backgroundColor: grey['200'],
    padding: '5px',
  },
}))

interface CommentCloseActionProps {
  /** Handler function to switch the comment card state back to the default state. */
  onClose: VoidFunction
}

/**
 * Represents a Comment close action button.
 *
 * @returns {React.ReactElement} A Comment close action button component.
 */
export function CommentCloseAction({ onClose }: CommentCloseActionProps) {
  const classes = useStyles()
  const { t } = useTranslation(['common'])
  return (
    <Box mr={1}>
      <IconButton
        aria-label={t('common:close')}
        className={classes.iconButton}
        onClick={onClose}
        size="small"
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Box>
  )
}

CommentCloseAction.propTypes = {
  onClose: PropTypes.func.isRequired,
}
