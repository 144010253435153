/* eslint-disable jsdoc/require-param */
/**
 * @module organizationsApiMethods
 */
/* eslint-disable import/prefer-default-export */
import { Organization } from 'components/Organizations/types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { cleanParams } from 'helpers/remove-nulls'
import { QUERY_KEYS } from 'helpers'
import { fetchClient } from 'helpers/transport'
import { NumberLike } from 'types/misc.d'
import { FetchOrganizations } from './types'

export interface GetOrganizationsProps {
  pagination: Pagination
  sorter: Sorter
  filters: FetchOrganizations.Filter
}

export interface GetOrganizationsResponse {
  organizations: Array<Organization>
  meta: Meta
}

/**
 * Get organizations.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function getOrganizations({
  pagination,
  sorter,
  filters,
}: GetOrganizationsProps) {
  const removeAllNullableValues = cleanParams({
    per: pagination.per,
    page: pagination.page,
    ...sorter,
    ...filters,
  })
  const params = new URLSearchParams(removeAllNullableValues)
  const response = await fetchClient<GetOrganizationsResponse>(
    `/organizations?${params.toString()}`,
  )
  return response.parsedBody
}

export const useGetOrganizations = (query: GetOrganizationsProps) =>
  useQuery([QUERY_KEYS.ALL_ORGANIZATIONS, query], () => getOrganizations(query))

/**
 * Get an organization.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function getOrganization(orgId?: NumberLike) {
  try {
    const response = await fetchClient<{ organization: Organization }>(
      `/organizations/${orgId}`,
    )
    return response.parsedBody.organization
  } catch (error) {
    if (error instanceof Response) {
      throw new Error('OrganizationNotFound')
    }
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export const useGetOrganization = (orgId?: NumberLike) =>
  useQuery([QUERY_KEYS.ORGANIZATION, orgId], () => getOrganization(orgId), {
    enabled: Boolean(orgId),
  })

/**
 * Search an organization.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 * @returns {Promise<object>} - The API response.
 */
export async function searchOrganizations(name: string) {
  try {
    const searchParams = new URLSearchParams({
      name,
      order: 'asc',
      page: '1',
      per: '1000',
      sort: 'name',
    }).toString()
    const response = await fetchClient<{
      organizations: Array<Organization.Organization>
    }>(`/organizations?${searchParams}`)
    return response.parsedBody.organizations
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

interface UpdateOrganizationProps {
  orgId?: NumberLike
  name?: string
  information?: string
  website?: string
  address?: string
  phone?: string
  status?: string
  profile_photo_id?: NumberLike
  partnership_agreement_id?: NumberLike
  staff_representative_id?: NumberLike
}

export async function updateOrganization({
  orgId,
  ...props
}: UpdateOrganizationProps) {
  const response = await fetchClient<{ organization: Organization }>(
    `/organizations/${orgId}`,
    {
      body: JSON.stringify(props),
      method: 'PATCH',
    },
  )
  return response.parsedBody.organization
}

export const useUpdateOrganization = (orgId?: NumberLike) =>
  useMutation((values: Partial<UpdateOrganizationProps>) =>
    updateOrganization({
      orgId,
      ...values,
    }),
  )

interface OrganizationContentPartner {
  id: string
  name: string
  email: string
  primary_language: string
  license_status: 'pending' | 'rejected' | 'accepted'
  license_updated_by?: number
  license_type: 'universal'
  external_ids: Array<{ id: number; type: string }>
}

interface OrganizationContentPartnerResponse {
  data: Array<OrganizationContentPartner>
}

interface OrganizationContentPartnerArgs {
  organizationId: NumberLike
}

export async function getOrganizationContentPartners({
  organizationId,
}: OrganizationContentPartnerArgs): Promise<Array<OrganizationContentPartner>> {
  const response = await fetchClient<OrganizationContentPartnerResponse>(
    `/organizations/${organizationId}/content_partners`,
  )

  return response.parsedBody.data
}

export const useGetOrganizationContentPartners = ({
  organizationId,
}: OrganizationContentPartnerArgs) =>
  useQuery(
    [QUERY_KEYS.ORGANIZATION_CONTENT_PARTNERS, organizationId],
    () => getOrganizationContentPartners({ organizationId }),
    {
      enabled: Boolean(organizationId),
    },
  )
