/* eslint-disable jsdoc/require-param */
/**
 * @module LiveInAppAndSearch
 */
import { Dispatch, ReactElement, SetStateAction } from 'react'
import { FormControl, FormControlLabel, Radio } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface LiveInAppAndSearchProps {
  /** System status value. */
  value: boolean
  /** Function to change system status value. */
  onChange: Dispatch<SetStateAction<boolean>>
}

/**
 * Radio Component to change the system status value.
 *
 * @returns {ReactElement} - Component for system status configuration.
 */
export function LiveInAppAndSearch({
  value,
  onChange,
}: LiveInAppAndSearchProps) {
  const { t } = useTranslation('common')

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Radio
            checked={value}
            color="primary"
            name="live-in-app-and-search"
            onChange={() => onChange(true)}
          />
        }
        label={t('yes')}
      />
      <FormControlLabel
        control={
          <Radio
            checked={!value}
            color="primary"
            name="live-in-app-and-search"
            onChange={() => onChange(false)}
          />
        }
        label={t('no')}
      />
    </FormControl>
  )
}
