/* eslint-disable import/no-cycle */
/* eslint-disable jsdoc/require-param */
/**
 * @module Form
 */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Can, useAuth } from 'auth'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import scrollIntoView from 'scroll-into-view'
import { utilityColors, yvRed } from '@youversion/react/styles/colors-v3'
import {
  FILE_MAX_BYTE_SIZE,
  PLAN_DESCRIPTION_WORDS_LIMIT,
  QUERY_PARAMS,
  submissionStatuses,
} from 'helpers/constants'
import { grey } from '@mui/material/colors'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import { usePlan } from 'context'
import { useAlert } from '@youversion/react'
import { validateFileIsImage, validateImageDimensions } from 'helpers/edit-form'
import { FileRejection } from 'react-dropzone'
import { useCreateAttachment } from 'api/attachment'
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio'
import { isHttpsPrepended, prependHttps } from 'helpers/url-normalizer'
import { titleCase } from 'helpers/title-case'
import countWords from 'utils/validate-word-count'
import { useTranslation } from 'react-i18next'
import { ImageField } from './Fields/ImageField'
import { CategoryField } from './Fields/CategoryField'
import { KeywordField } from './Fields/KeywordsField'
import { LiveInAppAndSearch } from './Fields/LiveInAppAndSearch'
import { AlternateOrganizations } from './Fields/AlternateOrganizations'
import { NarratedAudioDialog } from './Fields/NarratedAudioDialog'
import { validateUrl } from '..'
import { Plan } from '../../types'
import LanguageField from './Fields/LanguageField'
import { DeletePlan } from './Fields/DeletePlan'

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      backgroundColor: utilityColors.light.info,
      color: 'white',
    },
    deleteButton: {
      '&:hover': {
        backgroundColor: yvRed,
      },
      backgroundColor: yvRed,
      color: '#fff',
    },
    formControlLabel: {
      alignItems: 'flex-start',
      marginBlockEnd: theme.spacing(1),
    },
    formControlLabelText: {
      marginBlockStart: 8,
    },
    photoLibraryIcon: {
      color: grey[500],
    },
    imageUpload: {
      height: '200px',
      width: '100%',
    },
    imgUploadPlaceholder: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
    },
    imageContainer: {
      height: '200px',
      borderRadius: 15,
      overflow: 'hidden',
    },
  }),
)

interface FormProps {
  /** The array of active categories. */
  activeCategories: Array<Plan.Category>
  /** An array of categories. */
  categories: Array<Plan.Category>
  /** An array of categoriesSections. */
  categorySections: Array<string>
  /** Function to invoke submission when save button is clicked. */
  onSubmit: () => void
  /** Function to update the active categories. */
  setActiveCategories: Dispatch<SetStateAction<Array<Plan.Category>>>
  /** Function to update the loading attachment stack. */
  setLoadingAttachmentStack: Dispatch<SetStateAction<Array<Date>>>
  /** Function to update form errors object. */
  setFormErrors: Dispatch<SetStateAction<Record<string, string>>>
  /** Tells when user wants narrated audio. */
  wantsNarratedAudio: boolean
  /** Function to update wantsNarratedAudio. */
  setWantsNarratedAudio: Dispatch<SetStateAction<boolean>>
  /** Toggle showing narrated dialog. */
  showNarratedAudioDialog: boolean
  /** Function to toggle showing narrated dialog. */
  setShowNarratedAudioDialog: Dispatch<SetStateAction<boolean>>
  /** Plan Object. */
  plan: Plan.Plan
  planLanguage: Language
  /** All form errors. */
  formErrors: Record<string, string>
  hasPlanBeenPublished: boolean
}

/**
 * The Plans Form component.
 *
 * @returns {React.ReactElement} - The Plans Form component.
 */
export function Form({
  activeCategories,
  categories,
  categorySections,
  onSubmit,
  setLoadingAttachmentStack,
  setFormErrors,
  setActiveCategories,
  wantsNarratedAudio,
  setWantsNarratedAudio,
  showNarratedAudioDialog,
  setShowNarratedAudioDialog,
  plan,
  planLanguage,
  formErrors,
  hasPlanBeenPublished,
}: FormProps) {
  const [autoCapitalizeTitle, setAutoCapitalizeTitle] = useState(false)
  const classes = useStyles()
  const { search, hash } = useLocation()
  const { updatePlanDetails } = usePlan()
  const { throwAlert } = useAlert()
  const { mutateAsync } = useCreateAttachment()
  const { user } = useAuth()
  const { t } = useTranslation('plans')

  const handleCheckAutoCapitalizeTitle = () => {
    setAutoCapitalizeTitle((prev: boolean) => !prev)
  }

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPlanName = event.target.value
    if (autoCapitalizeTitle || plan.language_id === 1) {
      if (!newPlanName) {
        return updatePlanDetails({ name: '' })
      }
      updatePlanDetails({ name: titleCase(newPlanName) })
    } else {
      updatePlanDetails({ name: newPlanName })
    }
  }

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0)
    } else {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [hash])

  useEffect(() => {
    if (plan.language_id !== 1) {
      return
    }
    if (plan.name) {
      updatePlanDetails({ name: titleCase(plan.name) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan.language_id])

  useEffect(() => {
    if (autoCapitalizeTitle) {
      if (!plan.name) {
        return
      }
      updatePlanDetails({ name: titleCase(plan.name) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCapitalizeTitle])
  function createImgDropHandler(width: number, height: number) {
    // Params from react-dropzone: acceptedFile, rejectedFile.
    return async (
      acceptedFile: Array<File>,
      rejectedFile: Array<FileRejection>,
    ) => {
      if (!('name' in plan)) return
      // Validate file is an image and that the image is the correct dimensions.
      try {
        const { imageErrorMsg, isValid } = validateFileIsImage({
          acceptedFile,
          rejectedFile,
        })
        if (imageErrorMsg && !isValid) {
          throw new Error(imageErrorMsg)
        }

        const file = acceptedFile[0]

        await validateImageDimensions({
          height,
          imageUrl: URL.createObjectURL(file),
          width,
        })

        if (file.size > FILE_MAX_BYTE_SIZE) {
          throw new Error(t('edit_plan_info.image_size_error'))
        }

        // Now, upload the image.
        setLoadingAttachmentStack((prevState) => prevState.concat(new Date()))
        const attachment = await mutateAsync({
          file,
          orgId: plan.primary_organization_id,
        })
        throwAlert({
          id: 'upload_image_success',
          key: 'upload_image_success',
          message: t('edit_plan_info.image_upload_success'),
          timeout: 3000,
          type: 'success',
        })

        return attachment
      } catch (error) {
        if (error instanceof Error) {
          throwAlert({
            id: 'upload_image_error',
            key: 'upload_image_error',
            message: t('edit_plan_info.image_upload_error', {
              error: error.message,
            }),
            timeout: 3000,
            type: 'error',
          })
        }
      } finally {
        setLoadingAttachmentStack((prevState) => prevState.slice(0, -1))
      }
    }
  }

  // If query param exists for upload_audio, it is from a link such as used on
  // the EditDay page, where there is intention to highlight and show user where
  // to upload their audio.
  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    if (
      queryParams.has(QUERY_PARAMS.uploadAudio) &&
      queryParams.get(QUERY_PARAMS.uploadAudio)
    ) {
      const narratedAudio = document.getElementById('narrated-audio')
      if (narratedAudio) {
        scrollIntoView(narratedAudio, {
          align: { top: 0.1 },
        })
      }
    }
  }, [search])

  const validatePlanDescription = () => {
    const isDescriptionValidated = countWords(
      plan.description,
      PLAN_DESCRIPTION_WORDS_LIMIT,
    )
    if (isDescriptionValidated) {
      const errorMessage = t('plans:plan_description_validation', {
        limit: PLAN_DESCRIPTION_WORDS_LIMIT,
      })
      setFormErrors((prev) => ({
        ...prev,
        description: errorMessage,
      }))
      return throwAlert({
        id: 'plan-description-error',
        key: 'plan-description-error',
        message: errorMessage,
        timeout: 5000,
        type: 'error',
      })
    }
    setFormErrors((prev) => ({
      ...prev,
      description: '',
    }))
  }

  return (
    <form>
      <Box>
        <Grid container={true} spacing={1}>
          <Grid
            data-testid="smallImg-attachment-container"
            item={true}
            lg={4}
            md={8}
            sm={5}
            xl={4}
            xs={8}
          >
            <Box className={classes.imageContainer} id="add-small-plan-image">
              <ImageField
                className={classes.imageUpload}
                image={plan.small_image}
                onChange={(image: Attachment) =>
                  updatePlanDetails({
                    small_image: image,
                  })
                }
                onFileDrop={createImgDropHandler(320, 320)}
                placeholder={
                  <div className={classes.imgUploadPlaceholder}>
                    <div>
                      <PhotoLibraryIcon
                        className={classes.photoLibraryIcon}
                        fontSize="large"
                      />
                    </div>
                    <Box mt={2}>
                      <Button color="primary" variant="contained">
                        {t('edit_plan_info.upload_image')}
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <Typography color="textSecondary">
                        {t('edit_plan_info.upload_image_small')}
                      </Typography>
                    </Box>
                  </div>
                }
              />
            </Box>
          </Grid>
          <Grid
            data-testid="largeImg-attachment-container"
            item={true}
            lg={8}
            md={12}
            sm={12}
            xl={8}
            xs={12}
          >
            <div className={classes.imageContainer} id="add-large-plan-image">
              <ImageField
                className={classes.imageUpload}
                image={plan.large_image}
                onChange={(image: Attachment) => {
                  updatePlanDetails({
                    large_image: image,
                  })
                }}
                onFileDrop={createImgDropHandler(1440, 810)}
                placeholder={
                  <div className={classes.imgUploadPlaceholder}>
                    <div>
                      <PhotoLibraryIcon
                        className={classes.photoLibraryIcon}
                        fontSize="large"
                      />
                    </div>
                    <Box mt={2}>
                      <Button color="primary" variant="contained">
                        {t('edit_plan_info.upload_image')}
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <Typography color="textSecondary">
                        {t('edit_plan_info.upload_image_large')}
                      </Typography>
                    </Box>
                  </div>
                }
              />
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box mt={2}>
        <LanguageField
          disabled={user ? !user.can('administrate:all_permissions') : false}
          planLanguageId={plan.language_id}
          updatePlanDetails={updatePlanDetails}
        />
      </Box>
      <Box mt={2}>
        <TextField
          fullWidth={true}
          id="name"
          name="name"
          onChange={handleChangeTitle}
          placeholder={t('edit_plan_info.plan_name')}
          type="text"
          value={plan?.name ?? ''}
          variant="outlined"
        />

        {plan.language_id !== 1 && (
          <FormControlLabel
            control={
              <Checkbox
                checked={autoCapitalizeTitle}
                color="primary"
                name="autoCapitalizeTitle"
                onChange={handleCheckAutoCapitalizeTitle}
              />
            }
            label={t('edit_plan_info.auto_capitalize')}
          />
        )}
      </Box>
      <Box id="add-a-description" mt={2}>
        <TextField
          error={Boolean(formErrors.description)}
          fullWidth={true}
          helperText={
            formErrors.description ? formErrors.description : undefined
          }
          label={'Description'}
          minRows={5}
          multiline={true}
          name="description"
          onBlur={() => {
            validatePlanDescription()
          }}
          onChange={(event) =>
            updatePlanDetails({ description: event.target.value })
          }
          placeholder={t('edit_plan_info.description')}
          type="text"
          value={plan?.description ?? ''}
          variant="outlined"
        />
      </Box>

      <Box mt={3}>
        <NarratedAudioDialog
          disabled={
            plan?.narrated_audio_feature?.submission_status ===
              submissionStatuses.SUBMITTED && Boolean(plan?.submitted_at)
          }
          handleSelection={(isSelected) => setWantsNarratedAudio(isSelected)}
          handleSubmit={onSubmit}
          plan={plan}
          planHasNarratedAudio={planHasNarratedAudio(plan)}
          planLanguage={planLanguage}
          setShowNarratedAudioDialog={setShowNarratedAudioDialog}
          showNarratedAudioDialog={showNarratedAudioDialog}
          value={wantsNarratedAudio}
        />
      </Box>

      <Box mt={4}>
        <Typography variant="h2">
          {t('edit_plan_info.additional_info')}
        </Typography>
      </Box>

      <Box mt={2}>
        <Typography id="add-categories" variant="h3">
          {t('edit_plan_info.categories')}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {t('edit_plan_info.select_categories')}
        </Typography>
        <CategoryField
          activeCategories={activeCategories}
          categories={categories}
          sections={categorySections}
          setActiveCategories={(selectedCategories) => {
            updatePlanDetails({
              categories: selectedCategories.map((category) => category.name),
            })
            setActiveCategories(selectedCategories)
          }}
        />
      </Box>

      <Box mt={2}>
        <Typography id="add-keywords" variant="h3">
          {t('edit_plan_info.keywords')}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {t('edit_plan_info.keyword_info')}
        </Typography>
        <KeywordField plan={plan} updatePlanDetails={updatePlanDetails} />
      </Box>

      <Box mt={4}>
        <Typography variant="h2">{t('edit_plan_info.attribution')}</Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="h3">{t('edit_plan_info.multi_org')}</Typography>
        <Typography color="textSecondary" variant="caption">
          {t('edit_plan_info.multi_org_info')}
        </Typography>
        <AlternateOrganizations
          onChange={(val) =>
            updatePlanDetails({ alternate_organizations: val })
          }
          value={plan.alternate_organizations}
        />
      </Box>

      <Box mt={2}>
        <Typography id="add-publisher-website" variant="h3">
          {t('edit_plan_info.about_publisher')}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {t('edit_plan_info.about_publisher_info')}
        </Typography>
        <TextField
          error={
            Boolean(formErrors.partner_url) && !validateUrl(plan?.partner_url)
          }
          fullWidth={true}
          helperText={
            Boolean(formErrors.partner_url) && !validateUrl(plan?.partner_url)
              ? formErrors.partner_url
              : undefined
          }
          id="partner_url"
          name="partner_url"
          onBlur={() => {
            const url = plan?.partner_url ?? ''
            if (!isHttpsPrepended(url)) {
              updatePlanDetails({ partner_url: prependHttps(url) })
            }
          }}
          onChange={(event) =>
            updatePlanDetails({ partner_url: event.target.value })
          }
          placeholder="https://example.com"
          type="text"
          value={plan?.partner_url ?? ''}
          variant="outlined"
        />
      </Box>

      {!hasPlanBeenPublished ? <DeletePlan planId={plan.id} /> : null}

      <Can user="plans:administrate_all">
        <Box mt={4}>
          <Typography variant="h2">{t('edit_plan_info.admin')}</Typography>
          <Typography color="textSecondary" variant="caption">
            {t('edit_plan_info.admin_info')}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="h3">
            {t('edit_plan_info.partner_attribution')}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {t('edit_plan_info.attribution_info')}
          </Typography>
          <TextField
            fullWidth={true}
            id="attribution_text"
            name="attribution_text"
            onChange={(event) =>
              updatePlanDetails({ attribution_text: event.target.value })
            }
            placeholder={t('edit_plan_info.attribution_text')}
            type="text"
            value={plan.attribution_text ?? ''}
            variant="outlined"
          />
        </Box>

        <Box mt={2}>
          <Typography id="slug-section" variant="h3">
            {t('edit_plan_info.plan_slug')}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {t('edit_plan_info.slug_info')}
          </Typography>
          <TextField
            fullWidth={true}
            id="title_slug"
            name="title_slug"
            onChange={(event) =>
              updatePlanDetails({ title_slug: event.target.value })
            }
            placeholder={t('edit_plan_info.title_slug')}
            type="text"
            value={plan?.title_slug ?? ''}
            variant="outlined"
          />
        </Box>

        <Box mt={2}>
          <Box>
            <Typography variant="h3">
              {t('edit_plan_info.live_search')}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {t('edit_plan_info.live_search_info')}
            </Typography>
          </Box>
          <LiveInAppAndSearch
            onChange={() =>
              updatePlanDetails({ system_status: !plan.system_status })
            }
            value={plan.system_status}
          />
        </Box>
      </Can>
    </form>
  )
}
