import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { GetOrganizationsResponse } from 'api/organizations'
import { ORGS_PER_PAGE } from 'helpers'
import { ErrorOutline } from '@mui/icons-material'
import { green, white, yellow, yvRed } from '@youversion/react/styles/colors-v3'
import { OrganizationMembershipsTableHead } from './TableHead'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  tableContainer: {
    maxHeight: '70vh',
  },
  table: {
    minWidth: 750,
  },
  paginationContainer: {
    borderBlockStart: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  statusChipApproved: {
    backgroundColor: green[30],
    color: white,
  },
  statusChipPending: {
    backgroundColor: yellow[30],
    color: white,
  },
  statusChipRejected: {
    backgroundColor: yvRed[30],
    color: white,
  },
}))

interface Props {
  data?: GetOrganizationsResponse
  page: number
  error?: Error
  refetch: VoidFunction
  isLoading: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
}

export function OrganizationMembershipDataGrid({
  error,
  refetch,
  isLoading,
  data,
  page,
  setPage,
}: Props) {
  const classes = useStyles()

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1)
  }

  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table
          aria-label="organizations table"
          aria-labelledby="organizations"
          className={classes.table}
          size="medium"
          stickyHeader={true}
        >
          <OrganizationMembershipsTableHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={5}
                  style={{
                    height: '50vh',
                    border: 0,
                  }}
                >
                  <CircularProgress data-testid="loading organizations" />
                </TableCell>
              </TableRow>
            ) : null}

            {error ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={5}
                  style={{
                    height: '50vh',
                    border: 0,
                  }}
                >
                  <ErrorOutline color="error" fontSize="large" />
                  <Typography>{error.message}</Typography>
                  <Box mt={2}>
                    <Button onClick={refetch} size="medium" variant="outlined">
                      Retry
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}

            {data && !data?.organizations?.length ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={5}
                  style={{
                    height: '50vh',
                    border: 0,
                  }}
                >
                  <ErrorOutline color="disabled" fontSize="large" />
                  <Typography>Organizations not found</Typography>
                  <Box mt={2}>
                    <Button onClick={refetch} size="medium" variant="outlined">
                      Retry
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}

            {data?.organizations.map((row) => {
              return (
                <TableRow
                  hover={true}
                  key={row.id}
                  style={{ textDecoration: 'none' }}
                >
                  <TableCell
                    align="right"
                    component="th"
                    scope="row"
                    width="10%"
                  >
                    <Avatar
                      alt={row.name}
                      src={row?.profile_photo?.asset?.original_url}
                      variant="rounded"
                    >
                      {row.name?.[0]}
                    </Avatar>
                  </TableCell>
                  <TableCell width="70%">{row.name}</TableCell>
                  <TableCell width="20%">
                    <Button
                      component={Link}
                      to={`/organizations/${row.id}`}
                      variant="outlined"
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.paginationContainer}
        component="div"
        count={data?.meta.total_count ?? 0}
        onPageChange={handleChangePage}
        page={page - 1}
        rowsPerPage={ORGS_PER_PAGE}
        rowsPerPageOptions={[50]}
      />
    </div>
  )
}
