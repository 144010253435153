import React, { FC } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Theme,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import WarningIcon from '@mui/icons-material/Warning'
import { yellow } from '@youversion/react/styles/colors-v3'
import classNames from 'classnames'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    accordionDetailEntry: {
      '&:first-child': {
        marginBlockStart: theme.spacing(2),
      },
      '&:last-child': {
        borderBlockEnd: 'none',
        marginBlockEnd: 0,
        paddingBlockEnd: 0,
      },
      borderBlockEnd: `1px solid ${yellow[40]}`,
      marginBlockEnd: theme.spacing(2),
      paddingBlockEnd: theme.spacing(2),
    },
    accordionDetails: {
      '& a': {
        color: yellow[40],
      },
      backgroundColor: yellow[10],
      border: `1px solid ${yellow[10]}`,
      color: yellow[40],
      flexDirection: 'column',
    },
    accordionSummary: {
      '& .MuiAccordionSummary-expandIcon': {
        color: yellow[40],
      },
      backgroundColor: yellow[10],
      border: `1px solid ${yellow[10]}`,
      borderRadius: theme.spacing(2),
      color: yellow[40],
    },
    detailsHidden: {
      borderEndStartRadius: 'unset',
      borderEndEndRadius: 'unset',
      borderBlockStartWidth: 'unset',
    },
    detailsVisible: {
      borderEndStartRadius: theme.spacing(2),
      borderEndEndRadius: theme.spacing(2),
      borderBlockStartWidth: 0,
    },
    summaryAccordionClosed: {
      borderEndStartRadius: theme.spacing(2),
      borderEndEndRadius: theme.spacing(2),
      borderBlockEndWidth: 'unset',
    },
    summaryAccordionOpen: {
      borderEndStartRadius: 0,
      borderEndEndRadius: 0,
      borderBlockEndWidth: 0,
    },
    warningIcon: {
      fontSize: 'inherit',
      verticalAlign: 'middle',
    },
  }),
)

interface Props {
  /*
   *  The title value to display in the Accordion summary.
   */
  title: string
  /*
   *  Array of warnings to display.
   */
  warnings: Array<string>
}

/**
 * Represents an Accordion component used to display Plan export warnings.
 */
const ExportPlanWarningAccordion: FC<Props> = ({ title, warnings }) => {
  const [openErrorAccordion, setOpenErrorAccordion] = React.useState(false)
  const classes = useStyles()
  const warningDetailsContent = warnings.length
    ? warnings.map((warning, index) => (
        <div
          className={classes.accordionDetailEntry}
          dangerouslySetInnerHTML={{
            __html: `${index + 1}. ${warning}`,
          }}
          key={`warning-message-${index.toString()}`}
        />
      ))
    : null

  return (
    <Box alignItems="center" mb={2} mt={2}>
      <Accordion
        expanded={openErrorAccordion}
        onChange={() =>
          warnings.length
            ? setOpenErrorAccordion((prevBool) => !prevBool)
            : null
        }
      >
        <AccordionSummary
          aria-controls="plan_publishable-preflight-errors-panel-content"
          className={classNames(classes.accordionSummary, {
            [`${classes.summaryAccordionOpen}`]: openErrorAccordion,
            [`${classes.summaryAccordionClosed}`]: !openErrorAccordion,
          })}
          expandIcon={warnings.length ? <ExpandMoreIcon /> : null}
          id="plan_publishable-preflight-errors-panel-content"
        >
          <Box height={24} mr={1}>
            <WarningIcon className={classes.warningIcon} />
          </Box>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classNames(classes.accordionDetails, {
            [`${classes.detailsVisible}`]: openErrorAccordion,
            [`${classes.detailsHidden}`]: !openErrorAccordion,
          })}
          id="plan_publishable-preflight-errors-panel-content"
        >
          {warningDetailsContent}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default ExportPlanWarningAccordion
