/**
 * @module MenuLayout
 */
import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Container,
  Fade,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import partnerPortalLogo from 'assets/yv-pp-logo.svg'
import { Sidebar, SystemAlert } from 'components'
import { SystemAlertProps } from 'components/SystemAlert'

// TODO we want this to be false, once all the components are responsive.
const SHOW_MOBILE_VIEW = true

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBlockEnd: 0,
      // Having an empty string staisfies the Typescript requirements and allows it to default to existing
      // MUI container size.
      maxWidth: SHOW_MOBILE_VIEW ? undefined : theme.breakpoints.values.md,
      // This min-width is temporary. Why? Partner Portal is not yet ready as a responsive site, so
      // this width will make it scrollable (vertically and horizontally) on mobile view, just as it
      // was previously. In the future, when we build out containers and components in Material UI,
      // then we can remove this and allow Partner Portal to be responsive and mobile friendly :).
      minWidth: SHOW_MOBILE_VIEW ? undefined : theme.breakpoints.values.md,
      transition: theme.transitions.create('margin-block-start', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.sharp,
      }),
    },
    menuLayout: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      },
    },
    stickyMobileHeader: {
      insetInlineStart: theme.spacing(0.5),
      insetBlockStart: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.palette.common.white,
        position: 'sticky',
        insetBlockStart: 0,
        zIndex: 99,
        paddingBlockStart: '12px',
      },
    },
    logoWrapper: {
      marginInlineEnd: theme.spacing(1),
    },
  }),
)

// TODO: Manage these messages as a feature: https://lifechurch.atlassian.net/browse/PP-555
const alertMessages: Array<SystemAlertProps> = [
  {
    endDate: new Date(2022, 4, 21),
    html: '<span><strong>Commenting on plans:</strong> You can now <strong>edit</strong> and <strong>delete</strong> comments left throughout your Plans.</span>',
    localStorageKey: 'pp-alert-editable-comments',
    muiAlertSeverity: 'info',
    startDate: new Date(2022, 1, 21),
  },
  {
    endDate: new Date(2022, 5, 1),
    html: '<span><strong>New Editor Updates!</strong> Pasting plan day text into the editor will keep your formatting, such as <strong>bold</strong>, <span style="text-decoration: underline;">underlined</span>, and <em>italicized</em> text.</span>',
    localStorageKey: 'pp-alert-block-editor-text-ux-updates',
    muiAlertSeverity: 'info',
    startDate: new Date(2021, 11, 1),
  },
  {
    endDate: new Date(2022, 2, 16),
    html: 'Partner Portal now has a new plan editor! More details here - <a href="https://partners.youversion.com/help-articles/how-to-add-a-plan-partner-portal" target="_blank" rel="noopener">https://partners.youversion.com/help-articles/how-to-add-a-plan-partner-portal</a>.',
    localStorageKey: 'pp-alert-plan-editor-closed',
    muiAlertSeverity: 'info',
    startDate: new Date(2021, 2, 1),
  },
  // This alert message below will only appear within Cypress test since it's now in the past.
  {
    endDate: new Date(2021, 1, 2),
    html: 'Welcome to Partner Portal!',
    localStorageKey: 'pp-alert-welcome-closed',
    muiAlertSeverity: 'info',
    startDate: new Date(2021, 1, 1),
  },
]

export interface Props {
  /** The component rendered in the main body container. */
  children: React.ReactElement
}

// eslint-disable-next-line jsdoc/require-jsdoc
export default function MenuLayout({ children }: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('lg'))

  const [openSidebar, setOpenSidebar] = React.useState(() => true)

  // Sidebar is open by default on Desktop and closed by default on mobile or tablet.
  React.useEffect(() => {
    setOpenSidebar(!isMobileView)
  }, [isMobileView])

  function handleCloseMobileSidebar() {
    if (isMobileView) {
      setOpenSidebar(false)
    }
  }

  function toggleSidebar() {
    setOpenSidebar((prevBool) => !prevBool)
  }

  return (
    <div className={classes.menuLayout} data-testid="menu-layout-container">
      <Sidebar
        handleCloseMobileSidebar={handleCloseMobileSidebar}
        isMobileView={isMobileView}
        open={openSidebar}
      />

      <Box
        className={classes.stickyMobileHeader}
        display="flex"
        position="absolute"
      >
        <IconButton
          aria-label="toggle navigation menu"
          onClick={toggleSidebar}
          size="large"
        >
          {openSidebar ? (
            <MenuOpenIcon data-testid="menu-open-icon" />
          ) : (
            <MenuIcon data-testid="menu-icon" />
          )}
        </IconButton>
        <Fade in={!openSidebar}>
          <Box alignItems="center" display="flex">
            <Box className={classes.logoWrapper} height={32}>
              <Link to="/plans">
                <img
                  alt="YouVersion Partner Portal Logo"
                  height="40px"
                  src={partnerPortalLogo}
                />
              </Link>
            </Box>
          </Box>
        </Fade>
      </Box>
      <Container
        className={classes.container}
        component="section"
        maxWidth="md"
      >
        {alertMessages.map((alert) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <SystemAlert key={alert.localStorageKey} {...alert} />
        ))}
        <Box height={{ sm: '0', md: '64px', lg: '0' }} />
        {children}
      </Container>
    </div>
  )
}
