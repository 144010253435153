/**
 * @module PersistReducer
 */

// For now, this isn't actually a reducer file; it's being used to organize
// localStorage side effects.
import store2 from 'store2'
import { activeOrgIdSelector, tokenSelector } from 'state/selectors'
import { KEY_ORGID, KEY_TOKEN, LOAD_ORGID, LOAD_TOKEN } from './constants'

export function persistToken() {
  return function persistToken(dispatch, getState) {
    const token = tokenSelector(getState())

    try {
      token ? store2.set(KEY_TOKEN, token) : store2.remove(KEY_TOKEN)
    } catch (e) {
      // Ignore errors
    }
  }
}

/*
  Initialization function to load the users auth token from localstorage
  if present.

  Currently fired just before the React app mounts.
*/

export function loadToken() {
  let token = null
  try {
    token = store2.get(KEY_TOKEN)
  } catch (e) {
    // Ignore errors
  }

  return {
    type: LOAD_TOKEN,
    payload: token,
  }
}

/*
  Persists a selected Organization (or All Orgs) to localstorage so that
  the next time the user arrives to the application, the organization
  context for the user is pulled from localstorage.
*/
export function persistActiveOrgId() {
  return function persistToken(dispatch, getState) {
    const activeOrgId = activeOrgIdSelector(getState())
    const { viewingAllOrganizations } = getState().organizations

    const orgId = viewingAllOrganizations ? 'ALL_ORGS' : activeOrgId

    try {
      orgId ? store2.set(KEY_ORGID, orgId) : store2.remove(KEY_ORGID)
    } catch (e) {
      // Ignore errors
    }
  }
}

/*
  Initialization function to load an Organization id from localstorage
  if present.

  Currently fired just before the React app mounts.
*/

export function loadOrgId() {
  return function loadOrgId(dispatch) {
    let orgId = null
    try {
      orgId = store2.get(KEY_ORGID)
    } catch (e) {
      // Ignore errors
    }

    return dispatch({ type: LOAD_ORGID, payload: orgId })
  }
}
