/* eslint-disable no-nested-ternary */
import { createFactory, useState } from 'react'
import { GetOrganizationsResponse } from 'api/organizations'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Table from 'components/misc/Table'
import { ORGS_PER_PAGE, orgStatuses, sorterFor } from 'helpers'
import basicPicture from 'assets/basic_picture.png'
import { Can } from 'auth'
import ImageLoader from 'react-imageloader'
import { Chip, CircularProgress } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { green, white, yellow, yvRed } from '@youversion/react/styles/colors-v3'
import PaginationBar from 'components/Plans/PaginationBar' // TODO: relocate component to better shared path
import { useTranslation } from 'react-i18next'
import styles from '../Organizations.module.scss'
import { ApproveOrganization } from '../ApproveOrganization'
import { RejectOrganization } from '../RejectOrganization'

interface OrganizationDataGridProps {
  /** The plans data. */
  data?: GetOrganizationsResponse
  /** Active organization id. Could be ALL_ORGS if no org is active. */
  activeOrganizationId: string
  /** Indicates if the active organization is "All Organizations". */
  isViewingAllOrgs: boolean
  /** Switches active organization. */
  switchActiveOrg: (id: number) => void
  /** Switches active organization to all organizations. */
  viewAllOrgs: VoidFunction
  /** Refetches organization data. */
  refetch: VoidFunction
}

const useStyles = makeStyles(() =>
  createStyles({
    statusChipApproved: {
      backgroundColor: green[30],
      color: white,
    },
    statusChipPending: {
      backgroundColor: yellow[30],
      color: white,
    },
    statusChipRejected: {
      backgroundColor: yvRed[30],
      color: white,
    },
    width55Cell: {
      width: '55%',
    },
    width15Cell: {
      width: '15%',
    },
  }),
)

// TODO: Change this to MUI's datagrid component
export function OrganizationDataGrid({
  isViewingAllOrgs,
  data,
  activeOrganizationId,
  switchActiveOrg,
  viewAllOrgs,
  refetch,
}: OrganizationDataGridProps) {
  const { t } = useTranslation(['organizations', 'common'])
  const classes = useStyles({})
  const navigate = useNavigate()
  const [query, setQuery] = useSearchParams()
  const [showApproveOrganizationModal, setShowApproveOrganizationModal] =
    useState(false)
  const [showRejectOrganizationModal, setShowRejectOrganizationModal] =
    useState(false)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    number | undefined
  >()

  function handlePageSelect(selectedPage: number) {
    const newPage = selectedPage + 1
    query.set('page', newPage.toString())
    setQuery(query)
  }

  function handleSortColumn(column: string) {
    if (query.get('sort') === column) {
      if (query.get('order') === 'desc') {
        query.set('order', 'asc')
      } else {
        query.set('order', 'desc')
      }
    } else {
      query.set('order', 'desc')
    }

    query.set('sort', column)
    query.set('page', '1')
    setQuery(query)
  }

  function handleRejectOrg(org: Organization) {
    setSelectedOrganizationId(org.id)
    setShowRejectOrganizationModal(true)
  }

  function handleApproveOrg(org: Organization) {
    setSelectedOrganizationId(org.id)
    setShowApproveOrganizationModal(true)
  }

  const viewAllOrgsItem = (
    <tr className={isViewingAllOrgs ? 'activeRow' : undefined}>
      <td>
        <div className={styles.listImageWrapper}>{/* Empty Placeholder */}</div>
        <strong>{t('organizations:view_all_organizations')}</strong>
      </td>

      <td />

      <td />

      <td>
        <div>
          <button
            className={styles.btnPillSecondary}
            data-testid="buttonViewAllOrganizations"
            disabled={isViewingAllOrgs}
            onClick={() => viewAllOrgs()}
            style={{ margin: 0 }}
            type="button"
          >
            {t('organizations:switch')}
          </button>
        </div>
      </td>
    </tr>
  )

  const sortData = {
    sort: query.get('sort') ?? undefined,
    order: query.get('order') ?? undefined,
  }

  return (
    <>
      <div>
        <Table data-testid="tableOrganizationList">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th
                className={`${styles.sortableHeading} ${classes.width55Cell}`}
                onClick={() => handleSortColumn('name')}
              >
                {t('organizations:table.header.name')}{' '}
                {sorterFor('name', sortData)}
              </th>
              <th
                className={`${styles.sortableHeading} ${classes.width15Cell}`}
                onClick={() => handleSortColumn('status')}
              >
                {t('organizations:table.header.status')}{' '}
                {sorterFor('status', sortData)}
              </th>
              <th className={classes.width15Cell}>
                {t('organizations:table.header.members')}
              </th>
              <th className={classes.width15Cell}>
                {t('organizations:table.header.actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            <Can user="read_all:organization">{viewAllOrgsItem}</Can>

            {data &&
              data.organizations.map((org) => {
                const isPending = org.status === 'pending'
                const orgImageUrl = org.profile_photo
                  ? org.profile_photo.source_url
                  : basicPicture

                const isOrgActive =
                  org.id === parseInt(activeOrganizationId, 10) &&
                  !isViewingAllOrgs

                return (
                  <tr
                    className={isOrgActive ? 'activeRow' : undefined}
                    key={org.id}
                  >
                    <td className={classes.width55Cell}>
                      <div className={styles.listImageWrapper}>
                        <ImageLoader
                          preloader={() => <CircularProgress />}
                          src={orgImageUrl}
                          wrapper={createFactory('div')}
                        >
                          {t('common:image_load_failed')}
                        </ImageLoader>
                      </div>
                      <strong>{org.name}</strong>
                    </td>

                    <td className={classes.width15Cell}>
                      <Chip
                        className={
                          org.status === orgStatuses.PENDING
                            ? classes.statusChipPending
                            : org.status === orgStatuses.APPROVED
                            ? classes.statusChipApproved
                            : classes.statusChipRejected
                        }
                        color="secondary"
                        label={t(`organizations:status.${org.status}`)}
                      />
                    </td>

                    <td className={classes.width15Cell}>
                      {org.memberships_count}
                    </td>

                    <td className={classes.width15Cell}>
                      <div>
                        <button
                          className={styles.btnPillSecondary}
                          disabled={isOrgActive}
                          onClick={() => switchActiveOrg(org.id)}
                          style={{ margin: 0 }}
                          type="button"
                        >
                          {t('organizations:switch')}
                        </button>
                        <button
                          className={styles.btnPill}
                          onClick={(event) => {
                            event.preventDefault()
                            navigate(`/organizations/${org.id}`)
                          }}
                          style={{ marginInlineStart: 10 }}
                          type="button"
                        >
                          {t('organizations:table.actions.view')}
                        </button>
                      </div>
                      {isPending && (
                        <div style={{ marginBlockStart: 10 }}>
                          <button
                            className={styles.btnPillAccept}
                            onClick={(event) => {
                              event.preventDefault()
                              handleApproveOrg(org)
                            }}
                            style={{ margin: 0 }}
                            type="button"
                          >
                            {t('organizations:table.actions.approve')}
                          </button>
                          <button
                            className={styles.btnPillReject}
                            onClick={(event) => {
                              event.preventDefault()
                              handleRejectOrg(org)
                            }}
                            style={{ marginInlineStart: 10 }}
                            type="button"
                          >
                            {t('organizations:table.actions.reject')}
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>

        {data ? (
          <PaginationBar
            itemCount={data.organizations.length}
            onPageChange={(page) => handlePageSelect(page.selected)}
            pageCount={data.meta.total_pages}
            perPageCount={ORGS_PER_PAGE}
            selected={data.meta.current_page}
            totalCount={data.meta.total_count}
          />
        ) : null}
      </div>
      <ApproveOrganization
        organizationId={selectedOrganizationId}
        refetch={refetch}
        setShow={setShowApproveOrganizationModal}
        show={showApproveOrganizationModal}
      />
      <RejectOrganization
        organizationId={selectedOrganizationId}
        refetch={refetch}
        setShow={setShowRejectOrganizationModal}
        show={showRejectOrganizationModal}
      />
    </>
  )
}
