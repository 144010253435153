import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useInviteUserToOrganization } from 'api/organization_memberships'
import { NumberLike } from 'types/misc'
import { AsyncButton, useAlert } from '@youversion/react'
import { Refresh as RefreshIcon } from '@mui/icons-material'
import { API_STATUS } from 'helpers'
import { useTranslation } from 'react-i18next'

interface ReInviteMemberModalProps {
  orgId: NumberLike
  user: User
}

export function ReInviteMember({ orgId, user }: ReInviteMemberModalProps) {
  const [show, setShow] = useState(false)
  const { mutateAsync: inviteMember, status: loadingMembershipDeletion } =
    useInviteUserToOrganization()
  const { t } = useTranslation(['organizations', 'common'])

  const { throwAlert } = useAlert()

  async function handleDeleteUser() {
    try {
      await inviteMember({
        orgId,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
      })
      throwAlert({
        id: 'organization_membership_invite_success',
        key: 'organization_membership_invite_success',
        message: t(
          'organizations:single_org.membership_table.reinvite_member.organization_membership_invite_success',
        ),
        timeout: 3000,
        type: 'success',
      })
      setShow(false)
    } catch (error) {
      throwAlert({
        id: 'organization_membership_invite_error',
        key: 'organization_membership_invite_error',
        message: t(
          'organizations:single_org.membership_table.reinvite_member.organization_membership_invite_error',
        ),
        timeout: 3000,
        type: 'error',
      })
    }
  }

  const isLoading = loadingMembershipDeletion === API_STATUS.LOADING

  return (
    <>
      <Tooltip
        title={t(
          'organizations:single_org.membership_table.reinvite_member.title',
        )}
      >
        <IconButton
          aria-label={t(
            'organizations:single_org.membership_table.reinvite_member.title',
          )}
          onClick={() => setShow(true)}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth="xs" onClose={() => setShow(false)} open={show}>
        <DialogTitle>
          {t('organizations:single_org.membership_table.reinvite_member.title')}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {t(
              'organizations:single_org.membership_table.reinvite_member.sub_text',
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus={true}
            disabled={isLoading}
            onClick={() => setShow(false)}
            type="button"
          >
            {t('common:close')}
          </Button>
          <AsyncButton
            color="primary"
            disabled={isLoading}
            idle={t(
              'organizations:single_org.membership_table.reinvite_member.button.idle',
            )}
            onClick={handleDeleteUser}
            options={{
              disableUpdateOnSuccess: true,
            }}
            pending={t(
              'organizations:single_org.membership_table.reinvite_member.button.loading',
            )}
            rejected={t(
              'organizations:single_org.membership_table.reinvite_member.button.failed',
            )}
            resolved={t(
              'organizations:single_org.membership_table.reinvite_member.button.success',
            )}
            variant="contained"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
