import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import biblePlanHeart from 'assets/bible-plan-heart.png'
import React, { FC } from 'react'
import PublicIcon from '@mui/icons-material/Public'
import CloseIcon from '@mui/icons-material/Close'
import { LazyImage } from '@youversion/react'

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',
    insetBlockStart: '1rem',
    insetInlineEnd: '1rem',
  },
  learnMoreButton: {
    marginBlockEnd: '1rem',
  },
  dialog: {
    borderRadius: '1rem',
  },
}))

interface Props {
  isNotificationDismissedKey: string
}

export const HelpPagesNotification: FC<Props> = ({
  isNotificationDismissedKey,
}) => {
  const classes = useStyles()

  const [showNotification, setShowNotification] = React.useState(true)
  const hideHPNotification = () => {
    localStorage.setItem(isNotificationDismissedKey, JSON.stringify(true))
    setShowNotification(false)
  }
  return (
    <>
      {showNotification ? (
        <Dialog
          classes={{ paper: classes.dialog }}
          maxWidth="xs"
          onClose={() => setShowNotification(false)}
          open={showNotification}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            m="2rem"
            mb="1rem"
          >
            <IconButton
              className={classes.closeIcon}
              color="primary"
              data-testid="x-button"
              edge="end"
              onClick={() => setShowNotification(false)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <Box>
              <LazyImage
                alt="bible plan heart"
                height="215px"
                src={biblePlanHeart}
                width="100%"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              mt="2rem"
              textAlign="center"
            >
              <Typography gutterBottom={true} variant="h2">
                Introducing New Help Pages
              </Typography>
              <Typography variant="body2">
                For the expert or novice, the new help pages can partner with
                you to create excellence Plans that help people seek intimacy
                with God every day.
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" mt="2rem">
              <Button
                className={classes.learnMoreButton}
                color="primary"
                href="https://www.partners.youversion.com/"
                size="large"
                startIcon={<PublicIcon fontSize="inherit" />}
                target="blank"
                variant="contained"
              >
                Learn More
              </Button>
              <Box
                color="text.secondary"
                display="flex"
                justifyContent="center"
              >
                <Button
                  color="inherit"
                  onClick={hideHPNotification}
                  size="small"
                  variant="text"
                >
                  DON'T SHOW AGAIN
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
      ) : null}
    </>
  )
}
