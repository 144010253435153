import { Box, InputAdornment, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  clickable: {
    cursor: 'pointer',
    marginInlineStart: theme.spacing(2),
    marginInlineEnd: theme.spacing(1),
  },
  closeBorder: {
    borderColor: grey[300],
    cursor: 'pointer',
    borderInlineEnd: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
  },
  inputForName: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    backgroundColor: grey['100'],
    borderRadius: theme.spacing(5),
    padding: `0px ${theme.spacing(1)}`,
    marginBlockStart: theme.spacing(1),
  },
  noBorder: {
    border: 'none',
  },
}))

/**
 * Input Component for filtering by user names, ids, emails.
 *
 * @returns {ReactElement} - Returns an input component.
 */
export function UsersFilters() {
  const classes = useStyles()
  const [query, setQuery] = useSearchParams()
  const [search, setSearch] = useState(query.get('search') ?? '')

  const clearSearchInput = () => {
    setSearch('')
    query.delete('search')
    setQuery(query)
  }

  const onSearch = () => {
    query.set('page', '1')
    query.set('search', search.trim())
    setQuery(query)
  }

  const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onSearch()
    }
  }

  return (
    <TextField
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
        className: classes.inputForName,
        endAdornment:
          search === '' ? null : (
            <InputAdornment position="end">
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                justifyContent="center"
              >
                <Box
                  aria-label="Clear Search Input"
                  className={classes.closeBorder}
                  onClick={() => clearSearchInput()}
                >
                  <CloseIcon fontSize="inherit" />
                </Box>
                <Box
                  aria-label="Search Users"
                  className={classes.clickable}
                  onClick={() => onSearch()}
                >
                  <SearchIcon fontSize="inherit" />
                </Box>
              </Box>
            </InputAdornment>
          ),
        startAdornment:
          search === '' ? (
            <InputAdornment aria-label="Search for a user" position="start">
              <Box marginLeft={1}>
                <SearchIcon fontSize="inherit" />
              </Box>
            </InputAdornment>
          ) : null,
      }}
      fullWidth={true}
      onChange={(event) => setSearch(event.target.value)}
      onKeyUp={onKeyUp}
      placeholder="Search by Name, Email, or User ID"
      type="text"
      value={search}
      variant="outlined"
    />
  )
}
