/**
 * @module TranslationIcon
 */
import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EditIcon from '@mui/icons-material/Edit'

interface StatusType {
  status: string
}

export function TranslationIcon({ status }: StatusType) {
  if (status === 'published') {
    return <CheckIcon fontSize="small" />
  }

  if (status === 'draft') {
    return <EditIcon fontSize="small" />
  }

  if (status === 'submitted') {
    return <AccessTimeIcon fontSize="small" />
  }

  if (status === 'approved') {
    return <CheckIcon fontSize="small" />
  }
  return null
}
