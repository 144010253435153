/**
 * @module PlanEdit
 */
import React from 'react'
import { API_STATUS } from 'helpers'
import { useCategories } from 'hooks'
import LoaderOverlay from 'components/LoaderOverlay'
import { PlanForm } from 'components/Plans/EditPlanForm'
import { usePlan } from 'context'
import { Plan } from 'components/Plans/types'

function EditPlan() {
  const {
    updatedPlan,
    loadingStatus,
    hasPlanBeenPublished,
    planDays,
    planLanguage,
  } = usePlan()
  const allCategories = useCategories()

  const [activeCategories, setActiveCategories] = React.useState<
    Array<Plan.Category>
  >([])

  React.useEffect(() => {
    function loadActiveCategories() {
      if ('categories' in updatedPlan && 'categoriesById' in allCategories) {
        const selectedCategories = (updatedPlan.categories || [])
          .map((id) => allCategories.categoriesById[id])
          .filter((category) => category !== undefined)
        setActiveCategories(selectedCategories)
      }
    }
    loadActiveCategories()
  }, [allCategories, updatedPlan])

  if (
    loadingStatus === API_STATUS.LOADING ||
    !('categories' in updatedPlan) ||
    !('categoriesById' in allCategories) ||
    !('id' in planLanguage)
  ) {
    return <LoaderOverlay />
  }

  return (
    <PlanForm
      activeCategories={activeCategories}
      categories={Object.values(allCategories.categoriesById)}
      categorySections={allCategories.categorySections}
      hasPlanBeenPublished={hasPlanBeenPublished}
      plan={updatedPlan}
      planDays={planDays}
      planLanguage={planLanguage}
      setActiveCategories={setActiveCategories}
    />
  )
}

export default EditPlan
