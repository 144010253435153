/* eslint-disable jsdoc/require-param */
/**
 * @module Day
 */
import * as React from 'react'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import { Box, Button, Theme, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { VolumeUp } from '@mui/icons-material'
import { border } from '@youversion/react/styles/colors-v3'
import { useStatusIconAndText } from 'hooks'
import { usePlan } from 'context'
import { Plan } from 'components/Plans/types'
import { useTranslation } from 'react-i18next'
import { DayOptions } from '../DayOptions'

import styles from '../DayScroller.module.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayBox: {
      backgroundColor: theme.palette.background.paper,
      border: '3px solid transparent',
      borderRadius: theme.spacing(1),
      height: 70,
      marginInlineEnd: theme.spacing(1),
      textAlign: 'center',
      textTransform: 'none',
      width: 70,
    },
    dayBoxBottomLeftIcon: {
      insetBlockEnd: 2,
      fill: theme.palette.text.secondary,
      fontSize: 16,
      insetInlineStart: 2,
      position: 'absolute',
    },
    dayBoxContainer: {
      display: 'inline-block',
      height: 70,
      marginInlineEnd: theme.spacing(1.5),
      position: 'relative',
      width: 70,
    },
    newDay: {
      backgroundColor: 'inherit',
      border: `2px dashed ${border[theme.palette.mode]}`,
      color: theme.palette.text.secondary,
    },
    selectedBox: {
      fontWeight: 'bold',
    },
    smallerIcon: {
      fontSize: '14px !important',
    },
  }),
)

interface DayScrollerDayProps {
  /** The day object. */
  day: Plan.Day
  /** The day's index from the planDays array. */
  index: number
  /** The position of the next day. */
  prevDayPosition?: number
  /** The position of the previous day. */
  nextDayPosition?: number
  /** The plan days of the plan for the dayscroller. */
  totalDays: number
}

/**
 * Represents a container for a DayScroller Day component.
 *
 * @returns {React.ReactElement} - The Day component.
 */
export function DayScrollerDay({
  day,
  index,
  nextDayPosition,
  prevDayPosition,
  totalDays,
}: DayScrollerDayProps) {
  const classes = useStyles()
  const { id: planId, dayId } = useParams()
  const { hasNarratedAudio, scrollRef } = usePlan()
  const { t } = useTranslation('plans')

  const dayStatus =
    hasNarratedAudio && !day.narrated_audio_attachment
      ? Plan.DayStatus.MISSING_NARRATED_AUDIO_STATUS
      : day.status

  const { icon: dayIcon, tooltipText: dayTooltipText } =
    useStatusIconAndText(dayStatus)
  const activeDayIndex = day.id.toString() === dayId ? index : null

  // Scroll current day into view.
  React.useEffect(() => {
    if (
      activeDayIndex !== null &&
      scrollRef !== undefined &&
      scrollRef.current
    ) {
      // There is at most 10 boxes shown. 7 was chosen to show 3 boxes on either side of current day.
      const dayboxesThreshold = 7
      if (
        totalDays - activeDayIndex < dayboxesThreshold &&
        scrollRef.current.lastElementChild
      ) {
        scrollRef.current.lastElementChild.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      } else if (
        activeDayIndex < dayboxesThreshold &&
        scrollRef.current.firstElementChild
      ) {
        scrollRef.current.firstElementChild.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      } else if (
        activeDayIndex > dayboxesThreshold &&
        scrollRef.current.firstElementChild
      ) {
        const element = Array.from(scrollRef.current.children)[
          activeDayIndex - 3
        ]
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      } else if (scrollRef.current.children) {
        const element = Array.from(scrollRef.current.children)[
          activeDayIndex + 3
        ]
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, [activeDayIndex, scrollRef, totalDays])

  return (
    <Box className={classes.dayBoxContainer} key={day.id}>
      <Button
        className={classNames(
          styles.dayBox,
          classes.dayBox,
          dayId === day.id.toString() ? styles.dayBoxSelected : null,
        )}
        component={Link}
        to={`/plans/${planId}/days/${day.id}`}
      >
        <Typography
          className={dayId === day.id.toString() ? classes.selectedBox : ''}
          color="textPrimary"
          variant="body2"
        >
          {t('plans:day.day_number', { number: index + 1 })}
        </Typography>
        {day.narrated_audio_attachment ? (
          <VolumeUp
            className={classNames(
              classes.dayBoxBottomLeftIcon,
              classes.smallerIcon,
            )}
            color="inherit"
            data-testid="narrated-audio-icon"
            fontSize="small"
          />
        ) : null}
        <Tooltip placement="top" title={dayTooltipText}>
          {dayIcon}
        </Tooltip>
      </Button>
      <DayOptions
        day={day}
        dayId={dayId}
        dayNumber={index + 1}
        index={index}
        nextDayPosition={nextDayPosition}
        prevDayPosition={prevDayPosition}
        totalDays={totalDays}
      />
    </Box>
  )
}

DayScrollerDay.defaultProps = {
  nextDayPosition: -1,
  prevDayPosition: -1,
}
