import { Box, Chip, Typography } from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import { useGetOrganizationContentPartners } from '../../../api/organizations'
import { titleCase } from '../../../helpers/title-case'

interface Props {
  organizationId: number
}

function getYouVersionConnectUrl({
  organizationId,
}: {
  organizationId: string
}) {
  return `${process.env.REACT_APP_YOUVERSION_CONNECT_URL}?organization_id=${organizationId}`
}

const statusColor = {
  accepted: 'success',
  pending: 'warning',
  rejected: 'error',
} as const

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    hide: true,
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'license_status',
    headerName: 'License Status',
    width: 150,
    // @ts-expect-error
    renderCell: ({ value }: { value: 'accepted' | 'pending' | 'rejected' }) => (
      <Chip
        color={statusColor[value]}
        label={titleCase(value)}
        size="small"
        variant="outlined"
      />
    ),
  },
  {
    field: 'links',
    headerName: 'Links',
    flex: 1,
    renderCell: ({ row }) => (
      <a
        href={getYouVersionConnectUrl({ organizationId: row.id })}
        rel="noreferrer"
        target="_blank"
      >
        View in YouVersion Connect
      </a>
    ),
  },
]

export function OrganizationContentPartners({ organizationId }: Props) {
  const { data, isLoading } = useGetOrganizationContentPartners({
    organizationId,
  })

  return (
    <Box my={8}>
      <Typography variant="h2">YouVersion Connect</Typography>
      <Typography color="textSecondary" marginBlockEnd={3} variant="body1">
        YouVersion Connect organizations mapped to this Partner Portal
        organization.
      </Typography>
      <Box data-testid="content-partnerships-data" height="50vh" mt={4}>
        <DataGrid
          columns={columns}
          loading={isLoading}
          pageSize={50}
          rowCount={data?.length}
          rowHeight={70}
          rows={data ?? []}
        />
      </Box>
    </Box>
  )
}
