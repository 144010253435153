/* eslint-disable no-lone-blocks */
/**
 * @module TranslationPicker
 */
import React from 'react'
import { Box } from '@mui/material'
import { Plan } from 'components/Plans/types'
import { useGetOrganization } from 'api/organizations'
import LanguageNumIcon from './LanguageNumIcon'
import TranslationComboBox from './TranslationComboBox'
import { TranslationPickerProps } from './types'

{
  /* TODO - figure out double scroll issue */
}
interface Props {
  planId: number
  plansCurrentTranslations: Array<TranslationPickerProps>
  plan: Plan.Plan
}

export function TranslationPicker({
  planId,
  plansCurrentTranslations,
  plan,
}: Props) {
  const [isSortedAlphabetically, setIsSortedAlphabetically] =
    React.useState(false)
  const [selectedTranslationProps, setSelectedTranslationProps] =
    React.useState<TranslationPickerProps | null>(
      () =>
        plansCurrentTranslations.find(
          (translation) => translation.id === planId,
        ) || null,
    )
  const translationsClone = [...plansCurrentTranslations]
  const statusSortedTranslations = translationsClone.sort(
    ({ status: a }, { status: b }) => {
      const aLowerCase = a.toLowerCase()
      const bLowerCase = b.toLowerCase()
      if (aLowerCase < bLowerCase) {
        return -1
      }
      if (aLowerCase > bLowerCase) {
        return 1
      }
      return 0
    },
  )

  const translationsCopy = [...plansCurrentTranslations]
  const alphabeticallySortedTranslations = translationsCopy.sort(
    ({ languageTag: firstLang }, { languageTag: secondLang }) => {
      if (firstLang && secondLang) {
        if (firstLang < secondLang) {
          return -1
        }
        if (firstLang > secondLang) {
          return 1
        }
        return 0
      }
      return 0
    },
  )

  const theOrg = useGetOrganization(plan?.primary_organization_id)
  const isOrgPlanListGreaterThanOne =
    theOrg?.data?.plan_language_ids?.length <= 1

  return (
    <Box alignItems="center" display="flex">
      <Box alignItems="center" display="flex" mr={1}>
        <LanguageNumIcon numberOfLangs={plansCurrentTranslations.length} />
      </Box>
      <TranslationComboBox
        disableNewLanguageButton={!plan.submitted_at}
        handleToggleSort={() => {
          setIsSortedAlphabetically((prevBool) => !prevBool)
        }}
        isOrgPlanListGreaterThanOne={isOrgPlanListGreaterThanOne}
        isSortedAlphabetically={isSortedAlphabetically}
        selectedTranslation={selectedTranslationProps}
        setSelectedTranslation={setSelectedTranslationProps}
        translations={
          isSortedAlphabetically
            ? alphabeticallySortedTranslations
            : statusSortedTranslations
        }
      />
    </Box>
  )
}
