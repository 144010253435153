/**
 * @module UsersRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
import { Route, Routes } from 'react-router-dom'
import { Users } from 'containers/users'
import { UserRoutes } from './user'

export function UsersRoutes() {
  return (
    <Routes>
      <Route element={<Users />} index={true} />

      <Route element={<UserRoutes />} path=":id/*" />
    </Routes>
  )
}
