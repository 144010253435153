import React from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { LazyImage } from '@youversion/react'

const useStyles = makeStyles(() =>
  createStyles({
    cardStyle: {
      height: '125px',
      width: '125px',
      padding: '0px',
      background: '#EDEFEF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    iconStyle: {
      height: '53px',
      width: '53px',
      color: '#FFFFFF;',
      position: 'absolute',
    },
    textStyle: {
      paddingBlockStart: '8px',
    },
  }),
)

export interface OrganizationResultsProps {
  organizationsList: Array<Organization>
  selectedOrganization: Organization | null
  onChange: (selectedOrganization: Organization | null) => void
}

export const OrganizationResults = ({
  organizationsList,
  selectedOrganization,
  onChange,
}: OrganizationResultsProps) => {
  const cardClass = useStyles()

  return (
    <DialogContent>
      <Box height="300px">
        <Grid columnSpacing={6} container={true} rowSpacing={2}>
          {organizationsList.map((organization) => (
            <Grid item={true} key={organization.id} xs={4}>
              <Card>
                <CardActionArea
                  data-testid={organization.name}
                  onClick={() => {
                    onChange(organization)
                  }}
                >
                  <CardContent classes={{ root: cardClass.cardStyle }}>
                    {organization.id === selectedOrganization?.id && (
                      <CheckCircleIcon className={cardClass.iconStyle} />
                    )}
                    {organization.profile_photo?.asset?.thumb_url && (
                      <LazyImage
                        alt={`${organization.name} icon`}
                        height="100%"
                        src={organization.profile_photo.asset.thumb_url}
                        width="100%"
                      />
                    )}
                  </CardContent>
                </CardActionArea>
              </Card>
              <Typography className={cardClass.textStyle}>
                {organization.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </DialogContent>
  )
}
