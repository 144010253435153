import { Form, Formik } from 'formik'
import MuiLoadingButton from 'components/Buttons/mui-loading-button'
import * as Yup from 'yup'
import { Box, TextField } from '@mui/material'
import { useAlert } from '@youversion/react'
import { useAcceptUserInvitation } from 'api/organization_memberships'
import { useTranslation } from 'react-i18next'

interface ConfirmUserAccountFormProps {
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
}

interface Props {
  token: string
}

export const ConfirmUserAccountForm = ({ token }: Props) => {
  const { isLoading, mutate } = useAcceptUserInvitation()
  const { throwAlert } = useAlert()
  const { t } = useTranslation('create_account')

  const ConfirmUserAccountFormSchema = Yup.object().shape({
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('passwords_match'))
      .required(t('please_confirm_password')),
    firstName: Yup.string().required(t('please_enter_first_name')),
    lastName: Yup.string().required(t('please_enter_last_name')),
    password: Yup.string().required(t('please_enter_password')),
  })

  async function submit(values: ConfirmUserAccountFormProps) {
    mutate(
      {
        ...values,
        token,
      },
      {
        onError: (e) => {
          if (e instanceof Error) {
            throwAlert({
              id: 'accept_user_account_failed',
              key: 'accept_user_account_failed',
              message: e.message,
              timeout: 3000,
              type: 'error',
            })
          }
        },
        onSuccess: (res) => {
          if (res) {
            localStorage.setItem('token', JSON.stringify(res.meta.token))

            if (res.organization_membership.organization_id) {
              localStorage.setItem(
                'orgId',
                String(res.organization_membership.organization_id),
              )
            } else {
              localStorage.removeItem('orgId')
            }
            window.location.replace('/plans')
          }
        },
      },
    )
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={submit}
      validationSchema={ConfirmUserAccountFormSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        handleChange,
        values,
        handleBlur,
      }) => {
        const invalidFirstNameField =
          !values.firstName.length ||
          (touched.firstName && Boolean(errors.firstName))

        const invalidLastNameField =
          !values.lastName.length ||
          (touched.lastName && Boolean(errors.lastName))

        const invalidPasswordField =
          !values.password.length ||
          (touched.password && Boolean(errors.password))

        const invalidConfirmPasswordField =
          !values.confirmPassword.length ||
          (touched.confirmPassword && Boolean(errors.confirmPassword))

        const disableButton =
          invalidFirstNameField ||
          invalidLastNameField ||
          invalidConfirmPasswordField ||
          invalidPasswordField ||
          isLoading

        return (
          <Form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                error={touched.firstName && Boolean(errors.firstName)}
                fullWidth={true}
                helperText={touched.firstName && errors.firstName}
                label={t('first_name')}
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('first_name')}
                type="text"
                value={values.firstName}
                variant="outlined"
              />
            </Box>
            <Box mb={3}>
              <TextField
                error={touched.lastName && Boolean(errors.lastName)}
                fullWidth={true}
                helperText={touched.lastName && errors.lastName}
                label={t('last_name')}
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('last_name')}
                type="text"
                value={values.lastName}
                variant="outlined"
              />
            </Box>
            <Box mb={3}>
              <TextField
                error={touched.password && Boolean(errors.password)}
                fullWidth={true}
                helperText={touched.password && errors.password}
                label={t('password')}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('password')}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Box>

            <Box mb={3}>
              <TextField
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                fullWidth={true}
                helperText={touched.confirmPassword && errors.confirmPassword}
                label={t('confirm_password')}
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={t('confirm_password')}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />
            </Box>
            <Box>
              <MuiLoadingButton
                color="primary"
                disabled={disableButton}
                isLoading={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                {t('confirm_account')}
              </MuiLoadingButton>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
