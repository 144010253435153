/* eslint-disable jsdoc/require-param */
import { fetchClient } from 'helpers/transport'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'helpers/constants'

const VOICES_BASE_URL = '/v1/polly_voices'

/**
 * List voices items.
 *
 * @returns {Promise<Array<object>>} - An array of all voices items.
 */
async function getPollyVoices(languageTag: string) {
  const response = await fetchClient<{
    polly_voices: Array<PollyVoice>
    meta: Meta
  }>(`${VOICES_BASE_URL}?language_tag=${languageTag}`)

  return response.parsedBody.polly_voices ?? []
}

export const useGetPollyVoices = (languageTag: string) =>
  useQuery([QUERY_KEYS.VOICES, languageTag], () => getPollyVoices(languageTag))
