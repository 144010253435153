/**
 * @module Languages
 */

import update from 'immutability-helper'
import { handleActions } from 'redux-actions'
import { handleApiAction } from 'helpers'

import {
  CREATE,
  LIST,
  UPDATE,
  // UPDATE_LANGUAGE,
  // DESTROY_LANGUAGE,
} from 'state/actions/languages/constants'

/*
  Example state:

  languages: {
    all: {
      items: [Array of Group objects],
      loading: false | true,
      loadedAt: timestamp,
    },
    action: {
      create: {
        status: 'pending | success | failure | null', // for tracking the status of an action triggered via the UI.
        meta: {}
      },
      update: {
        status: ...,
        meta: ...,
      },
      destroy: {
        status: ...,
        meta: ...,
      }
    },
  }
*/

const initialState = {
  all: {
    items: [],
    loading: false,
    loadedAt: null,
  },
  action: {
    create: {
      status: null,
      meta: {},
    },
    update: {
      status: null,
      meta: {},
    },
    destroy: {
      status: null,
      meta: {},
    },
  },
}

export default handleActions(
  {
    [LIST]: handleApiAction((_, action) => ({
      start: (state) => {
        return update(state, {
          all: {
            loading: { $set: true },
            loadedAt: { $set: null },
          },
        })
      },

      success: (state) => {
        return update(state, {
          all: {
            loading: { $set: false },
            loadedAt: { $set: Date.now() },
            items: { $set: action.payload.languages },
          },
        })
      },

      failure: (state) => {
        return update(state, {
          all: {
            loading: { $set: false },
          },
        })
      },
    })),

    [CREATE]: handleApiAction((_, action) => ({
      start: (state) => {
        return update(state, {
          action: {
            create: {
              status: { $set: 'pending' },
            },
          },
        })
      },

      success: (state) => {
        return update(state, {
          all: {
            items: { $push: [action.payload.language] },
          },
          action: {
            create: {
              status: { $set: 'success' },
              meta: { $set: {} },
            },
          },
        })
      },

      failure: (state) => {
        return update(state, {
          action: {
            create: {
              status: { $set: 'failure' },
              meta: {
                errors: { $set: action.payload.details },
              },
            },
          },
        })
      },
    })),

    [UPDATE]: handleApiAction((_, action) => ({
      start: (state) => {
        return update(state, {
          action: {
            update: {
              status: { $set: 'pending' },
            },
          },
        })
      },

      success: (state) => {
        const index = state.all.items.findIndex(
          (language) => language.id === action.payload.language.id,
        )

        return update(state, {
          all: {
            items: {
              [index]: { $set: action.payload.language },
            },
          },
          action: {
            update: {
              status: { $set: 'success' },
              meta: { $set: {} },
            },
          },
        })
      },

      failure: (state) => {
        return update(state, {
          action: {
            update: {
              status: { $set: 'failure' },
              meta: {
                errors: { $set: action.payload.details },
              },
            },
          },
        })
      },
    })),
  },
  initialState,
)
