import { Plan } from 'components/Plans/types'
import { submissionStatuses } from 'helpers/constants'

/**
 * Determines whether or not a plan has narrated audio enabled.
 */
export const planHasNarratedAudio = (plan: Plan.Plan): boolean => {
  if (plan) {
    return Boolean(
      plan.narrated_audio_feature &&
        plan.narrated_audio_feature.submission_status !==
          submissionStatuses.DISABLED,
    )
  }
  return false
}
