// eslint-disable-next-line jsdoc/require-param
/**
 * @module Can
 */
import React from 'react'
import { useAuth } from 'auth/context'

interface CanProps {
  /** An optional fallback to render if the permission check fails. Accepts anything renderable by React. */
  fallback?: React.ReactElement
  /** The user permission(s) to check. */
  user: string
}

/**
 * The Can component.
 *
 * @alias module:Can
 *
 * @param {object} props - The component props object.
 * @param {React.ReactNode} props.children - The component children.
 * @param {React.ReactNode} [props.fallback] - An optional fallback to render if the permission check fails. Accepts anything renderable by React.
 * @param {(string|Array<string>)} props.user - The user permission(s) to check.
 *
 * @returns {React.ReactElement} - The Can component.
 *
 * @example
 * import { Can } from 'auth'
 *
 * function MyComponent () {
 *   return (
 *     <Can user="read_posts" fallback="Nothing to show">
 *       <h1>This is a post</h1>
 *       <Can user={['edit_posts', 'read_comments']} fallback={() => (<button>Sign Up</button>)}>
 *         <button>Edit Post</button>
 *       </Can>
 *     </Can>
 *   )
 * }
 */
export function Can({
  children,
  fallback,
  user: userPermission,
}: React.PropsWithChildren<CanProps>) {
  const { user } = useAuth()

  if (user && user.can(userPermission)) {
    return <>{children}</>
  }

  if (fallback) {
    return <>{fallback}</>
  }

  return null
}

Can.defaultProps = {
  children: null,
  fallback: undefined,
}
