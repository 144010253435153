/**
 * @module Accept
 */
import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import Helmet from 'react-helmet'
import { BASE_TITLE } from 'helpers/constants'
import { ConfirmUserAccountForm } from 'components/ConfirmUserAccountForm'
import { useTranslation } from 'react-i18next'

/**
 * The Accept component.
 *
 * @alias module:Accept
 *
 * @returns {ReactElement} - The Reset Password component.
 */
function AcceptInvitation() {
  const { token } = useParams()
  const { t } = useTranslation(['create_account'])

  return (
    <>
      <Helmet title={`${BASE_TITLE} | ${t('accept_invitation')}`} />

      <Box
        alignItems="center"
        display="flex"
        flex={1}
        flexDirection="column"
        justifyContent="center"
      >
        <Box>
          <Typography color="textPrimary" gutterBottom={true} variant="h1">
            {t('welcome_aboard')}
          </Typography>

          <Typography color="textSecondary" gutterBottom={true} variant="body1">
            {t('create_password')}
          </Typography>

          <Box mt={2}>
            {token ? <ConfirmUserAccountForm token={token} /> : null}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AcceptInvitation
