import React from 'react'
import PropTypes from 'prop-types'
import styles from './Misc.module.scss'

export default function Table({ children, ...props }) {
  return (
    // Allow arbitrary props, like `data-testid` to be passed to the
    // primary table element.
    <table {...props} className={styles.table}>
      {children}
    </table>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
}
