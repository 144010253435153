/**
 * @module SignUpRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import { FC, Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import SignUpFormWrapper from 'containers/SignUpFormWrapper'
import LoaderOverlay from 'components/LoaderOverlay'

const PartnerSignUpForm = lazy(() => import('components/PartnerSignUpForm'))
const SignUpSuccess = lazy(() => import('./success'))

/**
 * The SignUpRoutes component.
 *
 * @alias module:SignUpRoutes
 *
 * @returns {ReactElement} - The SignUpRoutes component.
 */
const SignUpRoutes: FC = () => {
  return (
    <SignUpFormWrapper>
      <Suspense fallback={<LoaderOverlay />}>
        <Routes>
          <Route index={true} element={<PartnerSignUpForm />} />

          {/* 🐣 Nested routes only below this line. */}
          <Route path="success" element={<SignUpSuccess />} />
        </Routes>
      </Suspense>
    </SignUpFormWrapper>
  )
}

export default SignUpRoutes
