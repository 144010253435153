import { userSchema } from 'state/reducers/organizationsReducer'
import { LOAD_ACCOUNT } from './constants'

function loadAccount() {
  return async function loadAccount(dispatch) {
    return dispatch({
      type: LOAD_ACCOUNT,
      api: async (client) => {
        return client.get('/account', userSchema, {})
      },
    })
  }
}

export { loadAccount }
