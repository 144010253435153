/**
 * @module PlanImagesHelpers
 */
import { IMAGEPROXY_URL } from 'helpers/constants'

export const urlForImageAsset = (image: Attachment) => {
  if (!image) {
    return null
  }

  if (image.asset?.original_url) {
    return image.asset.original_url
  }

  if (image.asset?.thumb_url) {
    return image.asset.thumb_url
  }

  if (
    image.source_url &&
    !image.source_url.startsWith('https://cassi.thewardro.be/')
  ) {
    return image.source_url
  }

  return null
}

/**
 * Given an image object with `.asset` from the API, generates a proxied url appropriately
 * This avoids proxied URLs in Development mode.
 *
 * @param {object} image - An image object returned from the API.
 * @param {string} size - An optional size for the image. Ex: 100x (or whatever format the ImageProxy API allows).
 * @returns {string|null} - A url for the image, possibly prefixed with Image Proxy.
 */
export function imageProxyUrl(image: Attachment | null, size: string) {
  let assetUrl = null
  if (image) {
    assetUrl = urlForImageAsset(image)
  }

  // Don't add the prefix if the assetUrl is falsey (empty/null)
  // NOTE: We can use process.env here because we inject it on purpose.
  if (process.env.NODE_ENV === 'production' && assetUrl) {
    //  In production, we want to send all images through Image Proxy...
    assetUrl = size
      ? `${IMAGEPROXY_URL}/${size}/${assetUrl}`
      : `${IMAGEPROXY_URL}/${assetUrl}`
  }
  return assetUrl
}
