/**
 * @module parseDayNumber
 */

/* eslint-disable import/prefer-default-export */
export function parseDayNumber(days, dayId) {
  const dayNumber = days.findIndex((day) => {
    if (day.id === dayId) {
      return true
    }
    return false
  })
  return dayNumber + 1
}
