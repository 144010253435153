/**
 * @module GroupRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import { ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useAuth } from 'auth'
import { Group } from 'components/Group'
import { GroupEdit } from 'containers/groups/edit'

/**
 * The GroupRoutes component.
 *
 * @alias module:GroupRoutes
 *
 * @returns {ReactElement} - The GroupRoutes component.
 */
export default function GroupRoutes() {
  const { user } = useAuth()

  return (
    <Routes>
      <Route index={true} element={<Group />} />

      {/* 🐣 Nested routes only below this line. */}
      {user && user.can('edit:group') ? (
        <Route path="edit" element={<GroupEdit />} />
      ) : null}
    </Routes>
  )
}
