import React, { FC, ReactNode } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  dialog: {
    width: '100%',
  },
}))
type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'

export interface Props {
  component?: ReactNode
  dialogActions?: ReactNode
  dialogContentChildren?: ReactNode | String
  dialogTitle?: ReactNode | String
  disableClose: boolean
  handleClose: (reason: CloseReason) => void | boolean
  isDialogOpen: boolean
}

const DialogWrapper: FC<Props> = ({
  component,
  dialogActions,
  dialogContentChildren,
  dialogTitle,
  disableClose,
  handleClose,
  isDialogOpen,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      aria-describedby="dialog-wrapper"
      aria-labelledby="dialog-wrapper-label"
      classes={{ paper: classes.dialog }}
      onClose={disableClose ? undefined : () => handleClose('backdropClick')}
      open={isDialogOpen}
    >
      {component || (
        <>
          <DialogTitle id="dialog-wrapper-title">{dialogTitle}</DialogTitle>

          <DialogContent id="dialog-wrapper-content">
            {dialogContentChildren}
          </DialogContent>
          <DialogActions>{dialogActions}</DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default DialogWrapper
