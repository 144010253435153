/**
 * @module DayBox
 */
import React from 'react'
import classnames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import { defaultBlockTypes } from '@youversion/mui-block-editor'
import { AsyncButton } from '@youversion/react'
import { utilityColors } from '@youversion/react/styles/colors-v3'
import { statusTypes } from '@youversion/utils'
import { getPlanDay, useGetComments } from 'api/plans'
import { Card, CardSection, CommentSection } from 'components/Card'
import { API_STATUS, submissionStatuses } from 'helpers/constants'
import BibleReferencePills from 'components/bible/bible-reference-pills'
import { Can } from 'auth'
import { useTranslation } from 'react-i18next'
import styles from '../Plans.module.scss'
import PreviewBlockContent from '../devotional-content-blocks/preview-block-content'
import { Plan } from '../types'

const useStyles = makeStyles((theme) => ({
  cardSection: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    position: 'relative',
  },
  editIcon: {
    position: 'absolute',
    insetInlineEnd: theme.spacing(1),
    insetBlockStart: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
  planBox: {
    border: 'none',
    borderRadius: theme.spacing(2),
    gap: 16,
    height: '100%',
  },
  planContainer: {
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2),
    },
  },
  required: {
    color: utilityColors.light.alert,
  },
}))

interface Props {
  dayNumber: number
  hasNarratedAudio: boolean
  onApproveDayInfo: Function
  planStatus: Plan.Status
}

export default function DayBox({
  dayNumber,
  hasNarratedAudio,
  onApproveDayInfo,
  planStatus,
}: Props) {
  const classes = useStyles()
  const { id, dayId } = useParams()
  const { t } = useTranslation('plans')
  const planId = parseInt(id!, 10)

  const [day, setDay] = React.useState<Plan.Day>()
  const [loadingStatus, setLoadingStatus] = React.useState(statusTypes.IDLE)

  const {
    data: comments,
    refetch: refetchComments,
    status: commentLoadingStatus,
  } = useGetComments({ planId, dayId })

  React.useEffect(() => {
    async function loadDayDetails() {
      if (dayId) {
        setLoadingStatus(statusTypes.PENDING)
        try {
          const dayResponse = await getPlanDay({ planId, dayId })
          if (dayResponse) {
            setDay(dayResponse)
          }

          setLoadingStatus(statusTypes.RESOLVED)
        } catch (error) {
          setLoadingStatus(statusTypes.REJECTED)
          if (error instanceof Error) {
            throw new Error(
              t('plans:day.unable_to_load', { message: error.message }),
            )
          }
        }
      }
    }

    loadDayDetails()
  }, [dayId, planId, t])

  if (!day) {
    return (
      <Box pb={1} pl={1} pr={1} width="100%">
        <LinearProgress />
      </Box>
    )
  }

  const isLoading =
    loadingStatus === statusTypes.RESOLVED ||
    commentLoadingStatus === API_STATUS.SUCCESS

  return (
    <Collapse className={classes.fullWidth} in={isLoading}>
      <Card
        className={classnames(styles.planBoxFull, classes.planBox)}
        data-testid="day-container"
        id={`day-${dayNumber}`}
      >
        <Grid className={classes.planContainer} container={true} spacing={2}>
          <Grid item={true} lg={6} md={12} xs={12}>
            <CardSection className={classes.cardSection} type="plain">
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                <Can user="edit_unpublished:plan_day">
                  <Box mb={2}>
                    {day.status === submissionStatuses.SUBMITTED ? (
                      <AsyncButton
                        color="primary"
                        idle={t('plans:day.approve_info')}
                        onClick={() =>
                          onApproveDayInfo((updatedDay: Plan.Day) =>
                            setDay(updatedDay),
                          )
                        }
                        options={{
                          disableUpdateOnSuccess: true,
                        }}
                        pending={t('plans:day.approve_info')}
                        rejected={t('plans:day.info_error')}
                        startIcon={<CheckIcon />}
                        variant="contained"
                      >
                        {t('plans:day.approve_info')}
                      </AsyncButton>
                    ) : null}
                  </Box>
                </Can>

                <IconButton
                  aria-label={t('plans:day.edit_day', { number: dayNumber })}
                  className={classes.editIcon}
                  component={Link}
                  size="large"
                  to="edit"
                >
                  <EditIcon />
                </IconButton>
              </Box>

              <Box mb={4}>
                <Box mb={1}>
                  <Typography gutterBottom={true} variant="h3">
                    {t('plans:day.bible_ref')}
                  </Typography>
                  {!day?.references?.length ? (
                    <Typography variant="subtitle1">
                      {t('plans:day.add_scriptures')}
                    </Typography>
                  ) : (
                    <BibleReferencePills references={day.references} />
                  )}
                </Box>
              </Box>

              {hasNarratedAudio ? (
                <Box mb={4}>
                  <Typography variant="h3">
                    <Box alignItems="center" display="flex" mb={1}>
                      <Box>
                        {t('plans:day.narrated_audio')}
                        {!day.narrated_audio_attachment ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Box>
                      <Box alignItems="center" display="flex" ml={0.75}>
                        <VolumeUpIcon fontSize="inherit" />
                      </Box>
                    </Box>
                  </Typography>
                  {day.narrated_audio_attachment ? (
                    <audio
                      controls={true}
                      src={day.narrated_audio_attachment.file_url}
                    >
                      {t('plans:day.no_browser_support')}
                    </audio>
                  ) : (
                    <Typography variant="subtitle1">
                      {t('plans:day.add_audio')}
                    </Typography>
                  )}
                </Box>
              ) : null}

              {day.devotional_content_blocks &&
              day.devotional_content_blocks.length ? (
                <Box>
                  <Typography variant="h3">
                    {t('plans:day.devo_content')}
                  </Typography>
                  <PreviewBlockContent
                    blocks={day.devotional_content_blocks}
                    key={day.id}
                    planStatus={planStatus}
                    supportedBlocks={defaultBlockTypes}
                  />
                </Box>
              ) : null}
            </CardSection>
          </Grid>
          <Grid item={true} lg={6} md={12} xs={12}>
            {comments ? (
              <CommentSection
                comments={comments}
                dayId={day.id}
                planId={planId}
                refetch={refetchComments}
              />
            ) : null}
          </Grid>
        </Grid>
      </Card>
    </Collapse>
  )
}
