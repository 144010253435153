/* eslint-disable jsdoc/require-param */
/* eslint-disable react/no-multi-comp */
/**
 * @module PlanFilteringForm
 */
import { Box, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import styles from './styles.module.scss'
import { NameField } from './NameField'
import { LanguageField } from './LanguageField'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBlock: theme.spacing(2),
  },
}))

/**
 * Plan Form - Plan Search Input and Clear Filters Button.
 *
 * @returns {ReactElement} - The Form component.
 */
export function Form() {
  const classes = useStyles()

  return (
    <div className={styles.filters}>
      <Box className={classes.container}>
        <Grid container={true} spacing={2}>
          <Grid item={true} lg={8} md={12} sm={12} xs={12}>
            <NameField />
          </Grid>
          <Grid item={true} lg={4} md={4} sm={12} xs={12}>
            <LanguageField />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
