/* eslint-disable jsdoc/require-param */
/**
 * @module planDayApiMethods
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import { Plan } from 'components/Plans/types'
import { DEFAULT_PLANPORTAL_HEADERS, getToken } from 'helpers'
import {
  DEFAULT_HEADERS,
  PLAN_PORTAL_API_URL,
  PORTALPY_URL,
  QUERY_KEYS,
} from 'helpers/constants'
import { fetchClient } from 'helpers/transport'
import { NumberLike, StringList } from 'types/misc'

/**
 * Convert an html string to blocks.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<Array<object>>} - An array of block objects.
 */
export async function convertHtmlToBlocks(html: string) {
  try {
    const authToken = await getToken()
    const response = await fetch(`${PORTALPY_URL}/2.1/html_to_blocks`, {
      body: JSON.stringify({
        html,
      }),
      headers: DEFAULT_HEADERS(authToken),
      method: 'POST',
    })
    const responseJson = await response.json()
    return responseJson.data
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export interface PlanDayDataProps {
  /** Required scripture references. */
  references: StringList
  /** Devotional content blocks. */
  devotional_content_blocks?: Array<Plan.DevotionalContentBlocks>
  status?: string
  position?: number
}

interface CreatePlanDayProps {
  data: PlanDayDataProps
  planId: NumberLike
}

/**
 * Creates a new plan day.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The new plan day data.
 */
export async function createPlanDay({ data, planId }: CreatePlanDayProps) {
  try {
    const response = await fetchClient<Plan.Day>(`/4.0/plans/${planId}/days`, {
      baseUrl: PLAN_PORTAL_API_URL,
      body: JSON.stringify(data),
      method: 'POST',
    })
    return response.parsedBody
  } catch (error) {
    if (error instanceof Response) {
      const response = await error.json()
      throw new Error(response.message)
    }

    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export const useCreatePlanDay = () => useMutation(createPlanDay)

interface GetPlanDaysProps {
  planId: NumberLike
}

export interface GetPlanDaysResponse {
  data: Plan.Day[]
}

export async function getPlanDays({
  planId,
}: GetPlanDaysProps): Promise<GetPlanDaysResponse | undefined> {
  try {
    const authToken = await getToken()
    const response = await fetch(
      `${PLAN_PORTAL_API_URL}/4.0/plans/${planId}/days`,
      {
        headers: DEFAULT_PLANPORTAL_HEADERS(authToken),
        method: 'GET',
      },
    )
    return await response.json()
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export const useGetPlanDays = (params: GetPlanDaysProps) =>
  useQuery([QUERY_KEYS.PLAN_DAYS, params], () => getPlanDays(params))

interface GetPlanDayProps {
  /** The plan's id. */
  dayId: NumberLike
  /** The day's id. */
  planId: NumberLike
}

/**
 * GET a single plan day.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 *  - The plan day's data.
 * @returns {Promise<object>} - The plan day data.
 */
export async function getPlanDay({ planId, dayId }: GetPlanDayProps) {
  try {
    const response = await fetchClient<Plan.Day>(
      `/4.0/plans/${planId}/days/${dayId}`,
      { baseUrl: PLAN_PORTAL_API_URL, method: 'GET' },
    )
    return response.parsedBody
  } catch (error) {
    if (error instanceof Response) {
      const response = await error.json()
      throw new Error(response.message)
    }

    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export const useGetPlanDay = (params: GetPlanDayProps) =>
  useQuery([QUERY_KEYS.PLAN_DAY, params], () => getPlanDay(params))

interface UpdatePlanDayProps {
  /** The plan's id. */
  dayId: NumberLike
  /** The day's id. */
  planId: NumberLike
  /** The plan day's data. */
  data: Partial<PlanDayDataProps>
}

/**
 * Updates a plan day.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<Object>} - The plan day's data.
 */
export async function updatePlanDay({
  dayId,
  planId,
  data,
}: UpdatePlanDayProps) {
  try {
    const response = await fetchClient<Plan.Day>(
      `/4.0/plans/${planId}/days/${dayId}`,
      {
        baseUrl: PLAN_PORTAL_API_URL,
        body: JSON.stringify(data),
        method: 'PATCH',
      },
    )

    return response.parsedBody
  } catch (error) {
    if (error instanceof Response) {
      const response = await error.json()
      throw new Error(response.message)
    }

    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export const useUpdatePlanDay = () => useMutation(updatePlanDay)

interface DeletePlanDayProps {
  /** The plan's id. */
  dayId: NumberLike
  /** The day's id. */
  planId: NumberLike
}

/**
 * Function to delete plan day.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise} - Does not return anything.
 */
export async function deletePlanDay({ dayId, planId }: DeletePlanDayProps) {
  try {
    return await fetchClient<{ plan_day: Plan.Day }>(
      `/4.0/plans/${planId}/days/${dayId}`,
      {
        baseUrl: PLAN_PORTAL_API_URL,
        method: 'DELETE',
      },
    )
  } catch (error) {
    if (error instanceof Response) {
      const response = await error.json()
      throw new Error(response.message)
    }

    if (error instanceof Error) {
      throw new Error(error.message)
    }
  }
}

export const useDeletePlanDay = () => useMutation(deletePlanDay)
