import { APP_BOOTSTRAP_COMPLETED, APP_ROUTE_CHANGED } from './constants'
import { standardAction } from '../../utils'

function appRouteChanged() {
  return standardAction(APP_ROUTE_CHANGED)
}

/* One off bootstrap actions for App initialization */

function appBootstrapCompleted() {
  return standardAction(APP_BOOTSTRAP_COMPLETED)
}

export { appRouteChanged, appBootstrapCompleted }
