import React from 'react'
import { statusTypes } from '@youversion/utils'
import { useAuth } from 'auth'
import { Plan } from 'components/Plans/types'
import { getFileBlockProcessingErrors } from '../get-file-block-processing-errors'

export interface UsePreloadBlockDataProps {
  callback: (blocks: Plan.DevotionalContentBlocks[]) => void
  initialBlocks: Plan.DevotionalContentBlocks[]
  planStatus: Plan.Status
}

export function usePreloadBlockData({
  callback,
  initialBlocks,
  planStatus,
}: UsePreloadBlockDataProps) {
  const { user } = useAuth()

  const [loadingStatus, setLoadingStatus] = React.useState(statusTypes.PENDING)
  /**
   * Make extra api calls on initial load.
   */
  React.useEffect(() => {
    if (
      loadingStatus === statusTypes.RESOLVED ||
      loadingStatus === statusTypes.REJECTED
    ) {
      return
    }
    if (
      user &&
      user.can('read:file_attachment_status') &&
      planStatus === Plan.Status.APPROVED
    ) {
      getFileBlockProcessingErrors(initialBlocks)
        .then((processedBlocks) => {
          callback(processedBlocks)
          setLoadingStatus(statusTypes.RESOLVED)
        })
        .catch(() => {
          setLoadingStatus(statusTypes.REJECTED)
        })
    } else {
      setLoadingStatus(statusTypes.RESOLVED)
    }
  }, [callback, initialBlocks, loadingStatus, planStatus, user])

  return loadingStatus
}
