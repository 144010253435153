/**
 * @module LoadingOverlay
 */
import { ReactElement } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

/**
 * Returns a loader.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function LoadingOverlay() {
  const { t } = useTranslation('organizations')
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <CircularProgress />
      <Typography align="center" color="inherit" variant="body1">
        {t('single_org.membership_table.loading_membership')}
      </Typography>
    </Box>
  )
}
