/* eslint-disable jsdoc/require-param */
import { ReactElement } from 'react'
import {
  blue,
  green,
  grey,
  magenta,
  orange,
  yellow,
} from '@youversion/react/styles/colors-v3'
import classNames from 'classnames'
import { Chip, capitalize } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  Archive,
  Check,
  Create,
  ErrorOutline,
  HelpOutline,
  Schedule,
} from '@mui/icons-material'
import { submissionStatuses } from 'helpers/constants'
import { useTranslation } from 'react-i18next'

const STATUS_MISSING_NARRATED_AUDIO = 'missing-narrated-audio'
const useStyles = makeStyles(() =>
  createStyles<string, {}>({
    [submissionStatuses.PUBLISHED]: {
      '& span': {
        color: blue[40],
      },
      '& svg': {
        fill: blue[40],
      },
      backgroundColor: blue[10],
      borderColor: blue[10],
    },
    [submissionStatuses.APPROVED]: {
      '& span': {
        color: green[40],
      },
      '& svg': {
        fill: green[40],
      },
      backgroundColor: green[10],
      borderColor: green[10],
    },
    [submissionStatuses.ARCHIVED]: {
      '& span': {
        color: magenta[40],
      },
      '& svg': {
        fill: magenta[40],
      },
      backgroundColor: magenta[10],
      borderColor: magenta[10],
    },
    [submissionStatuses.DRAFT]: {
      '& span': {
        color: grey[40],
      },
      '& svg': {
        fill: grey[40],
      },
      backgroundColor: grey[10],
      borderColor: grey[10],
    },
    [submissionStatuses.REJECTED]: {
      '& span': {
        color: orange[40],
      },
      '& svg': {
        fill: orange[40],
      },
      backgroundColor: orange[10],
      borderColor: orange[10],
    },
    [submissionStatuses.SUBMITTED]: {
      '& span': {
        color: yellow[40],
      },
      '& svg': {
        fill: yellow[40],
      },
      backgroundColor: yellow[10],
      borderColor: yellow[10],
    },
    smallerIcon: {
      fontSize: 14,
    },
  }),
)

interface StatusBadgeProps {
  className?: string
  status: string
}

/**
 * Status Badge component to indicate a Plan's status.
 *
 * @returns {ReactElement} - The StatusBadge component.
 */
export default function StatusBadge({ className, status }: StatusBadgeProps) {
  const classes = useStyles()
  const { t } = useTranslation(['plans'])

  function getStatusIconAndText(dayStatus: string) {
    let icon = null
    let labelText = capitalize(dayStatus)

    switch (dayStatus) {
      case STATUS_MISSING_NARRATED_AUDIO:
        icon = (
          <ErrorOutline
            color="inherit"
            data-testid="missing-narrated-audio"
            fontSize="small"
          />
        )
        labelText = t('plans:narrated_audio_required')
        break
      case submissionStatuses.ARCHIVED:
        icon = <Archive data-testid="archived-status-icon" fontSize="small" />
        labelText = t('plans:status.archived')
        break
      case submissionStatuses.PUBLISHED:
        icon = <Check data-testid="published-status-icon" fontSize="small" />
        labelText = t('plans:status.live')
        break
      case submissionStatuses.APPROVED:
        icon = <Check data-testid="approved-status-icon" fontSize="small" />
        labelText = t('plans:status.approved')
        break
      case submissionStatuses.SUBMITTED:
        icon = <Schedule data-testid="submitted-status-icon" fontSize="small" />
        labelText = t('plans:status.in_review')
        break
      case submissionStatuses.DRAFT:
        icon = (
          <Create
            className={classes.smallerIcon}
            data-testid="draft-status-icon"
            fontSize="small"
          />
        )
        break
      default:
        // The help icon is a question mark. This shouldn't get here,
        // but this code existed before in other places and wanted to
        // make sure something appeared if it ever got here.
        icon = <HelpOutline data-testid="help-status-icon" fontSize="small" />
        break
    }

    return { icon, labelText }
  }

  const { icon, labelText } = getStatusIconAndText(status.toLowerCase())

  return (
    <Chip
      className={classNames(classes[status.toLowerCase()], className)}
      icon={icon}
      label={labelText}
      size="small"
      variant="outlined"
    />
  )
}
