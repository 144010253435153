/**
 * @module Selectors
 */

import { createSelector } from 'reselect'

const authSelector = (state) => state.auth
const plansSelector = (state) => state.plans
const orgsSelector = (state) => state.organizations
const activitiesSelector = (state) => state.activities

// //////////////////////
// / Activity states
// /

export const isActivityItemsLoadedSelector = createSelector(
  activitiesSelector,
  (activities) => activities.paged.loadedAt !== null,
)

// //////////////////////
// / Auth
// /
export const authErrorSelector = createSelector(
  authSelector,
  (auth) => auth.error,
)

export const tokenSelector = createSelector(authSelector, (auth) => auth.token)

export const apiAddressSelector = createSelector(
  authSelector,
  (auth) => auth.apiAddress,
)

export const isLoggedInSelector = createSelector(
  tokenSelector,
  (token) => !!token,
)

export const authLoadingSelector = createSelector(
  authSelector,
  (auth) => !!auth.loading,
)

export const userSelector = createSelector(authSelector, (auth) => auth.user)

export const ownAvatarUrlSelector = createSelector(userSelector, (user) => {
  return user && user.profile_photo?.asset.thumb_url
})

export const isAdminSelector = createSelector(userSelector, (user) => {
  return Boolean(user && user.role === 'admin')
})

// //////////////////////
// / Orgs
// /
export const orgsLoadingSelector = createSelector(
  orgsSelector,
  (org) => !!org.loading,
)

export const orgIdsSelector = createSelector(
  orgsSelector,
  (org) => org.curentPageOrgIds,
)

export const orgsByIdSelector = createSelector(
  orgsSelector,
  (org) => org.orgsById,
)

export const viewingAllOrgsSelector = createSelector(
  orgsSelector,
  (orgs) => orgs.viewingAllOrganizations,
)

export const allOrgsSelector = createSelector(
  orgIdsSelector,
  orgsByIdSelector,
  (orgIds, orgsById) => orgIds.map((id) => orgsById[id]),
)

export const activeOrgIdSelector = createSelector(
  orgsSelector,
  (org) => org.activeOrgId,
)

export const activeOrgSelector = createSelector(
  orgsSelector,
  (orgsState) => orgsState.activeOrg,
)

export const activeOrganizationNameSelector = createSelector(
  activeOrgSelector,
  (activeOrg) => activeOrg.name,
)

// //////////////////////
// / Plans
// /
export const plansSorterSelector = createSelector(
  plansSelector,
  (plans) => plans.sorter,
)

export const plansFiltersSelector = createSelector(
  plansSelector,
  (plans) => plans.filters,
)
