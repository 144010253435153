/* eslint-disable jsdoc/require-param */
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import { GroupsSelect } from './groups-select'
import { GroupsList } from './groups-list'

interface GroupsSelectProps {
  /** Available groups. */
  groups: Group[]
  /** Groups user belongs to. */
  userGroupMemberships: GroupMembership[]
  /** Function to refetch group membership data.  */
  refetchGroupMemberships: () => void
  /** User data. */
  user: User
}

/**
 * Add a group to user.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function ManageGroupMemberships({
  user,
  groups,
  userGroupMemberships,
  refetchGroupMemberships,
}: GroupsSelectProps) {
  const hasGroupMemberships = userGroupMemberships.length !== 0
  const hasGroups = groups.length !== 0
  const { t } = useTranslation('users')

  return (
    <div className={styles.groupsContainer}>
      <h3>{t('groups.groups')}</h3>
      {hasGroupMemberships ? (
        <GroupsList
          memberships={userGroupMemberships}
          refetch={refetchGroupMemberships}
        />
      ) : null}
      {hasGroups ? (
        <GroupsSelect
          groups={groups}
          refetch={refetchGroupMemberships}
          user={user}
          userGroups={userGroupMemberships}
        />
      ) : null}
    </div>
  )
}
