/* eslint-disable jsdoc/require-param */
/**
 * @module CommentPrimaryActions
 */
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { grey } from '@mui/material/colors'

import {
  Create as CreateIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import styles from '../../Card.module.scss'

const useStyles = makeStyles(() => ({
  iconButton: {
    '&:hover': {
      backgroundColor: grey['300'],
    },
    backgroundColor: grey['200'],
    padding: '5px',
  },
}))

interface CommentPrimaryActionsProps {
  /** Handler function to change the state of the comment card to the delete state. */
  triggerDeleteState: VoidFunction
  /** Handler function to change the state of the comment card to the update state. */
  triggerEditState: VoidFunction
}

/**
 * Represents the Comment primary actions buttons (update and delete).
 *
 * @returns {React.ReactElement} A Comment primary actions component.
 */
export function CommentPrimaryActions({
  triggerDeleteState,
  triggerEditState,
}: CommentPrimaryActionsProps) {
  const { t } = useTranslation(['plans'])
  const classes = useStyles()
  return (
    <Box aria-label="primary-actions" className={styles.commentPrimaryActions}>
      <Box mr={1}>
        <IconButton
          aria-label={t('plans:comment.edit_icon_button')}
          className={classes.iconButton}
          onClick={triggerEditState}
          size="small"
        >
          <CreateIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          aria-label={t('plans:comment.delete_icon_button')}
          className={classes.iconButton}
          onClick={triggerDeleteState}
          size="small"
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  )
}
