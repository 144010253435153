import { Dispatch, ReactElement, SetStateAction } from 'react'
import { Box, Button } from '@mui/material'
import Select from 'react-select'
import moment from 'moment'
import { NumberLike } from 'types/misc'
import { PaneContent } from 'components/misc'
import { PartnerRow } from './partner-row'
import Authorize from '../Authorize'

import styles from '../Styles.module.scss'

interface Props {
  title: string
  reportData: ReportData
  dateRange: DateRange
  setDateRange: Dispatch<SetStateAction<DateRange>>
}

interface DateRange {
  startYear?: SelectOption
  startMonth?: SelectOption
  endYear?: SelectOption
  endMonth?: SelectOption
}

interface ReportData {
  totalPlans: number
  totalPartners: number
  rawJSON: Array<PartnerRecord>
  records: Map<number, Array<PartnerRecord>>
}

interface SelectOption {
  label: NumberLike
  value: NumberLike
}

const generateCSV = (json: Array<PartnerRecord>) => {
  const generateRow = (record: PartnerRecord) => {
    const rep = `${record.first_name} ${record.last_name}`
    const partner = record.org_name
    const created = record.org_created_at
    const plans = record.org_plans_count
    return [rep, partner, created, plans].join(',')
  }

  const headers = [
    'Staff Rep',
    'Partner',
    'Partner Created',
    'Total Plans',
  ].join(',')

  const contents = json.reduce((csv, record) => {
    return `${csv}\n${generateRow(record)}`.trim()
  }, headers)

  return new Blob([contents], { type: 'text/csv' })
}

// Auto generate years in the Select list from 2007 to the current year.
const yearOptions: Array<SelectOption> = []
const beginningYear = 2007
const currentYear = moment().year()
for (let year = beginningYear; year <= currentYear; year += 1) {
  yearOptions.push({ label: year, value: year })
}

const monthOptions = moment.months().map((name, index) => {
  return { label: name, value: index + 1 }
})

export default function List({
  title,
  reportData,
  dateRange,
  setDateRange,
}: Props) {
  function handleDownloadCSV() {
    const url = window.URL.createObjectURL(generateCSV(reportData.rawJSON))
    const a = document.createElement('a')
    a.href = url
    a.download = 'report.csv'
    a.click()
  }

  function updateDateRange(params: Record<string, SelectOption>) {
    setDateRange((prevState) => {
      return { ...prevState, ...params }
    })
  }

  function handleDateSelect(
    option: SelectOption,
    { name: inputName }: { name: string },
  ) {
    updateDateRange({ [inputName]: option })
  }

  const tableRows: Array<ReactElement> = []

  if (reportData) {
    reportData.records.forEach((arr) => {
      tableRows.push(<PartnerRow key={`staff_${arr[0].id}`} records={arr} />)
    })
  }

  return (
    <Authorize>
      <div className={styles.contentContainer}>
        <PaneContent className={styles.paneContent}>
          <div>
            <h1>{title}</h1>
            <Button onClick={handleDownloadCSV} variant="outlined">
              Download CSV
            </Button>

            <div className={styles.filters}>
              <div className={styles.filter}>
                <div data-testid="select-start-year">
                  <label>Start Year & Month</label>
                  <Select
                    aria-label="Select start year"
                    isClearable={true}
                    name="startYear"
                    onChange={handleDateSelect}
                    options={yearOptions}
                    placeholder="Select Start Year..."
                    value={dateRange.startYear}
                  />
                </div>
                <Box mt={1} />{' '}
                <div data-testid="select-start-month">
                  <Select
                    aria-label="Select start month"
                    isClearable={true}
                    name="startMonth"
                    onChange={handleDateSelect}
                    options={monthOptions}
                    placeholder="Select Start Month..."
                    value={dateRange.startMonth}
                  />
                </div>
              </div>
              <div className={styles.filter}>
                <div data-testid="select-end-year">
                  <label>End Year & Month</label>
                  <Select
                    aria-label="Select end year"
                    isClearable={true}
                    name="endYear"
                    onChange={handleDateSelect}
                    options={yearOptions}
                    placeholder="Select End Year..."
                    value={dateRange.endYear}
                  />
                </div>
                <Box mt={1} />
                <div data-testid="select-end-month">
                  <Select
                    aria-label="Select end month"
                    isClearable={true}
                    name="endMonth"
                    onChange={handleDateSelect}
                    options={monthOptions}
                    placeholder="Select End Month..."
                    value={dateRange.endMonth}
                  />
                </div>
              </div>
            </div>
            {reportData ? (
              <div>
                <table className={styles.table}>
                  <thead>
                    <tr className={styles.totalHeading}>
                      <th />
                      <th>
                        Total Partners:
                        <strong> {reportData.totalPartners}</strong>
                      </th>
                      <th />
                      <th>
                        {reportData.totalPlans !== 0 && (
                          <span>
                            Total Plans:
                            <strong> {reportData.totalPlans}</strong>
                          </span>
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.repColumn}>Rep</th>
                      <th className={styles.partnerColumn}>Partner</th>
                      <th className={styles.createdColumn}>Partner Created</th>
                      <th className={styles.plansColumn}>Plans</th>
                    </tr>
                  </thead>
                  {tableRows}
                </table>
              </div>
            ) : null}
          </div>
        </PaneContent>
      </div>
    </Authorize>
  )
}
