/* eslint-disable jsdoc/require-param */
/**
 * @module PlansLandingPage
 */
import React, { FC } from 'react'
import { NarratedAudioIntroDialog } from 'components'
import { Filters } from 'components/Plans/Filtering/Filters'
import { PlanDataGrid } from 'components/Plans/plan-data-grid'
import { GetPlansResponse } from 'api/plans'

interface Props {
  data?: GetPlansResponse
  organizationId: string
  loadingStatus: string
}

/**
 * The plans landing page.
 *
 * @returns {ReactElement} - The Plans Landing Page component.
 */
const LandingPage: FC<Props> = ({
  data,
  organizationId,
  loadingStatus,
}: Props) => {
  return (
    <>
      <Filters />
      <PlanDataGrid
        data={data}
        loadingStatus={loadingStatus}
        organizationId={organizationId}
      />
      <NarratedAudioIntroDialog />
    </>
  )
}

export default LandingPage
