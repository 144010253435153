/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LinkButton } from 'components/Buttons/link-button'
import styles from './styles.module.scss'

/* TODO: Refactor to use this as a basis for all toolbars across the app */

export const Toolbar = ({ children }: React.PropsWithChildren) => {
  return <div className={styles.positioner}>{children}</div>
}

interface IButton {
  disabled?: boolean
  icon?: string
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  variant?: string
}
export const Button = ({
  children,
  disabled = false,
  icon,
  onClick,
  variant = 'light',
  ...props
}: React.PropsWithChildren<IButton>) => {
  return (
    <LinkButton
      className={`${styles[`${variant}Button`]} ${styles.toolbarBtn}`}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {icon && <i className={icon} />}
      {children}
    </LinkButton>
  )
}

interface ILink {
  to: string
  icon: string
  variant?: string
}

export const Link = ({
  to,
  icon,
  children,
  variant = 'light',
  ...props
}: React.PropsWithChildren<ILink>) => {
  return (
    <RouterLink
      className={`${styles[`${variant}Button`]} ${styles.toolbarBtn}`}
      to={to}
      {...props}
    >
      {icon && <i className={icon} />}
      {children}
    </RouterLink>
  )
}

export default Toolbar
