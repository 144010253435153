import { useQuery } from '@tanstack/react-query'
import { BIBLE_VERSIONS, ENVIRONMENT, QUERY_KEYS } from 'helpers'

export interface BibleVersionOutput {
  id: number
  abbreviation: string
  local_abbreviation: string
  title: string
  local_title: string
  audio: boolean
  audio_count: number
  text: boolean
  language: {
    iso_639_1: string
    iso_639_3: string
    name: string
    local_name: string
    text_direction: 'ltr' | 'rtl'
    language_tag: string
    secondary_language_tags: null
  }
  books: Array<{
    text: boolean
    usfm: string
    audio: boolean
    canon: string
    human: string
    human_long: string
    abbreviation: string
    chapters: Array<{
      toc: boolean
      usfm: string
      human: string
      canonical: boolean
    }>
  }>
}

export async function getBibleVersion(lang: string) {
  const bibleVersionCode = BIBLE_VERSIONS[lang] ?? BIBLE_VERSIONS.en
  const baseUrl =
    ENVIRONMENT === 'production'
      ? 'https://webproxy.bible.com'
      : 'https://webproxy-staging.bible.com'

  const response = await fetch(
    `${baseUrl}/bible/version.json?id=${bibleVersionCode}`,
  )

  const res = await response.json()
  return res.response.data as BibleVersionOutput
}

export const useGetBibleVersion = (lang: string) =>
  useQuery([QUERY_KEYS.BIBLE_VERSIONS, lang], () => getBibleVersion(lang))
