/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowForward } from '@mui/icons-material'
import { gray, yvRed } from '@youversion/react/styles/colors-v3'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '3rem',
    marginBlockStart: 10,
  },
  description: {
    color: gray[25],
    marginBlockStart: 5,
  },
  text404: {
    color: yvRed,
  },
}))

export const Page404 = () => {
  const { t } = useTranslation(['common'])
  const styles = useStyles()
  return (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="column"
      height="100vh"
      justifyContent="center"
    >
      <Typography className={styles.text404} variant="h3">
        404
      </Typography>
      <Typography className={styles.title} variant="h1">
        {t('common:page_not_found')}
      </Typography>
      <Typography className={styles.description}>
        {t('common:not_found_subtext')}
      </Typography>

      <Box mt={3}>
        <Button
          color="primary"
          endIcon={<ArrowForward fontSize="small" />}
          href="/plans"
          size="large"
          variant="contained"
        >
          {t('common:go_back_home')}
        </Button>
      </Box>
    </Box>
  )
}
