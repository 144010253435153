import { History, Transition } from 'history'
import { useContext, useEffect } from 'react'
import {
  UNSAFE_NavigationContext as NavigationContext,
  Navigator,
} from 'react-router-dom'

type ExtendNavigator = Navigator & Pick<History, 'block'>

// eslint-disable-next-line jsdoc/require-param
/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @see {@link https://reactrouter.com/api/useBlocker}.
 * @see {@link https://stackoverflow.com/questions/71572678/react-router-v-6-useprompt-typescript/71587163#71587163}.
 */
export function useBlocker(blocker: (tx: Transition) => void, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) return

    if (!('block' in navigator)) return

    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. React Router DOM will do: Figure out
          // how to re-enable this block if the transition is cancelled for
          // some reason.
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    // return unblock - needed to ensure it only gets fired once
    // eslint-disable-next-line consistent-return
    return unblock
  }, [navigator, blocker, when])
}
